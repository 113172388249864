<template>
  <tbody>
    <tr v-for="(client, index) in clientsList"
          :key="index"
      >
        <td class="holder-checkbox">
          <CustomCheckbox
              :id="client.email"
              :labelText="'text'"
              :inputElement="client"
          />
        </td>
        <td>
          <div
              :id="`goto_client_profile_${client.publicId}`"
              @click="gotoProfile(client.publicId)"
              class="holder-info name">
            <div class="info">
              <span class="info-name" v-if="client">
                {{client.email}}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div class="device">
            <div class="mobile-phone">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#F8FCFC"/>
                <path
                    d="M19.365 8H12.6327C11.9889 8 11.4688 8.52214 11.4688 9.16598V22.8355C11.4688 23.4774 11.9889 24 12.6327 24H19.365C20.0078 24 20.5299 23.4779 20.5299 22.8355V9.16598C20.5294 8.52214 20.0078 8 19.365 8ZM19.6569 20.9636H12.3418V9.96686H19.6569V20.9636ZM17.5597 9.18315H14.4385V8.8428H17.5602L17.5597 9.18315ZM18.8954 9.02762C18.8954 9.19224 18.761 9.32555 18.5969 9.32555C18.4323 9.32555 18.2985 9.19173 18.2985 9.02762C18.2985 8.8635 18.4323 8.72968 18.5969 8.72968C18.7615 8.72918 18.8954 8.863 18.8954 9.02762ZM17.4198 22.7578H14.5774V21.8832H17.4198V22.7578Z"
                    fill="#082655"/>
              </svg>
            </div>
            <div class="pc">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#F8F5FF"/>
                <path
                    d="M24.1475 19.5192V18.7787V14.3343V9.88984C24.1475 9.48308 23.8146 9.14941 23.4071 9.14941H8.59291C8.18544 9.14941 7.85154 9.48144 7.85154 9.88984V14.3343V18.7787V19.5192L6 21.7414C6 22.3536 6.49721 22.8516 7.11111 22.8516H24.8889C25.5019 22.8516 26 22.3534 26 21.7414L24.1475 19.5192ZM17.4825 22.4825H14.5191C14.4167 22.4825 14.3338 22.4003 14.3338 22.2972C14.3338 22.1941 14.4167 22.1118 14.5191 22.1118H17.4827C17.5842 22.1118 17.6681 22.1941 17.6681 22.2972C17.6653 22.4005 17.584 22.4825 17.4825 22.4825ZM12.9467 21.7421L13.5118 21.0638H18.487L19.0531 21.7421H12.9467ZM23.1051 18.2091C23.1051 18.5664 22.8141 18.8556 22.4593 18.8556H9.54047C9.18572 18.8556 8.8947 18.5639 8.8947 18.2091V10.4609C8.8947 10.1035 9.18572 9.81439 9.54047 9.81439H22.4595C22.8143 9.81439 23.1053 10.1054 23.1053 10.4609C23.1051 10.4609 23.1051 18.2091 23.1051 18.2091Z"
                    fill="#9B7EDE"/>
              </svg>
            </div>
          </div>
        </td>
        <td>
          <span class="registration-date-text">{{parseDate(client.lastSyncDate)}}</span>
        </td>
        <td class="w-400">
          <div class="holder-btns">
            <button
              :disabled="!hasPermission(['manage_contacts'])"
              :id="`resend_client_${client.publicId}`"
              @click="$emit('resend', client.publicId, client.email)"
              class="btn resend-btn"
            >
              <span class="btn-span">Resend</span>
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.26778 7.06211L15.2078 2.79461C15.3474 2.72913 15.5038 2.70828 15.6557 2.73488C15.8076 2.76149 15.9476 2.83427 16.0566 2.9433C16.1656 3.05234 16.2384 3.19233 16.265 3.34422C16.2916 3.49611 16.2708 3.6525 16.2053 3.79211L11.9378 15.7321C11.9 15.8978 11.8071 16.0458 11.6742 16.1518C11.5414 16.2577 11.3765 16.3155 11.2065 16.3155C11.0366 16.3155 10.8717 16.2577 10.7388 16.1518C10.606 16.0458 10.513 15.8978 10.4753 15.7321L8.59278 10.8421C8.55536 10.7387 8.49564 10.6448 8.41788 10.567C8.34011 10.4892 8.24619 10.4295 8.14278 10.3921L3.25278 8.52461C3.08744 8.48518 2.9404 8.39076 2.83575 8.25681C2.7311 8.12287 2.67505 7.95735 2.6768 7.78738C2.67854 7.61741 2.73797 7.45307 2.84534 7.3213C2.95272 7.18953 3.10167 7.09814 3.26778 7.06211Z"
                      fill="#082655"/>
                <path d="M8.46528 10.5346L10.7153 8.28461M3.26778 7.06211L15.2078 2.79461C15.3474 2.72913 15.5038 2.70828 15.6557 2.73488C15.8076 2.76149 15.9476 2.83427 16.0566 2.9433C16.1656 3.05234 16.2384 3.19233 16.265 3.34422C16.2916 3.49611 16.2708 3.6525 16.2053 3.79211L11.9378 15.7321C11.9 15.8978 11.8071 16.0458 11.6742 16.1518C11.5414 16.2577 11.3765 16.3155 11.2065 16.3155C11.0366 16.3155 10.8717 16.2577 10.7388 16.1518C10.606 16.0458 10.513 15.8978 10.4753 15.7321L8.59278 10.8421C8.55536 10.7387 8.49564 10.6448 8.41788 10.567C8.34011 10.4892 8.24619 10.4295 8.14278 10.3921L3.25278 8.52461C3.08744 8.48518 2.9404 8.39076 2.83575 8.25681C2.7311 8.12287 2.67505 7.95735 2.6768 7.78738C2.67854 7.61741 2.73797 7.45307 2.84534 7.3213C2.95272 7.18953 3.10167 7.09814 3.26778 7.06211V7.06211Z"
                      stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <span :id="`disabled-deactivate-client_${client.publicId}`" :ref="`disabledDeactivateClient_${client.publicId}`">
              <button
                  :disabled="!hasPermission(['manage_contacts'])"
                  :id="`deactivate_client_${client.publicId}`"
                  @click.stop="confirmDeactivateUser(client)"
                  class="btn btn-deactivate"
              >
              <span class="btn-span">Deactivate</span>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z" fill="#D7DCE6"/>
              </svg>
            </button>
            </span>
            <tooltip v-if="!hasPermission(['manage_contacts'])" :target-ref="$refs[`disabledDeactivateClient_${client.publicId}`]">
              <span>To be able to deactivate client from a system - you need to have the 'Manage contact's' permission in system</span>
            </tooltip>
          </div>
        </td>
      </tr>
  </tbody>
</template>

<script>
import hasPermission from "../../../utils/permission";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import parseDate from "../../../mixins/parseDate";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "CaseClientsItem",
  props: {
    clientsList: {
      type: Array
    },
  },
  emits: ['resend', 'confirmDeactivateUser'],
  components: {
    Tooltip,
    CustomCheckbox
  },
  data() {
    return {
      el: {
        isActive: true
      },
      item: {
        isActive: false
      },
    }
  },
  mixins: [parseDate],
  methods: {
    hasPermission,
    capitalizeFirstLetter(string) {
      if(string) {
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
      }
    },
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'Custodian Profile', params: { slug: id}})
      window.open(route.href, '_blank');
    },
    confirmDeactivateUser(item) {
      this.$emit('confirmDeactivateUser', item)
    },
  },

}
</script>

<style scoped>

.holder-info.name {
  cursor: pointer;
}

.holder-info.name:hover {
  color: var(--accent-color);
}

.info-name {
  transition: all 0.3s;
}

.info-name:hover {
  color: var(--accent-color);
}

.info {
  width: 100%;
  max-width: calc(100% - 40px);
}

.device {
  width: 100%;
  max-width: 74px;
  display: flex;
  justify-content: space-between;
  margin: 0 64px 0 0;
}

.mobile-phone {
  width: 32px;
  height: 32px;

}
.pc {
  width: 32px;
  height: 32px;
}

.registration-date-text {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.btn-deactivate {
  min-width: unset;
  width: 100%;
  max-width: 146px;
  min-height: unset;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 14px;
  justify-content: space-between;
}

.holder-btns {
  justify-content: flex-end;
}

.btn-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 25px);
}

.case-clients-table.non-border .table-row:last-child {
  border-bottom: none;
}

.btn.resend-btn {
    padding: 8px 15px;
    min-width: unset;
    width: 118px;
    max-width: 180px;
    border-color: var(--case-filters-border-color);
    background-color: var(--white-color);
    font-size: 12px;
    line-height: 14px;
    color: var(--secondary-text-color);
}

.btn.resend-btn svg path {
    fill: var(--accent-color);
}

.btn.resend-btn:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

.btn.resend-btn:hover svg path {
    fill: var(--accent-color);
}

</style>
