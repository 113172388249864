<template>
  <div class="tag-section">
    <div class="category-wrapper">
      <h2 class="section-header">{{ category.category.name }}</h2>
      <ContextMenu v-if="!isDefault" tag-menu :item="category" @delete="showDeleteCategoryPopup"
                   @inputValue="changeCategoryName"/>
    </div>
    <div class="section-tags">
      <draggable
        v-if="category.tags.length > 0 && category.tags[0].id !== 0"
        v-model="category.tags"
        @end="onTagDragEnd($event)"
        class="draggable"
      >
        <TagItem
          v-for="(tag, index) in category.tags"
          :tag="tag"
          :key="index"
          :is-default="isDefault"
          @delete="showDeleteTagPopup"
        />
      </draggable>
      <div v-if="newTagInput" class="add-tag">
        <svg @click="addNewTag" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_19972_116898)">
            <path
              d="M22.4348 0H1.56522C1.1501 0 0.751977 0.164906 0.458441 0.458441C0.164906 0.751977 0 1.1501 0 1.56522V22.4348C0 22.8499 0.164906 23.248 0.458441 23.5416C0.751977 23.8351 1.1501 24 1.56522 24H22.4348C22.8499 24 23.248 23.8351 23.5416 23.5416C23.8351 23.248 24 22.8499 24 22.4348V1.56522C24 1.1501 23.8351 0.751977 23.5416 0.458441C23.248 0.164906 22.8499 0 22.4348 0ZM22.9565 22.4348C22.9565 22.5732 22.9016 22.7059 22.8037 22.8037C22.7059 22.9016 22.5732 22.9565 22.4348 22.9565H1.56522C1.42684 22.9565 1.29414 22.9016 1.19629 22.8037C1.09845 22.7059 1.04348 22.5732 1.04348 22.4348V1.56522C1.04348 1.42684 1.09845 1.29414 1.19629 1.19629C1.29414 1.09845 1.42684 1.04348 1.56522 1.04348H22.4348C22.5732 1.04348 22.7059 1.09845 22.8037 1.19629C22.9016 1.29414 22.9565 1.42684 22.9565 1.56522V22.4348ZM17.7391 12C17.7391 12.1384 17.6842 12.2711 17.5863 12.3689C17.4885 12.4668 17.3558 12.5217 17.2174 12.5217H12.5217V17.2174C12.5217 17.3558 12.4668 17.4885 12.3689 17.5863C12.2711 17.6842 12.1384 17.7391 12 17.7391C11.8616 17.7391 11.7289 17.6842 11.6311 17.5863C11.5332 17.4885 11.4783 17.3558 11.4783 17.2174V12.5217H6.78261C6.64423 12.5217 6.51153 12.4668 6.41368 12.3689C6.31584 12.2711 6.26087 12.1384 6.26087 12C6.26087 11.8616 6.31584 11.7289 6.41368 11.6311C6.51153 11.5332 6.64423 11.4783 6.78261 11.4783H11.4783V6.78261C11.4783 6.64423 11.5332 6.51153 11.6311 6.41368C11.7289 6.31584 11.8616 6.26087 12 6.26087C12.1384 6.26087 12.2711 6.31584 12.3689 6.41368C12.4668 6.51153 12.5217 6.64423 12.5217 6.78261V11.4783H17.2174C17.3558 11.4783 17.4885 11.5332 17.5863 11.6311C17.6842 11.7289 17.7391 11.8616 17.7391 12Z"
              fill="#373C44"/>
          </g>
          <defs>
            <clipPath id="clip0_19972_116898">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <CustomInput
          ref="newTagNameInput"
          id="input_invite_mail"
          v-model="newTagName"
          placeholder="Enter tag name"
          @input="addNewTag"
          @value="newTagName = $event"
          style="max-width: 300px;"
        />
        <svg @click="closeNewTagInput" class="close-icon" width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">-->
          <path
            d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
            fill="#D7DCE6"/>
          -->
        </svg>
      </div>
      <div v-else-if="!isDefault || category.category.name === 'Issues'" class="add-tag" @click="showTagInput">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_19972_116898)">
            <path
              d="M22.4348 0H1.56522C1.1501 0 0.751977 0.164906 0.458441 0.458441C0.164906 0.751977 0 1.1501 0 1.56522V22.4348C0 22.8499 0.164906 23.248 0.458441 23.5416C0.751977 23.8351 1.1501 24 1.56522 24H22.4348C22.8499 24 23.248 23.8351 23.5416 23.5416C23.8351 23.248 24 22.8499 24 22.4348V1.56522C24 1.1501 23.8351 0.751977 23.5416 0.458441C23.248 0.164906 22.8499 0 22.4348 0ZM22.9565 22.4348C22.9565 22.5732 22.9016 22.7059 22.8037 22.8037C22.7059 22.9016 22.5732 22.9565 22.4348 22.9565H1.56522C1.42684 22.9565 1.29414 22.9016 1.19629 22.8037C1.09845 22.7059 1.04348 22.5732 1.04348 22.4348V1.56522C1.04348 1.42684 1.09845 1.29414 1.19629 1.19629C1.29414 1.09845 1.42684 1.04348 1.56522 1.04348H22.4348C22.5732 1.04348 22.7059 1.09845 22.8037 1.19629C22.9016 1.29414 22.9565 1.42684 22.9565 1.56522V22.4348ZM17.7391 12C17.7391 12.1384 17.6842 12.2711 17.5863 12.3689C17.4885 12.4668 17.3558 12.5217 17.2174 12.5217H12.5217V17.2174C12.5217 17.3558 12.4668 17.4885 12.3689 17.5863C12.2711 17.6842 12.1384 17.7391 12 17.7391C11.8616 17.7391 11.7289 17.6842 11.6311 17.5863C11.5332 17.4885 11.4783 17.3558 11.4783 17.2174V12.5217H6.78261C6.64423 12.5217 6.51153 12.4668 6.41368 12.3689C6.31584 12.2711 6.26087 12.1384 6.26087 12C6.26087 11.8616 6.31584 11.7289 6.41368 11.6311C6.51153 11.5332 6.64423 11.4783 6.78261 11.4783H11.4783V6.78261C11.4783 6.64423 11.5332 6.51153 11.6311 6.41368C11.7289 6.31584 11.8616 6.26087 12 6.26087C12.1384 6.26087 12.2711 6.31584 12.3689 6.41368C12.4668 6.51153 12.5217 6.64423 12.5217 6.78261V11.4783H17.2174C17.3558 11.4783 17.4885 11.5332 17.5863 11.6311C17.6842 11.7289 17.7391 11.8616 17.7391 12Z"
              fill="#373C44"/>
          </g>
          <defs>
            <clipPath id="clip0_19972_116898">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <h2 class="section-header">Add tag</h2>
      </div>
    </div>
    <InfoDialog
      ref="infoDeleteTagDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="deleteTag"/>
    <InfoDialog
      ref="infoDeleteCategoryDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="deleteCategory"/>
    <CommonDialog ref="commonDeleteTagDialog">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The tag was deleted.</p>
    </CommonDialog>
    <CommonDialog ref="commonDeleteCategoryDialog">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The category was deleted.</p>
    </CommonDialog>
    <CommonDialog ref="commonDeleteCategoryWarningDialog">
      <h2 class="dialog-message-title">Deleting category</h2>
      <p class="dialog-message-description" style="display:block;">Before you can delete a category, you need to remove
        all tags from it</p>
    </CommonDialog>
    <CommonDialog ref="commonDeleteTagWarningDialog">
      <h2 class="dialog-message-title">Deleting tag</h2>
      <p class="dialog-message-description" style="display:block;">Before you can delete this tag, you need to remove it
        from all messages in this chat where it is used</p>
    </CommonDialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import CustomInput from "@/components/inputs/CustomInput.vue";
import {createCategoryDeleteDialog, createTagDeleteDialog} from "@/constants/infoDialogData";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import {mapActions} from "vuex";
import TagItem from "@/components/сases/case-messages/tags/TagItem.vue";
import ContextMenu from "@/components/popups/ContextMenu.vue";

export default {
  name: 'CategoryTags',
  components: {
    ContextMenu,
    TagItem,
    CommonDialog,
    InfoDialog,
    CustomInput,
    draggable,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    isDefault: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      newTagName: '',
      newTagInput: false,
      infoDialogData: {},
    }
  },
  mixins: [capitalizeFirstLetter],
  methods: {
    ...mapActions('caseMessages', {
      setLoadingState: 'setLoadingState',
      changeTagOrder: 'changeTagOrder',
      addNewTagRequest: "addNewTag",
      removeTagRequest: "removeTag",
      editCategory: "editCategory",
      removeCategory: "removeCategory",
    }),
    onTagDragEnd(event) {
      if (event.newIndex !== event.oldIndex) {
        this.setLoadingState(true)
        this.changeTagOrder({
          tagId: event.item._underlying_vm_.id,
          categoryId: this.category.category.id,
          orderIndex: event.newIndex + 1,
        })
      }
    },
    showTagInput() {
      this.newTagInput = !this.newTagInput;
    },
    closeNewTagInput() {
      this.newTagInput = false;
      this.newTagName = '';
    },
    addNewTag() {
      const refTagNameInput = this.$refs.newTagNameInput.getInput();
      if (this.newTagInput && this.newTagInput.length > 0) {
        this.setLoadingState(true);
        this.addNewTagRequest({newTagName: this.newTagName, categoryId: this.category.category.id});
      } else if (refTagNameInput && refTagNameInput.length > 0) {
        this.setLoadingState(true);
        this.addNewTagRequest({newTagName: refTagNameInput, categoryId: this.category.category.id});
      }
      this.closeNewTagInput();
    },
    deleteTag(tagId) {
      this.$load(async () => {
        await this.removeTagRequest({tagId: tagId})
        this.$refs.commonDeleteTagDialog.openPopups();
      }, () => {
        this.$refs.commonDeleteTagWarningDialog.openPopups();
      })
    },
    showDeleteTagPopup(tag) {
      this.infoDialogData = createTagDeleteDialog(tag.tag);
      this.$refs.infoDeleteTagDialog.openPopup(tag.id);
    },
    showDeleteCategoryPopup(category) {
      if (category.tags.length > 1 || category.tags[0].id !== 0) {
        this.$refs.commonDeleteCategoryWarningDialog.openPopups();
      } else {
        this.infoDialogData = createCategoryDeleteDialog(category.category.name);
        this.$refs.infoDeleteCategoryDialog.openPopup(category.category.id);
      }
    },
    changeCategoryName(newCatName) {
      this.editCategory({categoryId: this.category.category.id, categoryName: newCatName})
    },
    deleteCategory(categoryId) {
      this.removeCategory(categoryId)
        .then(() => this.$refs.commonDeleteCategoryDialog.openPopups());
    },
  }
}
</script>

<style scoped lang="scss">
.tag-section {
  padding: 10px 10px 0 0;
  border-top: 1px solid var(--border-color);
  max-width: 700px;
  width: 100%;

  .category-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    svg {
      cursor: grab;
    }

    .section-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
      color: var(--accent-color);
    }
  }

  .section-tags {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-color);

    .draggable {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .add-tag {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      margin: 0 0 0 35px;

      .section-header {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: var(--secondary-black-color);
      }
    }
  }
}
</style>