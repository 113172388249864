<template>
  <div>
    <div class="case-members-bar">
      <div class="holder-add-new">
        <button
          ref="addButton"
          :disabled="!hasRequiredPermission"
          :id="`goto_invite_in_${$route.params.slug}_case`"
          @click="navigateToInvitePage"
          class="btn btn-revert"
        >
          Add {{ nameOfAddBtn }}
        </button>
        <tooltip v-if="$refs.addButton && !hasRequiredPermission" :target-ref="$refs.addButton">
          <span>{{ tooltipText }}</span>
        </tooltip>
      </div>
      <div class="holder-tab-sorts">
        <div class="tab-wrapper">
          <router-link
            :to="{name: 'CaseEmployeesTable'}"
            :id="`user_in_${$route.params.slug}_case`"
            tag="button"
            exact-path
            class="tab-btn"
            :class="{'active': subIsActive('Employees')}"
          >
            <span class="tab-btn-text">Users</span>
          </router-link>
          <router-link
            :to="{name: 'CaseClientsTable'}"
            :id="`clients_in_${$route.params.slug}_case`"
            tag="button"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Custodians</span>
          </router-link>
        </div>
      </div>
      <div class="holder-permission-filters">
        <CustomSearch
          :id="`search_in_${$route.params.slug}_case`"
          :modelValue="searchText"
          @update:modelValue="searchText = $event"
          :placeholder="searchPlaceholder"
          :class-component="'custom-select-sessions'"
        />
      </div>
    </div>
    <router-view :searchText="searchText"></router-view>
  </div>

</template>

<script>
import hasPermission from "../../../utils/permission";
import CustomSearch from "../../inputs/CustomSearch";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "CaseMembersBar",
  components: {
    Tooltip,
    CustomSearch,
  },
  data() {
    return {
      searchText: "",
      searchPlaceholder: '',
    }
  },
  computed: {
    nameOfAddBtn() {
      return this.$route.name === 'CaseEmployeesTable' ? 'Users' : 'Custodians'
    },
    isCaseClientsTable() {
      return this.$route.name === 'CaseClientsTable'
    },
    hasRequiredPermission() {
      return this.hasPermission([this.isCaseClientsTable ? 'add_custodian' : 'assign_user_to_case'])
    },
    tooltipText() {
      return this.isCaseClientsTable
        ? "To gain access to add new custodians to a case, you must have the 'Assign custodian' permission in this case"
        : "You need to have the 'Assign User to Case' permission to be able to assign new users to a case on that case"
    }
  },
  watch: {
    '$route.name'(name) {
      this.searchText = ""
      this.setPlaceholder(name);
    }
  },
  methods: {
    hasPermission,
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];

      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    setPlaceholder(routeName) {
      switch (routeName) {
        case 'CaseEmployeesTable':
          this.searchPlaceholder = 'Search by name';
          break;
        case 'CaseClientsTable':
          this.searchPlaceholder = 'Search by email';
          break;
        default:
          this.searchPlaceholder = '';
      }
    },
    navigateToInvitePage() {
      const path = `/cases/${this.$route.params.slug}/${this.isCaseClientsTable ? 'invite' : 'assign'}`
      this.$router.push({ path }).catch(() => {})
    },
  },
  mounted() {
    this.setPlaceholder(this.$route.name);
  }
}
</script>

<style scoped>
.holder-permission-filters {
  margin-right: 20px;
}

.case-members-bar {
  padding: 52px 0 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bar-btns-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 305px;
}

/* tabs */
.holder-tab-sorts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  margin-right: 20px;
  width: 100%;
  min-height: 35px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 50%;
  max-height: 35px;
  padding: 10px 20px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text {
  color: var(--accent-color);
}
</style>