<template>
  <header>
    <div class="header-registered">
      <div class="header-title">
        <div id="header_nav_btn" class="title-return">
          <button @click="onGoBack" :disabled="!canGoBack">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5004 8.62531C15.5004 8.7745 15.4412 8.91757 15.3357 9.02306C15.2302 9.12855 15.0871 9.18781 14.9379 9.18781H3.92068L8.02341 13.2898C8.07567 13.3421 8.11713 13.4042 8.14541 13.4724C8.1737 13.5407 8.18826 13.6139 8.18826 13.6878C8.18826 13.7617 8.1737 13.8349 8.14541 13.9032C8.11713 13.9715 8.07567 14.0335 8.02341 14.0858C7.97115 14.138 7.90911 14.1795 7.84082 14.2078C7.77254 14.2361 7.69935 14.2506 7.62544 14.2506C7.55153 14.2506 7.47835 14.2361 7.41006 14.2078C7.34178 14.1795 7.27974 14.138 7.22747 14.0858L2.16497 9.02328C2.11267 8.97104 2.07119 8.909 2.04288 8.84072C2.01457 8.77243 2 8.69923 2 8.62531C2 8.55139 2.01457 8.4782 2.04288 8.40991C2.07119 8.34162 2.11267 8.27958 2.16497 8.22734L7.22747 3.16484C7.33302 3.0593 7.47618 3 7.62544 3C7.77471 3 7.91786 3.0593 8.02341 3.16484C8.12896 3.27039 8.18826 3.41355 8.18826 3.56281C8.18826 3.71208 8.12896 3.85523 8.02341 3.96078L3.92068 8.06281H14.9379C15.0871 8.06281 15.2302 8.12208 15.3357 8.22757C15.4412 8.33305 15.5004 8.47613 15.5004 8.62531Z"
                fill="#373C44"/>
            </svg>
          </button>
          <button @click="onGoForward" :disabled="!canGoForward">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3355 9.02328L10.273 14.0858C10.1674 14.1913 10.0243 14.2506 9.875 14.2506C9.72573 14.2506 9.58258 14.1913 9.47703 14.0858C9.37148 13.9802 9.31219 13.8371 9.31219 13.6878C9.31219 13.5385 9.37148 13.3954 9.47703 13.2898L13.5798 9.18781H2.5625C2.41332 9.18781 2.27024 9.12855 2.16475 9.02306C2.05926 8.91757 2 8.7745 2 8.62531C2 8.47613 2.05926 8.33305 2.16475 8.22757C2.27024 8.12208 2.41332 8.06281 2.5625 8.06281H13.5798L9.47703 3.96078C9.37148 3.85523 9.31219 3.71208 9.31219 3.56281C9.31219 3.41355 9.37148 3.27039 9.47703 3.16484C9.58258 3.0593 9.72573 3 9.875 3C10.0243 3 10.1674 3.0593 10.273 3.16484L15.3355 8.22734C15.3878 8.27958 15.4293 8.34162 15.4576 8.40991C15.4859 8.4782 15.5004 8.55139 15.5004 8.62531C15.5004 8.69923 15.4859 8.77243 15.4576 8.84072C15.4293 8.909 15.3878 8.97104 15.3355 9.02328Z"
                fill="#373C44"/>
            </svg>
          </button>
          <h3>
            <span v-text="title || headTitle"/>
            <slot></slot>
          </h3>
        </div>
      </div>
      <nav class="header-nav" v-if="loggingIn">
        <ul class="navigation">
          <li
            id="navigation_header_btn"
            class="nav-item">
            <HeaderNotificationsTemplate
              :notifications="notifications"
              @changedStatus="changeStatus"
            />
          </li>
          <li
            id="navigation_header_user_image"
            v-if="photoProfile"
            class="nav-item"
            @click.stop="$refs.userDropdown.toggleActive()"
          >
            <div class="holder-img">
              <img
                class="user-image"
                :src="photoProfile"
                alt=""
                onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
              >
            </div>
          </li>
          <li
            class="nav-item"
            id="profile_header_btn"
            @click.stop="$refs.userDropdown.toggleActive()"
            v-else
          >
            <div class="user-no-image">
              <span>{{ userInitials }}</span>
            </div>
          </li>
        </ul>
        <HeaderDropdown ref="userDropdown"/>
      </nav>
    </div>
  </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LocalStorageService from "../../services/LocalStorageService";
import HeaderNotificationsTemplate from "./HeaderNotificationsTemplate";
import HeaderDropdown from "./HeaderDropdown";

const localStorageService = LocalStorageService.getService();

export default {
  name: "HeaderRegistered",
  props: {
    returnPath: {
      type: Boolean
    },
    title: {
      type: String,
      default: () => null
    },
    subTitle: {
      type: String
    },
    withoutRouteName: {
      type: Boolean,
      required: false,
      default: () => false
    },
    shouldGoBackInRoute: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      loggingIn: true,
    }
  },
  computed: {
    ...mapGetters('routerHistory', {
      canGoBack: 'canGoBack',
      canGoForward: 'canGoForward',
    }),
    headTitle() {
      return this.withoutRouteName
        ? this.subTitle : this.subTitle
          ? this.$route.name + ' ' + this.subTitle : this.$route.name;
    },
    photoProfile() {
      return this.$store.state.profile.user.photoProfile
    },
    notifications() {
      return this.$store.state.notifications.notificationsList
    },
    userInitials() {
      if (this.$store.state.profile.user.firstName && this.$store.state.profile.user.lastName) {
        return this.$store.state.profile.user.firstName.substring(0, 1).toUpperCase()
          + this.$store.state.profile.user.lastName.substring(0, 1).toUpperCase();
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    ...mapActions('routerHistory', ['goBack', 'goForward']),
    onGoBack() {
      this.goBack();
    },
    onGoForward() {
      this.goForward();
    },
    changeStatus() {
      this.getNotificationAll({}).then(() => {
        this.getNotificationCount({})
      })
    },
  },
  mounted() {
    if (localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
    }

    this.getNotificationAll({}).then(() => {
      this.getNotificationCount({})
    })

    this.$store.dispatch('profile/getUser')

  },
  components: {
    HeaderDropdown,
    HeaderNotificationsTemplate
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none;
}

header {
  padding: 10px 16px;
  border-bottom: 1px solid var(--input-placeholder-value-color);
}

.header-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.title-return {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 15px;
}

.title-return button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.title-return button svg {
  margin-right: 10px;
  cursor: pointer;
}

.title-return button:disabled svg {
  opacity: 0.4;
  cursor: default;
}

.title-return h3 {
  margin: 0 15px;
}

.header-registered {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

header h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  margin: 0 30px 0 0;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  color: var(--secondary-black-color);
}

.header-nav {
  position: relative;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
}

.nav-item {
  cursor: pointer;
  position: relative;
}

.holder-img {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.user-image {
  width: 24px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 24px;
}

.notification-circle {
  position: absolute;
  top: -6px;
  right: 16px;
}

.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: #DDE3E3;
  transition: all .2s;
}

.nav-item:last-child {
  padding-right: 0;
}

.user-no-image {
  background: #1E325C;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  margin: 0 0 5px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white-color);
  font-size: 11.2px;
  font-weight: 400;
  line-height: 11.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.user-profile-public-page__wrapper .title-return svg {
  display: inline-block;
}

</style>