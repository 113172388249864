import store from '../store'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function hasPermission(value) {
    if (value && value instanceof Array && value.length > 0) {
        const roles = store.state.profile.user.permissions || []
        const permission = store.state.profile.permission.permissions || []
        const sumPermissionsList = roles.concat(permission);
        const permissionRoles = value

        return sumPermissionsList.some(role => {
            return permissionRoles.includes(role)
        })
    } else {
        return false
    }
}