<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered :title="title"/>
      <main class="main">
        <section class="dashboard-section">
          <ManageKeys/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";
import ManageKeys from "@/components/management/ManageKeys.vue";

export default {
  name: "ManagementPage",
  components: {
    HeaderRegistered,
    ManageKeys,
  },
  data() {
    return {
      title: 'Manage Custodians',
    }
  },
  watch: {
    '$route.params.slug': {
      deep: true,
      handler() {
        if (this.$route.params.slug) {
          document.title = 'Manage Custodians in ' + localStorage.getItem('title');
          this.title = 'Manage Custodians in ' + localStorage.getItem('title');
        } else {
          document.title = 'Manage Custodians';
          this.title = 'Manage Custodians';
        }
      }
    },
  },
  // mounted() {
  //   if (this.$route.params.slug) {
  //     document.title = 'Manage Custodians in ' + localStorage.getItem('title');
  //     this.title = 'Manage Custodians in ' + localStorage.getItem('title');
  //   }
  //   document.title = 'Manage Custodians'
  // }
}
</script>

<style scoped>

</style>