<template>
  <div class="case-list">
    <div v-if="showSearchPanel" class="cases-search-panel">
      <CustomSearch
        :modelValue="keySearch"
        id="cases_search_btn"
        @update:modelValue="keySearch = $event"
        @filter="filter"
        :classComponent="'case'"/>
      <div class="cases-filter-panel">
        <div id="disabled-create-case-button" ref="disabledCreateCaseButton">
          <button
            :disabled="!hasPermission(['case_management'])"
            @click="$router.push({name: 'Create Case'})"
            id="button_create_case"
            class="btn btn-create-case"
          >Create new
          </button>
        </div>
        <tooltip
          v-if="$refs.disabledCreateCaseButton && !hasPermission(['case_management'])"
          :target-ref="$refs.disabledCreateCaseButton"
        >
          <span>To access case creation - you need to have system permission - 'Case management'</span>
        </tooltip>
        <div class="tab-wrapper">
          <div
            class="tab-btn"
            :class="{ active: tabView === 'all'}"
            @click="setCasesTabView('all')"
          >
            <span class="tab-btn-text">All</span>
          </div>
          <div
            class="tab-btn"
            :class="{ active: tabView === 'noMatchData'}"
            @click="setCasesTabView('noMatchData')"
          >
            <span class="tab-btn-text">No Matching Data</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cases-list-wrapper">
      <div v-if="loadFlag" class="loader-bg">
        <div class="loader-p"></div>
      </div>
      <div v-else-if="keySearch && casesFromApi.length === 0" class="creating-cases">
        <div class="default-cases-image">
          <img src="@/assets/img/no-cases-found.svg" alt="no-cases-found">
        </div>
        <h3 class="creating-cases-title">No cases found, try again</h3>
      </div>
      <div v-else-if="showEmptySection && tabView === 'noMatchData'" class="creating-cases">
        <h3 class="creating-cases-title">No cases found</h3>
      </div>
      <div v-else-if="showEmptySection" class="creating-cases">
        <div class="default-cases-image">
          <img src="@/assets/img/if-cases-is-empty.svg" alt="start-creating-cases">
        </div>
        <h3 class="creating-cases-title">Start with creating cases</h3>
        <div id="disabled-create-case-button-empty" ref="disabledCreateCaseButtonEmpty">
          <button
            id="create_case_button"
            :disabled="!hasPermission(['case_management'])"
            @click="$router.push({name: 'Create Case'})"
            class="btn create-case-btn">
            Create case
          </button>
        </div>
        <tooltip
          v-if="$refs.disabledCreateCaseButtonEmpty && !hasPermission(['case_management'])"
          side="bottom"
          :target-ref="$refs.disabledCreateCaseButtonEmpty"
        >
          <span>To access case creation - you need to have system permission - 'Case management'</span>
        </tooltip>
      </div>
      <div class="cases" v-else>
        <div class="cases-wrapper">
          <Case
            v-for="(caseItem, index) in casesFromApi"
            :key="index"
            :caseItem="caseItem"
            @caseDeleted="getCases"
          />
        </div>
        <pagination
          v-show="count !== 1"
          v-model="page"
          :total-pages="count"
          :link-gen="linkGen"
          ref="pagination"
        ></pagination>
      </div>
      <FiltersPopupWrapper ref="caseFilter">
        <CasesFilterBlock/>
      </FiltersPopupWrapper>
    </div>
  </div>
</template>
<script>
import linkGenerator from "@/constants/linkGen";
import hasPermission from "@/utils/permission";
import Case from "./Case";
import CustomSearch from "@/components/inputs/CustomSearch";
import FiltersPopupWrapper from "@/components/popups/FiltersPopupWrapper";
import CasesFilterBlock from "../case-filters/CasesFilterBlock";
import Tooltip from "@/components/common/Tooltip.vue";
import Pagination from "@/components/common/Pagination.vue";
import debounce from "@/mixins/debounce";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CasesList",
  components: {
    Pagination,
    Tooltip,
    Case,
    CustomSearch,
    FiltersPopupWrapper,
    CasesFilterBlock
  },
  data() {
    return {
      casesFromApi: [],
      caseName: '',
      page: 1,
      size: 9,
      count: 1,
      pageSize: 2,
      showEmptySection: false,
      showSearchPanel: true,
      loadFlag: false,
      keySearch: "",
      errorMessage: '',
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.getCases();
        } else {
          this.page = 1;
        }
      }
    },
    tabView: {
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (this.page === 1) {
            this.getCases();
          } else {
            await this.$router.push(this.linkGen(1));
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('appState', {
      tabView: 'getCasesTabView',
    })
  },
  mixins: [debounce],
  methods: {
    ...mapActions('appState', ['setCasesTabView']),
    hasPermission,
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/cases')
    },
    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getCases() {
      this.loadFlag = true;
      this.$load(async () => {
        let page = this.page
        let size = this.size
        await this.$api.cases.getCases({
          caseName: this.fixedEncodeURIComponent(this.keySearch),
          page,
          size,
          withNoMatchData: this.tabView === 'noMatchData',
        }).then((res) => {
            this.casesFromApi = res.data.body.content
            this.count = res.data.body.totalPages
            this.pageSize = res.data.body.size
            if (!this.keySearch.length && this.casesFromApi.length) this.showSearchPanel = true;
            this.showEmptySection = this.keySearch.length === 0 && this.casesFromApi.length === 0;
            this.debounce(() => this.loadFlag = false);
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.debounce(() => this.loadFlag = false);
          })
        )
      })
    },
    filter() {
      this.getCases()
    },
  },
  mounted() {
    this.page = +this.$route.query.page || 1
    this.getCases()
  },
}
</script>

<style scoped>
.case-list {
  width: 100%;
}

.creating-cases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 105px 0 0 0;
  text-align: center;
}

.default-cases-image {
  max-width: 387px;
  margin: 0 auto;
}

.default-cases-image img {
  max-width: 100%;
  max-height: 100%;
}

.creating-cases-title {
  margin: 33px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--light-gray);
}

.create-case-btn {
  padding: 9px 19px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.create-case-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.cases-filter-panel {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.cases-search-panel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.cases-wrapper {
  width: 100%;
  padding: 60px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.btn-create-case {
  margin-right: 30px;
  padding: 14px 20px;
  border-radius: 25px;
  height: 35px;
  max-width: 120px;
  width: 100%;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: auto;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  min-width: 190px;
  width: 50%;
  max-height: 35px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
  cursor: pointer;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text,
.tab-btn:hover .tab-btn-text {
  color: var(--accent-color);
}

.cases-list-wrapper {
  margin: 30px 0 0;
  position: relative;
  height: 79vh;
  overflow: scroll;
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
}

</style>
