export default function(instance) {
  return {
    getSessions({caseId, sortBy, sortOrder, keySearch, dateFrom, dateTo}) {
      return instance.get(`/screenshot/case/${caseId}/sessions?sortBy=${sortBy}&sortOrder=${sortOrder}`
      + `&keySearch=${keySearch}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
    },
    getClientSessions({caseId, clientId, sortOrder}) {
      return instance.get(`/screenshot/clientId/${clientId}/sessions?sortOrder=${sortOrder}&caseId=${caseId}`)
    },
    getScreenshots(caseId, sessionId) {
      return instance.get(`/screenshot/case/${caseId}/session/${sessionId}`)
    },
    getClientCollections({sortBy, sortOrder, dateFrom, dateTo, caseId, deviceType, clientId, page, size}) {
      return instance.get(`/caseCollection/collections?sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}`
      +`&dateTo=${dateTo}&caseId=${caseId}&deviceType=${deviceType}&clientId=${clientId}&page=${page}&size=${size}`)
    },
  }
}