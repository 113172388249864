<template>
  <svg width="90" height="41" viewBox="0 0 90 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M87.328 23.3583H74.0751V19.1427H87.328V23.3583ZM89.2167 32.7871H74.0751C73.4939 32.7871 72.859 32.6852 72.1703 32.4814C71.4815 32.2776 70.8412 31.9451 70.2493 31.4838C69.6682 31.0118 69.1785 30.4058 68.7803 29.6656C68.3929 28.9148 68.1992 27.9976 68.1992 26.9142V11.8217C68.1992 11.5321 68.253 11.2585 68.3606 11.0011C68.4683 10.7436 68.6135 10.5184 68.7965 10.3253C68.9902 10.1322 69.2162 9.98204 69.4745 9.87477C69.7327 9.7675 70.0126 9.71387 70.3139 9.71387H89.2167V13.8973H72.3963V26.9142C72.3963 27.4613 72.5415 27.8796 72.8321 28.1692C73.1227 28.4589 73.5477 28.6037 74.1073 28.6037H89.2167V32.7871Z" fill="#082655"/>
    <path d="M64.0667 32.7866H59.8374V19.0456L52.3957 32.0947C52.2128 32.4272 51.9545 32.6793 51.6209 32.8509C51.2981 33.0226 50.9483 33.1084 50.5716 33.1084C50.2058 33.1084 49.8614 33.0226 49.5385 32.8509C49.2264 32.6793 48.9789 32.4272 48.796 32.0947L41.322 19.0456V32.7866H37.125V11.4993C37.125 11.0166 37.2649 10.5876 37.5447 10.2121C37.8353 9.8367 38.2119 9.58462 38.6747 9.4559C38.9007 9.40226 39.1267 9.38617 39.3527 9.40763C39.5786 9.41835 39.7939 9.46662 39.9984 9.55244C40.2136 9.62752 40.4073 9.74016 40.5795 9.89033C40.7517 10.0298 40.8969 10.2014 41.0153 10.4052L50.5716 26.962L60.128 10.4052C60.3755 9.9976 60.7145 9.70798 61.1449 9.53635C61.5862 9.36472 62.0435 9.3379 62.517 9.4559C62.969 9.58462 63.3403 9.8367 63.6309 10.2121C63.9214 10.5876 64.0667 11.0166 64.0667 11.4993V32.7866Z" fill="#082655"/>
    <path d="M34.1868 19.3679C34.1868 20.3226 34.0684 21.1754 33.8317 21.9263C33.5949 22.6771 33.2721 23.3422 32.8631 23.9214C32.4649 24.49 32.0022 24.978 31.4749 25.3857C30.9475 25.7933 30.3933 26.1258 29.8122 26.3832C29.2418 26.6407 28.6607 26.8284 28.0688 26.9464C27.4877 27.0644 26.9388 27.1234 26.4223 27.1234H16.9305V22.94H26.4223C26.9604 22.897 27.4446 22.7898 27.8751 22.6181C28.3163 22.4358 28.693 22.1944 29.0051 21.8941C29.3172 21.5937 29.5593 21.2344 29.7315 20.8161C29.9037 20.387 29.9898 19.9043 29.9898 19.3679V17.4693C29.936 16.9437 29.823 16.461 29.6508 16.0212C29.4786 15.5814 29.2418 15.206 28.9405 14.8949C28.6499 14.5838 28.2948 14.3425 27.8751 14.1708C27.4554 13.9885 26.9711 13.8973 26.4223 13.8973H16.9628C16.4032 13.8973 15.9781 14.0421 15.6876 14.3317C15.397 14.6214 15.2517 15.0397 15.2517 15.5868V32.7871H11.0547V15.5868C11.0547 14.5141 11.2484 13.6023 11.6358 12.8514C12.034 12.1006 12.5236 11.4945 13.1048 11.0333C13.6967 10.572 14.337 10.2395 15.0257 10.0357C15.7145 9.82113 16.3494 9.71387 16.9305 9.71387H26.4223C27.3693 9.71387 28.2195 9.83722 28.9728 10.0839C29.7261 10.3199 30.3879 10.6417 30.9583 11.0493C31.5394 11.4462 32.0291 11.9075 32.4273 12.4331C32.8362 12.9587 33.1698 13.5111 33.4281 14.0904C33.6971 14.6589 33.8909 15.2381 34.0092 15.8281C34.1276 16.4074 34.1868 16.9544 34.1868 17.4693V19.3679Z" fill="#082655"/>
    <path d="M86.4707 36.5674H87.2657L88.2163 38.2808H88.2542L89.2048 36.5674H89.9998L88.5863 38.9957V40.4406H87.8842V38.9957L86.4707 36.5674Z" fill="#5692B7"/>
    <path d="M83.4141 40.4406V36.5674H84.8712C85.1698 36.5674 85.4202 36.6191 85.6226 36.7225C85.8263 36.8258 85.9799 36.9708 86.0837 37.1574C86.1886 37.3428 86.2411 37.559 86.2411 37.8061C86.2411 38.0545 86.188 38.2701 86.0818 38.4529C85.9768 38.6345 85.8218 38.7751 85.6169 38.8747C85.412 38.973 85.1603 39.0222 84.8618 39.0222H83.8239V38.4397H84.7669C84.9414 38.4397 85.0844 38.4157 85.1957 38.3678C85.307 38.3187 85.3892 38.2474 85.4423 38.1541C85.4967 38.0596 85.5239 37.9436 85.5239 37.8061C85.5239 37.6687 85.4967 37.5514 85.4423 37.4544C85.388 37.356 85.3051 37.2816 85.1938 37.2312C85.0825 37.1795 84.9389 37.1537 84.7631 37.1537H84.118V40.4406H83.4141ZM85.4215 38.6855L86.3834 40.4406H85.5979L84.653 38.6855H85.4215Z" fill="#5692B7"/>
    <path d="M80.168 40.4406V36.5674H82.6953V37.1556H80.8719V38.2071H82.5643V38.7952H80.8719V39.8524H82.7104V40.4406H80.168Z" fill="#5692B7"/>
    <path d="M76.822 36.5674L77.8333 39.616H77.8732L78.8826 36.5674H79.6567L78.2868 40.4406H77.4178L76.0498 36.5674H76.822Z" fill="#5692B7"/>
    <path d="M75.8453 38.5042C75.8453 38.9215 75.7668 39.279 75.61 39.5765C75.4544 39.8728 75.2419 40.0998 74.9725 40.2574C74.7043 40.415 74.4001 40.4938 74.0598 40.4938C73.7196 40.4938 73.4147 40.415 73.1453 40.2574C72.8771 40.0985 72.6646 39.8709 72.5078 39.5746C72.3522 39.2771 72.2744 38.9203 72.2744 38.5042C72.2744 38.0869 72.3522 37.7301 72.5078 37.4338C72.6646 37.1362 72.8771 36.9087 73.1453 36.7511C73.4147 36.5934 73.7196 36.5146 74.0598 36.5146C74.4001 36.5146 74.7043 36.5934 74.9725 36.7511C75.2419 36.9087 75.4544 37.1362 75.61 37.4338C75.7668 37.7301 75.8453 38.0869 75.8453 38.5042ZM75.1375 38.5042C75.1375 38.2104 75.0914 37.9627 74.999 37.761C74.908 37.558 74.7815 37.4048 74.6196 37.3014C74.4576 37.1967 74.2711 37.1444 74.0598 37.1444C73.8486 37.1444 73.662 37.1967 73.5001 37.3014C73.3382 37.4048 73.2111 37.558 73.1187 37.761C73.0277 37.9627 72.9821 38.2104 72.9821 38.5042C72.9821 38.798 73.0277 39.0464 73.1187 39.2493C73.2111 39.4511 73.3382 39.6043 73.5001 39.7089C73.662 39.8123 73.8486 39.864 74.0598 39.864C74.2711 39.864 74.4576 39.8123 74.6196 39.7089C74.7815 39.6043 74.908 39.4511 74.999 39.2493C75.0914 39.0464 75.1375 38.798 75.1375 38.5042Z" fill="#5692B7"/>
    <path d="M71.7275 37.8744H71.0179C70.9976 37.7584 70.9603 37.6557 70.9059 37.5662C70.8515 37.4754 70.7839 37.3985 70.7029 37.3354C70.6219 37.2724 70.5296 37.2251 70.4259 37.1936C70.3234 37.1608 70.2127 37.1444 70.0938 37.1444C69.8826 37.1444 69.6954 37.1974 69.5322 37.3033C69.3691 37.4079 69.2413 37.5618 69.149 37.7647C69.0566 37.9665 69.0104 38.213 69.0104 38.5042C69.0104 38.8005 69.0566 39.0501 69.149 39.2531C69.2426 39.4549 69.3703 39.6074 69.5322 39.7108C69.6954 39.8129 69.882 39.864 70.0919 39.864C70.2083 39.864 70.3171 39.8489 70.4183 39.8186C70.5208 39.7871 70.6125 39.7411 70.6934 39.6805C70.7756 39.62 70.8446 39.5456 70.9002 39.4574C70.9571 39.3691 70.9964 39.2683 71.0179 39.1548L71.7275 39.1586C71.7009 39.3426 71.6434 39.5154 71.5548 39.6768C71.4675 39.8381 71.3531 39.9806 71.2114 40.1042C71.0697 40.2265 70.904 40.3223 70.7143 40.3916C70.5245 40.4597 70.3139 40.4938 70.0825 40.4938C69.7409 40.4938 69.4361 40.415 69.1679 40.2574C68.8998 40.0998 68.6885 39.8722 68.5342 39.5746C68.3799 39.2771 68.3027 38.9203 68.3027 38.5042C68.3027 38.0869 68.3805 37.7301 68.5361 37.4338C68.6917 37.1362 68.9036 36.9087 69.1717 36.7511C69.4399 36.5934 69.7435 36.5146 70.0825 36.5146C70.2988 36.5146 70.4999 36.5449 70.6858 36.6054C70.8718 36.6659 71.0375 36.7548 71.1829 36.8721C71.3284 36.9881 71.4479 37.1306 71.5415 37.2995C71.6364 37.4672 71.6984 37.6588 71.7275 37.8744Z" fill="#5692B7"/>
    <path d="M67.0442 37.6324C67.0265 37.4672 66.9518 37.3386 66.8203 37.2465C66.69 37.1545 66.5205 37.1085 66.3118 37.1085C66.1651 37.1085 66.0392 37.1306 65.9342 37.1747C65.8292 37.2188 65.7489 37.2787 65.6932 37.3543C65.6376 37.43 65.6091 37.5164 65.6079 37.6134C65.6079 37.6941 65.6262 37.7641 65.6629 37.8234C65.7008 37.8826 65.7521 37.9331 65.8166 37.9747C65.8811 38.015 65.9525 38.0491 66.031 38.0768C66.1094 38.1045 66.1884 38.1279 66.2681 38.1468L66.6324 38.2375C66.7792 38.2716 66.9202 38.3176 67.0555 38.3756C67.1922 38.4336 67.3142 38.5067 67.4217 38.595C67.5305 38.6832 67.6165 38.7898 67.6798 38.9146C67.743 39.0394 67.7746 39.1857 67.7746 39.3534C67.7746 39.5803 67.7165 39.7802 67.6001 39.9529C67.4837 40.1244 67.3155 40.2586 67.0954 40.3557C66.8766 40.4515 66.6116 40.4994 66.3004 40.4994C65.9981 40.4994 65.7356 40.4528 65.513 40.3595C65.2916 40.2662 65.1183 40.13 64.9931 39.951C64.8691 39.772 64.8021 39.5538 64.792 39.2966H65.4845C65.4946 39.4315 65.5364 39.5437 65.6098 39.6333C65.6831 39.7228 65.7786 39.7896 65.8963 39.8337C66.0152 39.8779 66.148 39.8999 66.2947 39.8999C66.4478 39.8999 66.5818 39.8772 66.6969 39.8318C66.8133 39.7852 66.9044 39.7209 66.9702 39.6389C67.0359 39.5557 67.0695 39.4586 67.0707 39.3477C67.0695 39.2468 67.0397 39.1636 66.9815 39.0981C66.9234 39.0312 66.8418 38.9758 66.7368 38.9316C66.6331 38.8862 66.5116 38.8459 66.3725 38.8106L65.9304 38.6971C65.6104 38.6152 65.3574 38.491 65.1715 38.3245C64.9868 38.1569 64.8944 37.9343 64.8944 37.6569C64.8944 37.4287 64.9564 37.2289 65.0804 37.0574C65.2056 36.886 65.3757 36.7529 65.5908 36.6584C65.8058 36.5626 66.0493 36.5146 66.3213 36.5146C66.597 36.5146 66.8386 36.5626 67.0461 36.6584C67.2548 36.7529 67.4186 36.8847 67.5375 37.0536C67.6564 37.2213 67.7177 37.4142 67.7215 37.6324H67.0442Z" fill="#5692B7"/>
    <path d="M64.1697 36.5674V40.4406H63.4658V36.5674H64.1697Z" fill="#5692B7"/>
    <path d="M60.9105 40.4406H59.5938V36.5674H60.9371C61.3229 36.5674 61.6543 36.6449 61.9313 36.8C62.2096 36.9538 62.4234 37.1751 62.5726 37.4638C62.7219 37.7525 62.7965 38.098 62.7965 38.5002C62.7965 38.9037 62.7212 39.2504 62.5707 39.5404C62.4215 39.8304 62.2058 40.0529 61.9237 40.208C61.6429 40.3631 61.3052 40.4406 60.9105 40.4406ZM60.2977 39.8335H60.8764C61.1471 39.8335 61.3728 39.7843 61.5537 39.686C61.7346 39.5864 61.8706 39.4383 61.9617 39.2416C62.0527 39.0436 62.0983 38.7965 62.0983 38.5002C62.0983 38.2039 62.0527 37.9581 61.9617 37.7626C61.8706 37.5659 61.7359 37.4191 61.5575 37.322C61.3804 37.2236 61.1603 37.1745 60.8972 37.1745H60.2977V39.8335Z" fill="#5692B7"/>
    <path d="M57.6879 40.4975C57.3957 40.4975 57.1434 40.437 56.9308 40.316C56.7196 40.1937 56.5571 40.0209 56.4432 39.7978C56.3294 39.5733 56.2725 39.3092 56.2725 39.0053C56.2725 38.7065 56.3294 38.4443 56.4432 38.2186C56.5583 37.9917 56.719 37.8151 56.9252 37.6891C57.1313 37.5617 57.3736 37.498 57.6518 37.498C57.8315 37.498 58.001 37.527 58.1603 37.585C58.321 37.6418 58.4627 37.73 58.5853 37.8498C58.7093 37.9696 58.8067 38.1221 58.8775 38.3075C58.9484 38.4916 58.9838 38.7109 58.9838 38.9656V39.1756H56.595V38.7141H58.3254C58.3241 38.583 58.2957 38.4664 58.24 38.3642C58.1844 38.2608 58.1066 38.1795 58.0067 38.1203C57.908 38.061 57.7929 38.0314 57.6613 38.0314C57.5209 38.0314 57.3976 38.0654 57.2913 38.1335C57.1851 38.2003 57.1022 38.2886 57.0428 38.3983C56.9846 38.5067 56.9549 38.6258 56.9536 38.7557V39.1585C56.9536 39.3275 56.9846 39.4725 57.0466 39.5935C57.1086 39.7133 57.1952 39.8053 57.3065 39.8696C57.4178 39.9327 57.5481 39.9642 57.6974 39.9642C57.7973 39.9642 57.8878 39.9503 57.9687 39.9226C58.0497 39.8936 58.1199 39.8514 58.1793 39.7959C58.2388 39.7404 58.2837 39.6717 58.314 39.5897L58.9553 39.6616C58.9149 39.8305 58.8377 39.9781 58.7239 40.1041C58.6113 40.229 58.4671 40.326 58.2913 40.3954C58.1154 40.4635 57.9143 40.4975 57.6879 40.4975Z" fill="#5692B7"/>
    <path d="M51.8564 40.4406V36.5674H54.3837V37.1556H52.5604V38.2071H54.2528V38.7952H52.5604V39.8524H54.3989V40.4406H51.8564Z" fill="#5692B7"/>
    <path d="M48.8301 40.4406V36.5674H49.534V39.8524H51.2454V40.4406H48.8301Z" fill="#5692B7"/>
    <path d="M48.0682 36.5674V40.4406H47.3643V36.5674H48.0682Z" fill="#5692B7"/>
    <path d="M43.8525 40.4406V36.5674H45.3401C45.6209 36.5674 45.8543 36.6115 46.0402 36.6998C46.2274 36.7868 46.3672 36.9059 46.4595 37.0572C46.5531 37.2085 46.5999 37.38 46.5999 37.5716C46.5999 37.7292 46.5696 37.8641 46.5089 37.9763C46.4481 38.0873 46.3665 38.1774 46.2641 38.2468C46.1616 38.3161 46.0472 38.3659 45.9207 38.3962V38.434C46.0585 38.4416 46.1907 38.4838 46.3172 38.5607C46.445 38.6364 46.5493 38.7435 46.6303 38.8822C46.7112 39.0209 46.7517 39.1886 46.7517 39.3853C46.7517 39.5858 46.703 39.7661 46.6056 39.9262C46.5082 40.085 46.3615 40.2105 46.1654 40.3025C45.9694 40.3946 45.7227 40.4406 45.4255 40.4406H43.8525ZM44.5565 39.8543H45.3135C45.569 39.8543 45.7531 39.8058 45.8656 39.7087C45.9795 39.6104 46.0364 39.4843 46.0364 39.3305C46.0364 39.2157 46.0079 39.1123 45.951 39.0203C45.8941 38.927 45.8131 38.8539 45.7082 38.8009C45.6032 38.7467 45.4779 38.7196 45.3325 38.7196H44.5565V39.8543ZM44.5565 38.2146H45.2528C45.3742 38.2146 45.4836 38.1926 45.581 38.1484C45.6784 38.1031 45.755 38.0394 45.8106 37.9574C45.8675 37.8742 45.896 37.7759 45.896 37.6624C45.896 37.5124 45.8429 37.3888 45.7366 37.2917C45.6316 37.1946 45.4754 37.1461 45.268 37.1461H44.5565V38.2146Z" fill="#5692B7"/>
    <path d="M43.1871 38.5042C43.1871 38.9215 43.1086 39.279 42.9518 39.5765C42.7962 39.8728 42.5837 40.0998 42.3143 40.2574C42.0461 40.415 41.7419 40.4938 41.4016 40.4938C41.0614 40.4938 40.7565 40.415 40.4871 40.2574C40.2189 40.0985 40.0064 39.8709 39.8496 39.5746C39.694 39.2771 39.6162 38.9203 39.6162 38.5042C39.6162 38.0869 39.694 37.7301 39.8496 37.4338C40.0064 37.1362 40.2189 36.9087 40.4871 36.7511C40.7565 36.5934 41.0614 36.5146 41.4016 36.5146C41.7419 36.5146 42.0461 36.5934 42.3143 36.7511C42.5837 36.9087 42.7962 37.1362 42.9518 37.4338C43.1086 37.7301 43.1871 38.0869 43.1871 38.5042ZM42.4793 38.5042C42.4793 38.2104 42.4332 37.9627 42.3408 37.761C42.2498 37.558 42.1233 37.4048 41.9614 37.3014C41.7994 37.1967 41.6129 37.1444 41.4016 37.1444C41.1904 37.1444 41.0038 37.1967 40.8419 37.3014C40.68 37.4048 40.5529 37.558 40.4605 37.761C40.3695 37.9627 40.3239 38.2104 40.3239 38.5042C40.3239 38.798 40.3695 39.0464 40.4605 39.2493C40.5529 39.4511 40.68 39.6043 40.8419 39.7089C41.0038 39.8123 41.1904 39.864 41.4016 39.864C41.6129 39.864 41.7994 39.8123 41.9614 39.7089C42.1233 39.6043 42.2498 39.4511 42.3408 39.2493C42.4332 39.0464 42.4793 38.798 42.4793 38.5042Z" fill="#5692B7"/>
    <path d="M34.874 36.5674H35.7354L36.889 39.374H36.9346L38.0882 36.5674H38.9496V40.4406H38.2741V37.7797H38.2381L37.1641 40.4293H36.6594L35.5855 37.774H35.5495V40.4406H34.874V36.5674Z" fill="#5692B7"/>
    <path d="M30.5215 40.4406V36.5674H31.2254V39.8524H32.9368V40.4406H30.5215Z" fill="#5692B7"/>
    <path d="M27.1527 40.4406H26.4014L27.7694 36.5674H28.6384L30.0083 40.4406H29.2569L28.219 37.3617H28.1887L27.1527 40.4406ZM27.1774 38.9219H29.2265V39.4855H27.1774V38.9219Z" fill="#5692B7"/>
    <path d="M23.4268 37.1556V36.5674H26.5271V37.1556H25.326V40.4406H24.6278V37.1556H23.4268Z" fill="#5692B7"/>
    <path d="M23.1031 38.5042C23.1031 38.9215 23.0246 39.279 22.8678 39.5765C22.7122 39.8728 22.4997 40.0998 22.2303 40.2574C21.9621 40.415 21.6579 40.4938 21.3176 40.4938C20.9774 40.4938 20.6725 40.415 20.4031 40.2574C20.135 40.0985 19.9225 39.8709 19.7656 39.5746C19.61 39.2771 19.5322 38.9203 19.5322 38.5042C19.5322 38.0869 19.61 37.7301 19.7656 37.4338C19.9225 37.1362 20.135 36.9087 20.4031 36.7511C20.6725 36.5934 20.9774 36.5146 21.3176 36.5146C21.6579 36.5146 21.9621 36.5934 22.2303 36.7511C22.4997 36.9087 22.7122 37.1362 22.8678 37.4338C23.0246 37.7301 23.1031 38.0869 23.1031 38.5042ZM22.3954 38.5042C22.3954 38.2104 22.3492 37.9627 22.2568 37.761C22.1658 37.558 22.0393 37.4048 21.8774 37.3014C21.7155 37.1967 21.5289 37.1444 21.3176 37.1444C21.1064 37.1444 20.9198 37.1967 20.7579 37.3014C20.596 37.4048 20.4689 37.558 20.3766 37.761C20.2855 37.9627 20.2399 38.2104 20.2399 38.5042C20.2399 38.798 20.2855 39.0464 20.3766 39.2493C20.4689 39.4511 20.596 39.6043 20.7579 39.7089C20.9198 39.8123 21.1064 39.864 21.3176 39.864C21.5289 39.864 21.7155 39.8123 21.8774 39.7089C22.0393 39.6043 22.1658 39.4511 22.2568 39.2493C22.3492 39.0464 22.3954 38.798 22.3954 38.5042Z" fill="#5692B7"/>
    <path d="M16.4949 36.5674L17.5062 39.616H17.546L18.5554 36.5674H19.3295L17.9597 40.4406H17.0907L15.7227 36.5674H16.4949Z" fill="#5692B7"/>
    <path d="M15.2117 36.5674V40.4406H14.5078V36.5674H15.2117Z" fill="#5692B7"/>
    <path d="M11.0693 40.4416V36.5684H12.5265C12.825 36.5684 13.0755 36.6238 13.2779 36.7348C13.4815 36.8457 13.6352 36.9983 13.7389 37.1925C13.8439 37.3854 13.8964 37.6047 13.8964 37.8506C13.8964 38.099 13.8439 38.3196 13.7389 38.5125C13.6339 38.7054 13.479 38.8574 13.2741 38.9683C13.0692 39.078 12.8168 39.1329 12.517 39.1329H11.5513V38.556H12.4222C12.5967 38.556 12.7397 38.5258 12.851 38.4653C12.9623 38.4047 13.0445 38.3215 13.0976 38.2156C13.152 38.1097 13.1792 37.988 13.1792 37.8506C13.1792 37.7132 13.152 37.5921 13.0976 37.4875C13.0445 37.3828 12.9616 37.3015 12.8491 37.2435C12.7378 37.1843 12.5942 37.1546 12.4184 37.1546H11.7733V40.4416H11.0693Z" fill="#5692B7"/>
    <path d="M4.49525 21.214L4.50894 21.3027C6.91321 11.9828 14.5271 5.43669 18.0335 3.32861C15.9741 3.32861 10.3527 3.66147 6.67941 7.98857C3.1039 12.2005 3.83099 16.9108 4.49525 21.214Z" fill="url(#paint0_linear_25964_7106)"/>
    <path d="M0.389723 13.086L0.399463 13.1485C2.10938 6.59 7.52435 1.98346 10.0181 0.5C8.55352 0.5 4.55553 0.734231 1.9431 3.77923C-0.599801 6.74319 -0.0826919 10.0579 0.389723 13.086Z" fill="url(#paint1_linear_25964_7106)"/>
    <defs>
      <linearGradient id="paint0_linear_25964_7106" x1="11.0453" y1="3.32861" x2="11.0453" y2="21.3027" gradientUnits="userSpaceOnUse">
        <stop stop-color="#62A2C0"/>
        <stop offset="1" stop-color="#32375C"/>
      </linearGradient>
      <linearGradient id="paint1_linear_25964_7106" x1="5.04815" y1="0.5" x2="5.04815" y2="13.1485" gradientUnits="userSpaceOnUse">
        <stop stop-color="#62A2C0"/>
        <stop offset="1" stop-color="#32375C"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "LogoIconBlue",
}
</script>

<style scoped>

</style>