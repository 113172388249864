import router from '@/router/index'

export default {
    namespaced: true,
    state: {
        visitedRoutes: [],
        currentIndex: -1,
        navigatingHistory: false,
    },
    mutations: {
        ADD_ROUTE(state, routePath) {
            state.visitedRoutes.push(routePath);
            state.currentIndex = state.visitedRoutes.length - 1;
        },
        SPLICE_FORWARD_ROUTES(state) {
            if (state.currentIndex < state.visitedRoutes.length - 1) {
                state.visitedRoutes.splice(state.currentIndex + 1);
            }
        },
        GO_BACK(state) {
            if (state.currentIndex > 0) {
                state.currentIndex--;
            }
        },
        GO_FORWARD(state) {
            if (state.currentIndex < state.visitedRoutes.length - 1) {
                state.currentIndex++;
            }
        },
        SET_NAVIGATING_HISTORY(state, value) {
            state.navigatingHistory = value;
        }
    },
    actions: {
        addRoute({state, commit}, routePath) {
            if (state.navigatingHistory) return;
            commit('ADD_ROUTE', routePath);
        },

        goBack({state, commit}) {
            if (state.currentIndex > 0) {
                const nextIndex = state.currentIndex - 1;
                const targetPath = state.visitedRoutes[nextIndex];

                if (targetPath !== router.currentRoute.path) {
                    commit('GO_BACK');
                    commit('SET_NAVIGATING_HISTORY', true);
                    router.push(targetPath);
                }
            } else {
                router.push('/');
            }
        },

        goForward({state, commit}) {
            if (state.currentIndex < state.visitedRoutes.length - 1) {
                const nextIndex = state.currentIndex + 1;
                const targetPath = state.visitedRoutes[nextIndex];

                if (targetPath !== router.currentRoute.path) {
                    commit('GO_FORWARD');
                    commit('SET_NAVIGATING_HISTORY', true);
                    router.push(targetPath);
                }
            }
        }
    },
    getters: {
        canGoBack: (state) => state.currentIndex > 0,
        canGoForward: (state) => state.currentIndex < state.visitedRoutes.length - 1
    }
};