<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <create-case-item/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CreateCaseItem from "../components/create-cases/CreateCaseItem";

export default {
  name: "CreateCase",
  components: {
    HeaderRegistered,
    CreateCaseItem,
  },
  mounted() {
    document.title = 'Create case'
  }
}
</script>

<style scoped>
</style>
