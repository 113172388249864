<template>
  <div class="permissions-accordeon">
    <Loader v-if="loadFlag" :load-flag="loadFlag" />
    <div class="header">
      <h3>
        Set permissions for <span>{{ title }}</span>
      </h3>
      <CustomSelect
        v-if="$route.path.includes('individual')"
        :options="roles"
        v-model="chosenRole"
        @input="$emit('changeRole', $event)"
        placeholder="Change role to"
        style="max-width: 200px"
      />
    </div>
    <vsa-list
        class="permissions-accordeon-list"
        :init-active="true"
        :auto-collapse="false"
        v-if="permission"
    >
      <vsa-item
          v-for="(item, name, index) in permission"
          :key="index"
          :item="item"
          :transition="'0'"
      >
<!--        <vsa-heading v-if="name">{{name.split('_').join(' ')}}</vsa-heading>-->
        <vsa-heading>{{String(name).split('_').join(' ')}}</vsa-heading>

        <vsa-content>
          <div class="div-group" v-if="item">
            <CustomCheckbox
                v-for="(el, elIndex) in item"
                :key="elIndex"
                :id="'checkbox_' + elIndex + '_' + index"
                :inputElement="el"
                :label="el.description"
                :labelText="el.name"
                :label-id="'label_checkbox_' + elIndex + '_' + index"
                :checkPermission="disabledCheckbox"
                @checked="changeCheckbox"
            />
          </div>
        </vsa-content>
      </vsa-item>
      <div class="holder-confirm disabled-permission" id="disabled-manage-individual-rights" ref="disabledManageIndividualRights">
        <button
            id="change_permission_confirm_button"
            :disabled="!hasPermission(['manage_individual_rights']) || disableButtonSubmit && isSending"
            class="btn"
            @click="submit"
        >
          Confirm
        </button>
      </div>
      <tooltip v-if="!hasPermission(['manage_individual_rights'])" :target-ref="$refs.disabledManageIndividualRights">
        <span>To gain access to change permissions - you need to get permission 'Manage individual rights'</span>
      </tooltip>
    </vsa-list>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import CustomCheckbox from "../inputs/CustomCheckbox";
import Loader from "../loader/Loader";
import hasPermission from "../../utils/permission";
import Tooltip from "@/components/common/Tooltip.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {ROLES_ARR} from "@/constants/const";

const isRoleChosen = (value) => {
  return ROLES_ARR.some((role) => role.name === value);
}

export default {
  components: {
    CustomSelect,
    Tooltip,
    Loader,
    CustomCheckbox,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },
  props: {
    title: {
      type: String
    },
    permission: {

    },
    isSending: {
      type: Boolean
    },
    disableButtonSubmit: {
      type: Boolean,
    },
    loadFlag: {
      type: Boolean
    },
    disabledCheckbox: {
      type: Boolean,
    },
  },
  data() {
    return {
      itemTitle: '',
      chosenRole: null,
      permissionRoleUpdate: null,
      roles: ROLES_ARR,
    }
  },
  validations() {
    return {
      chosenRole: { isRoleChosen },
    }
  },
  watch: {
    permissionRole() {
      this.permissionRoleUpdate = this.$store.state.profile.roleInfo
    }
  },
  methods: {
    hasPermission,
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item)
    },
    submit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss">

.permissions-accordeon-list {
  width: 100%;
  --vsa-max-width: 100%;
}

.permissions-accordeon .custom-checkbox-group {
  margin-bottom: 10px;
}

.permissions-accordeon {
  display: flex;
  flex-direction: column;
  width: calc(100% - 430px);
  padding: 30px 0 40px 35px;
  min-height: 100%;
  position: relative;
}

.permissions-accordeon .loader-p {
  top: 200px;
}

.permissions-accordeon .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.permissions-accordeon h3 {
  font-weight: 700;
  margin-left: 30px;
  font-size: 14px;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
  max-width: fit-content;
}

.permissions-accordeon h3 span {
  color: var(--admin-color);
}

// accordeon style
.vsa-item__trigger {
  background: #F8FCFC;
  border-radius: 5px;
  padding: 16px 30px;
  text-transform: uppercase;
}

.vsa-item__content {
  margin-left: 30px;
}

.vsa-item__trigger__content {
  font-weight: 400;
  font-size: 10px;
  color: var(--secondary-black-color);
}

.vsa-item__trigger__icon--is-default {
  width: 9px;
  height: 30px;
}

.vsa-item__trigger:focus, .vsa-item__trigger:hover {
  background-color: #F8FCFC;
  color: var(--secondary-black-color);
}

.vsa-item__trigger__icon--is-default:after, .vsa-item__trigger__icon--is-default:before {
  background-color: var(--light-gray);
}

.vsa-item__trigger:focus .vsa-item__trigger__icon--is-default:after, .vsa-item__trigger:focus .vsa-item__trigger__icon--is-default:before, .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default:after, .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default:before {
  background-color: var(--light-gray);
}

.vsa-item__trigger__icon--is-default:after, .vsa-item__trigger__icon--is-default:before {
  top: 0;
  width: 15px;
}

.vsa-item__trigger[aria-expanded=true] .vsa-item__trigger__icon--is-default:after {
  transform: rotate(-45deg) translate3d(-7px, 21px, 0);
}

.vsa-item__trigger__icon--is-default:after, .vsa-item__trigger__icon--is-default:before {
  height: 2px;
}

.vsa-item--is-active .vsa-item__heading, .vsa-item:not(:last-of-type) {
  border: none;
}

.vsa-list {
  --vsa-border-width: 0;
}

// end accordeon style
.holder-confirm {
  margin-top: 36px;
  margin-left: 30px;
}

.div-group {
  margin-bottom: 15px;
}

.permissions-checkbox-wrap {
  margin-bottom: 15px;
}

.permissions-accordeon .label-checkbox span {
  position: absolute;
  left: 40px;
  width: 600px;
}

.permissions-accordeon .group {
  position: relative;
  margin-bottom: 15px;
}

</style>