export default {
    namespaced: true,
    state: {
        isSidebarCollapsed: false,
        currentCase: null,
        updateCasesList: +new Date(),
        casesTabView: 'all',
    },
    getters: {
        getSidebarState: ({isSidebarCollapsed}) => isSidebarCollapsed,
        getCurrentCase: ({currentCase}) => currentCase,
        getUpdateCasesList: ({updateCasesList}) => updateCasesList,
        getCasesTabView: ({casesTabView}) => casesTabView,
    },
    actions: {
        setSidebarState: ({commit}, payload) => {
            commit('SET_SIDEBAR_STATE', payload)
        },
        setCurrentCase: ({commit}, payload) => {
            commit('SET_CURRENT_CASE', payload)
        },
        setUpdateCasesList: ({commit}) => {
            commit('SET_UPDATE_CASES_LIST')
        },
        setCasesTabView: ({commit}, payload) => {
            commit('SET_CASE_TAB_VIEW', payload)
        }
    },
    mutations: {
        SET_SIDEBAR_STATE: (state, payload) => {
            state.isSidebarCollapsed = payload
        },
        SET_CURRENT_CASE: (state, payload) => {
            state.currentCase = payload
        },
        SET_UPDATE_CASES_LIST: (state) => {
            state.updateCasesList += 1;
        },
        SET_CASE_TAB_VIEW: (state, payload) => {
            state.casesTabView = payload;
        }
    }
}