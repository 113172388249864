<template>
<div class="wrapper-invoice">
  <div class="invoice-header">
    <div class="container">
      <div class="box">
        <div class="holder-logo">
          <LogoIcon />
        </div>
        <div class="header-title">
          <h4>Invoice</h4>
          <p>Invoice Number:</p>
          <span>12345678</span>
          <p>Date:</p>
          <span>15/08/2021</span>
        </div>
      </div>
    </div>
  </div>
  <div class="invoice-content">
    <div class="container">
      <div class="box">
        <div class="bill-content">
          <p>Bill To:</p>
          <span>Timmy Turner</span>
          <span>957 North Street</span>
          <span>New York, NYC 345690</span>
          <span>United States</span>
        </div>
        <div class="bill-content">
          <p>Bill From:</p>
          <span>PME</span>
          <span>831 Manor Dr.</span>
          <span>Fountain Valley, CA 92708</span>
          <span>United States</span>
        </div>
      </div>
    </div>
    <TableHeader>
      <div class="container">
        <div class="box">
          <div class="table-content">
            <h5>Service</h5>
          </div>
          <div class="table-content">
            <h5>Numder of cases</h5>
            <h5>Cost</h5>
          </div>
        </div>
      </div>
    </TableHeader>
    <div class="table-body">
      <div class="container">
        <div class="box">
          <div class="table-content jc-s">
            <p>Active Cases</p>
            <p>Archived Cases</p>
            <p>Download Cases</p>
          </div>
          <div class="table-content">
            <div class="table-body-data">
              <span>33</span> <span>$99.00</span>
              <span>5</span> <span>$9.00</span>
              <span>4</span> <span>$9.00</span>
            </div>
            <div class="table-body-data">
              <span>Subtotal</span> <span>$108.00</span>
              <span>Discount</span> <span>-$8.00</span>
              <span>VAT</span> <span>$20.00</span>
              <span class="accent">Invoice Total</span> <span class="accent">$120.00</span>
              <span>Amount Due</span> <span>$0.00 USD</span>
            </div>
            <div class="table-body-data">
              <span class="bold">Paid</span> <span class="bold">-$120.00</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="bill-content">
          <p>Payments:</p>
          <span>$120.00 payment from Visa ···· 1234</span>
          <p>Notes:</p>
          <span>Add a note...</span>
        </div>
      </div>
    </div>

  </div>
  <div class="invoice-footer">
    <div class="container">
      <div class="footer-info">
        <p>Thanks for being a PME customer</p>
        <p>Need help? <a href="mailto:help@pme.com">help@pme.com</a></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import TableHeader from "../components/table/TableHeader";
import LogoIcon from "@/components/icons/LogoIcon.vue";
export default {
  name: "PrintInvoice",
  components: {LogoIcon, TableHeader}
}
</script>

<style scoped>

.container {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.wrapper-invoice {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper-invoice p {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-black-color);
  margin-bottom: 6px;
}

.wrapper-invoice span {
  display: block;
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.holder-logo, .header-title {
  width: 50%;
}

.invoice-header {
  flex: 0 0 auto;
  background: rgba(28, 202, 205, 0.05);
  padding: 35px 0;
}

.invoice-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 35px 0;
}

.bill-content, .table-content {
  width: 50%;
}

.table-content {
  display: flex;
  justify-content: space-between;
}

.table-content h5 {
  color: var(--secondary-black-color);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.box {
  display: flex;
}

.table-body .table-content {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.table-body-data {
  border-bottom: 2px solid var(--secondary-black-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 19px;
}

.jc-s {
  justify-content: flex-start;
}

.table-body p {
  margin-bottom: 12px;
}

.table-body-data span {
  display: inline-block;
  width: 50%;
  color: var(--secondary-black-color);
}

.table-body-data span:nth-child(even) {
  text-align: end;
}

.accent {
  color: var(--accent-color) !important;
}

.table-body-data:last-child {
  border: none;
}

.bold {
  font-weight: 600 !important;
}

.invoice-footer {
  display: flex;
  flex: 0 0 auto;
}

.box:last-child {
  /*margin-bottom: 105px;*/
}

.invoice-footer {
  padding: 24px 0;
  background: rgba(28, 202, 205, 0.05);
}

.footer-info {
  display: flex;
  justify-content: space-between;
}

.footer-info a {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

</style>