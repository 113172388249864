let parseDate = {
    methods: {
        parseDate(data) {
            if (!data) {
                return '-'
            }
            let newData = new Date(data)
            let options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            return newData.toLocaleString("en-US", options)
        },
    }
}

export default parseDate
