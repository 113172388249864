<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <h2 class="title">Coming soon!</h2>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";

export default {
  name: "SoftwareSettings",
  components: {
    HeaderRegistered,
  },
  mounted() {
    document.title = 'SoftwareSettings'
  }
}
</script>

<style scoped>

</style>