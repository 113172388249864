<template>
  <div id="head-button" style="position:relative;display:inline-block;"
       v-click-outside="closeNotes">

    <a class="button-default show-notifications js-show-notifications" @click="toggleNotifications">
      <svg v-if="notifications.length" width="24" height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.7927 16.4944C20.2724 15.5981 19.4989 13.0622 19.4989 9.75C19.4989 7.76088 18.7087 5.85322 17.3022 4.4467C15.8957 3.04018 13.988 2.25 11.9989 2.25C10.0098 2.25 8.10214 3.04018 6.69561 4.4467C5.28909 5.85322 4.49891 7.76088 4.49891 9.75C4.49891 13.0631 3.72454 15.5981 3.20423 16.4944C3.07135 16.7222 3.00091 16.9811 3.00001 17.2449C2.9991 17.5086 3.06776 17.768 3.19907 17.9967C3.33037 18.2255 3.51968 18.4156 3.74789 18.5478C3.9761 18.6801 4.23515 18.7498 4.49891 18.75H8.32485C8.49789 19.5967 8.95806 20.3577 9.62754 20.9042C10.297 21.4507 11.1347 21.7492 11.9989 21.7492C12.8631 21.7492 13.7008 21.4507 14.3703 20.9042C15.0398 20.3577 15.4999 19.5967 15.673 18.75H19.4989C19.7626 18.7496 20.0215 18.6798 20.2496 18.5475C20.4777 18.4151 20.6669 18.225 20.7981 17.9963C20.9292 17.7676 20.9978 17.5083 20.9969 17.2446C20.9959 16.9809 20.9255 16.7222 20.7927 16.4944ZM11.9989 20.25C11.5337 20.2499 11.0801 20.1055 10.7003 19.8369C10.3205 19.5683 10.0333 19.1886 9.87829 18.75H14.1195C13.9645 19.1886 13.6773 19.5683 13.2975 19.8369C12.9178 20.1055 12.4641 20.2499 11.9989 20.25Z"
          fill="#082655"/>
      </svg>
      <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.6307 16.5881C20.1 15.675 19.3116 13.0997 19.3116 9.75C19.3116 7.8106 18.5412 5.95064 17.1698 4.57928C15.7985 3.20792 13.9385 2.4375 11.9991 2.4375C10.0597 2.4375 8.19975 3.20792 6.82839 4.57928C5.45703 5.95064 4.68661 7.8106 4.68661 9.75C4.68661 13.1006 3.89723 15.675 3.36661 16.5881C3.25018 16.7875 3.18841 17.014 3.18751 17.2449C3.18661 17.4758 3.24663 17.7028 3.3615 17.903C3.47637 18.1033 3.64203 18.2697 3.84176 18.3855C4.0415 18.5013 4.26824 18.5623 4.49911 18.5625H8.48161C8.61823 19.3964 9.0468 20.1545 9.69079 20.7016C10.3348 21.2486 11.1523 21.549 11.9972 21.549C12.8422 21.549 13.6597 21.2486 14.3037 20.7016C14.9477 20.1545 15.3762 19.3964 15.5129 18.5625H19.4991C19.7298 18.562 19.9563 18.5007 20.1558 18.3848C20.3553 18.2689 20.5207 18.1024 20.6353 17.9022C20.75 17.702 20.8098 17.4751 20.8088 17.2444C20.8079 17.0137 20.7461 16.7874 20.6297 16.5881H20.6307ZM11.9991 20.4375C11.4506 20.4373 10.9181 20.2521 10.4879 19.9119C10.0577 19.5716 9.75475 19.0962 9.62817 18.5625H14.37C14.2435 19.0962 13.9406 19.5716 13.5103 19.9119C13.0801 20.2521 12.5476 20.4373 11.9991 20.4375ZM19.6594 17.3438C19.644 17.3725 19.621 17.3964 19.5928 17.4129C19.5647 17.4295 19.5326 17.438 19.5 17.4375H4.49911C4.46651 17.438 4.43441 17.4295 4.4063 17.4129C4.3782 17.3964 4.35517 17.3725 4.33973 17.3438C4.32328 17.3152 4.31461 17.2829 4.31461 17.25C4.31461 17.2171 4.32328 17.1848 4.33973 17.1562C5.04942 15.9375 5.81161 13.0959 5.81161 9.75C5.81161 8.10897 6.4635 6.53516 7.62389 5.37478C8.78427 4.2144 10.3581 3.5625 11.9991 3.5625C13.6401 3.5625 15.2139 4.2144 16.3743 5.37478C17.5347 6.53516 18.1866 8.10897 18.1866 9.75C18.1866 13.095 18.9497 15.9328 19.6594 17.1562C19.6759 17.1848 19.6845 17.2171 19.6845 17.25C19.6845 17.2829 19.6759 17.3152 19.6594 17.3438Z"
          fill="#8692A6"/>
      </svg>
    </a>
    <div class="notifications js-notifications"
         :style="{ display: displayState, top: topState + 'px', opacity: opacityState }">
      <span class="notifications-main-title">Notifications</span>
      <ul class="notifications-list">
        <HeaderNotificationsList
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
          @changeStatus="changeStatus"
        />
        <li class="item no-data" v-if="classBell === ''">You don't have
          notifications
        </li>
      </ul>
      <!--        <div class="notifications-list-empty" v-else>-->
      <!--          <span class="item no-data" :style="{display: displayState}">You don't have notifications</span>-->
      <!--        </div>-->
      <div class="notifications-show-all-wrap">
        <router-link :to="{path: '/notifications/files'}" class="notifications-show-all">See All</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import settings from "../../mixins/scrollSettings";
import HeaderNotificationsList from "./HeaderNotificationsList";

export default {
  name: "HeaderNotificationsTemplate",
  components: {
    HeaderNotificationsList,
  },
  mixins: [settings],
  data() {
    return {
      opacityState: null,
      displayState: null,
      topState: null,
      notificationFlag: false,
      classBell: '',
    }
  },
  props: {
    notifications: {
      type: Array,
    },
  },
  watch: {
    'notifications'(newVal) {
      if (newVal.length > 0) {
        this.classBell = 'visible'
      } else {
        this.classBell = ''
      }
    }
  },
  methods: {
    closeNotes() {
      this.notificationFlag = false
      this.opacityState = '0'
      this.displayState = 'none'
      this.topState = '-999'
    },
    toggleNotifications() {
      this.notificationFlag = !this.notificationFlag
      if (this.notificationFlag) {
        this.opacityState = '1'
        this.displayState = 'block'
        this.topState = '30'
      } else {
        this.opacityState = '0'
        this.displayState = 'none'
        this.topState = '-999'
      }
    },
    changeStatus() {
      this.$emit('changedStatus')
    },
  },
}
</script>

<style scoped>
.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: transparent;
  transition: all .2s;
}

.nav-item svg path {
  transition: all .2s;
}
</style>
