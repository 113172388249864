<template>
  <div class="main-app">
    <Sidebar v-if="this.$route.meta && this.$route.meta.requiresAuth"/>
    <router-view :key="routerKey" style="width:100%">
    </router-view>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: 'App',
  components: {
    Sidebar,
  },
  computed: {
    routerKey() {
      let isNoForceReload = false;
      if (this.$route.meta.noForceReload) {
        isNoForceReload = true;
      }
      if (this.$route.params.forceReload) {
        isNoForceReload = false;
      }
      return isNoForceReload ? '' : this.$route.fullPath;
    }
  }
}
</script>

<style>

body {
  font-family: 'Inter', sans-serif;
  overflow-x: auto;
}

.main-app {
  display: flex;
}


</style>
