<template>
  <div class="holder-table">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="search-panel">
      <CustomSelect
        v-model="selectedCaseId"
        placeholder="Case name"
        :options="casesList"
        @input="getDataByFilter"
        :type-select="'filterUser'"
        :customClass="'custom-select-sessions'"
        ref="caseSelect"
      />
      <button
        v-if="isAnyFilterApplied"
        class="reset-filters"
        @click="resetFilters"
      >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
            fill="#FF9537"/>
        </svg>
        Reset Filters
      </button>
    </div>
    <TableHeader>

      <div class="case">
        <div :id="`sort_sessions_by_case_name`" class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
              fill="#D7DCE6" />
          </svg>
          <span class="header-table-text">Case</span>
        </div>
      </div>

      <div class="device">
        <div :id="`sort_sessions_by_device`" class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Device</span>
        </div>
      </div>

      <div class="date">
        <div
          :id="`sort_sessions_by_date`"
          class="table-header-box"
          @click="sortedBy('started')"
          :class="{
            'sort-active': this.sortBy === 'email',
            'sorted-desc': this.order === DESC,
          }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z"
                fill="#D7DCE6" />
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Collection date</span>
          <arrowDown class="arrow" />
        </div>
      </div>
    </TableHeader>

    <div v-if="sessions.length > 0" class="table-body scrollable-container">
      <ClientSessionItem
        v-for="(session, index) in sessions"
        :key="index"
        :session="session"
      />
    </div>

    <div class="no-data" v-else>
      <h3>No sessions found</h3>
    </div>
  </div>
</template>

<script>
import TableHeader from "@/components/table/TableHeader";
import ClientSessionItem from "@/pages/client-profile/ClientSessionItem.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {ASC, DESC } from "@/constants/const";
import ArrowDown from "@/components/icons/ArrowDown.vue";

export default {
  name: "ClientSessionsList",
  components: {
    ArrowDown,
    CustomSelect,
    AlertErrorMsg,
    ClientSessionItem,
    TableHeader,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    casesList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      DESC,
      ASC,
      sortBy: "caseName",
      order: ASC,

      errorMessage: '',
      sessions: [],
      selectedCaseId: null,
    }
  },
  computed: {
    isAnyFilterApplied() {
      return this.selectedCaseId;
    },
  },
  methods: {
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === DESC ? ASC : DESC
      this.sortBy = filterName
      this.getSessions()
    },
    getDataByFilter() {
      // this.page = 1;
      this.getSessions();
    },
    getSessions() {
      this.$load(async () => {
        const res = await this.$api.screenshots.getClientSessions({
          caseId: this.selectedCaseId || '',
          clientId: this.clientId,
          sortOrder: this.order,
        });
        this.sessions = res.data.body;
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      });
    },
    resetFilters() {
      this.sortBy = '';
      this.order = '';
      this.selectedCaseId = null;
      this.$refs.caseSelect.selected = null;
      // this.debounce(() => this.page = 1, 500);
      this.getSessions();
    },
  },
  mounted() {
    this.getSessions();
  }
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;
}

.table-body {
  min-height: fit-content;
  max-height: 500px;
  overflow-y: auto;
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 15px;
}

.table-header-box {
  cursor: default;
}

.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box .header-table-text {
  margin: 0 5px 0 0;
  width: fit-content;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.case {
  width: 32%;
}

.device {
  width: 32%;
}

.date {
  width: 32%;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: var(--light-gray);
}

.search-panel {
  display: flex;
  gap: 15px;
  height: fit-content;
  align-items: center;
  margin: 0 0 15px;

  .reset-filters {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    margin-right: 23px;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-black-color);

    &:disabled {
      cursor: not-allowed;

      path {
        fill: var(--case-btn-border-color);
      }
    }

    svg {
      margin-right: 10px;
    }

    &:not(:disabled):hover {
      text-decoration: underline;
    }
  }
}
</style>
