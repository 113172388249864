<template>
  <div class="profile-content">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="holder-permissions-indiv">
      <div class="holder-search">
        <CustomSearch
            id="`search_permission_individual`"
            :modelValue="keySearch"
            @update:modelValue="keySearch = $event"
            @filter="filter"
            placeholder="Search by name"
        />
      </div>
      <div class="holder-sort">
        <div class="select-box">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 9C10.525 9 12.5714 6.98555 12.5714 4.5C12.5714 2.01445 10.525 0 8 0C5.475 0 3.42857 2.01445 3.42857 4.5C3.42857 6.98555 5.475 9 8 9ZM11.2 10.125H10.6036C9.81071 10.4836 8.92857 10.6875 8 10.6875C7.07143 10.6875 6.19286 10.4836 5.39643 10.125H4.8C2.15 10.125 0 12.2414 0 14.85V16.3125C0 17.2441 0.767857 18 1.71429 18H14.2857C15.2321 18 16 17.2441 16 16.3125V14.85C16 12.2414 13.85 10.125 11.2 10.125Z"
                fill="#D7DCE6"/>
          </svg>
          <span>User Name</span>
          <div class="sort">
            <arrowDown class="arrow" />
            <CustomMultiSelect
                ref="multiselectSort"
                :id="`sort_permission_individual`"
                :isOpen="isOpenSort"
                :class="customClass"
                :options="mockSort"
                :multiple="multipleSort"
                v-model="valueSort"
                :maxHeight="maxHeightSelect"
                @closeMultiSelect="closeMultiSelectSort"
                @clearAll="clearAllSort"
            />
          </div>
        </div>
        <div @click.stop="toggleSelect" class="select-box">
          <!--          <div>-->
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7615 9.39355C11.304 9.77342 10.5728 10.0535 10.0002 10.0535C9.42765 10.0535 8.69622 9.77394 8.23876 9.39408C7.4707 9.85154 7.02487 10.6668 6.96289 11.7779H13.0375C12.9756 10.6668 12.5297 9.85119 11.7615 9.39355Z"
                fill="#D7DCE6"/>
            <path
                d="M10.0007 8.57311C10.5449 8.57554 11.0369 8.2495 11.247 7.74741C11.4569 7.24515 11.3434 6.66599 10.9593 6.28022C10.5753 5.89446 9.99649 5.77831 9.49336 5.98613C8.99041 6.19377 8.66211 6.68439 8.66211 7.22866C8.66124 7.96929 9.26003 8.57085 10.0007 8.57311Z"
                fill="#D7DCE6"/>
            <path
                d="M8.16011 8.82926C8.26445 8.77683 8.39084 8.79836 8.47192 8.88239C8.80386 9.22788 9.50421 9.51954 10.0011 9.51954C10.498 9.51954 11.198 9.22735 11.5299 8.88187C11.6112 8.79784 11.7375 8.77666 11.8417 8.82944C12.8792 9.3494 13.5009 10.4001 13.5723 11.7779H13.6898V3.6001H6.26758V11.7779H6.42956C6.50109 10.4001 7.12261 9.34923 8.16011 8.82926ZM10.0009 5.35114C10.761 5.34888 11.4474 5.80479 11.74 6.50635C12.0325 7.20774 11.8733 8.01642 11.3367 8.55461C10.8002 9.09298 9.99206 9.25461 9.28963 8.96433C8.5872 8.67406 8.12904 7.98899 8.12904 7.22892C8.12852 6.19402 8.96584 5.35392 10.0009 5.35114Z"
                fill="#D7DCE6"/>
            <path
                d="M7.70612 1.51084H12.2929C12.4374 1.51084 12.5596 1.42021 12.5596 1.31084C12.5596 1.20146 12.4374 1.11084 12.2929 1.11084H7.70612C7.56168 1.11084 7.43945 1.20146 7.43945 1.31084C7.43945 1.42021 7.56168 1.51084 7.70612 1.51084Z"
                fill="#D7DCE6"/>
            <path
                d="M15.5996 0H4.39957C4.05599 0 3.77734 0.278646 3.77734 0.622222V19.3778C3.77734 19.7214 4.05599 20 4.39957 20H15.5996C15.9431 20 16.2218 19.7214 16.2218 19.3778V0.622222C16.2218 0.278646 15.9431 0 15.5996 0ZM7.70616 0.577778H12.293C12.7339 0.577778 13.093 0.905729 13.093 1.31111C13.093 1.71649 12.7339 2.04444 12.293 2.04444H7.70616C7.26519 2.04444 6.90616 1.71649 6.90616 1.31111C6.90616 0.905729 7.26519 0.577778 7.70616 0.577778ZM5.7329 3.51111C5.7329 3.26562 5.93186 3.06667 6.17734 3.06667H13.7773C14.0228 3.06667 14.2218 3.26562 14.2218 3.51111V11.8667C14.2218 12.1122 14.0228 12.3111 13.7773 12.3111H6.17734C5.93186 12.3111 5.7329 12.1122 5.7329 11.8667V3.51111ZM6.1329 14.4C5.98568 14.4 5.86623 14.2806 5.86623 14.1333C5.86623 13.9861 5.98568 13.8667 6.1329 13.8667H13.8218C13.969 13.8667 14.0885 13.9861 14.0885 14.1333C14.0885 14.2806 13.969 14.4 13.8218 14.4H6.1329ZM13.4218 15.9556C13.4218 16.1028 13.3023 16.2222 13.1551 16.2222H6.79957C6.65234 16.2222 6.5329 16.1028 6.5329 15.9556C6.5329 15.8083 6.65234 15.6889 6.79957 15.6889H13.1551C13.3023 15.6889 13.4218 15.8083 13.4218 15.9556ZM14.4885 18H5.46623C5.31901 18 5.19957 17.8806 5.19957 17.7333C5.19957 17.5861 5.31901 17.4667 5.46623 17.4667H14.4885C14.6357 17.4667 14.7551 17.5861 14.7551 17.7333C14.7551 17.8806 14.6357 18 14.4885 18Z"
                fill="#D7DCE6"/>
          </svg>
          <span>Role</span>
          <div class="sort">
            <arrowDown class="arrow" />
          </div>
          <!--          </div>-->
          <CustomMultiSelect
              ref="multiselectparent"
              :id="`permission_case_individual`"
              :isOpen="isOpen"
              :class="customClass"
              :options="mockRole"
              :multiple="multipleRole"
              v-model="valueRoles"
              :maxHeight="maxHeightSelect"
              @closeMultiSelect="closeMultiSelect"
              @clearAll="clearAll"
          />
        </div>
      </div>
      <vue-custom-scrollbar class="scroll-area" :settings="settings">
        <PermissionsList :users="userInfo" :tabIndive="tabIndive" @setTabIndive='getTabIndive'/>
      </vue-custom-scrollbar>
    </div>
    <SetPermissions
        :isSending="isSending"
        v-if="tabIndive"
        :title="fullNameUser"
        :permission="permissions"
        @changeCheckbox="changeCheckbox"
        @submit="submit"
        :disableButtonSubmit="disableButtonSubmit"
        :loadFlag="loadFlagPermission"
        :disabledCheckbox="!hasPermission(['manage_individual_rights'])"
        @changeRole="confirmChangeUserRole($event)"
    />
    <div v-else class="permissions-empty-wrap">
      <div class="permissions-empty-content">
        <svg width="114" height="158" viewBox="0 0 114 158" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M74.9169 56.1811C74.9169 46.286 66.8872 38.2676 56.9965 38.2676C47.0991 38.2676 39.0762 46.286 39.0762 56.1811C39.0762 62.9001 42.7775 68.7453 48.2492 71.8128L48.6204 59.9255C48.6204 55.0628 52.5663 51.117 57.4357 51.117H57.936C61.7709 51.117 64.8792 54.2252 64.8792 58.0601V72.2565C70.8126 69.3384 74.9169 63.242 74.9169 56.1811Z" fill="#082655"/>
          <path d="M64.8813 58.0579C64.8813 54.223 61.7731 51.1147 57.9382 51.1147H57.4379C52.5684 51.1147 48.6226 55.0606 48.6226 59.9232L48.2513 71.8105L47.425 98.0006C47.2371 98.8088 47.1307 117.019 47.1307 117.019C45.5913 115.45 43.8776 113.655 42.3382 111.937C38.7433 107.927 32.1397 105.224 27.7502 104.726C25.4389 104.462 23.6731 104.778 22.4959 105.661C22.1201 105.949 21.7511 106.268 21.407 106.614C19.1409 108.847 17.4317 112.451 19.0141 115.09C19.6978 116.245 20.8727 117.01 22.1224 117.814C23.7772 118.887 26.564 120.048 28.658 122.721C32.1669 127.211 40.2895 137.244 45.2812 142.598C46.3203 143.243 48.5184 145.437 49.5553 145.991C52.7065 148.004 55.2804 149.899 56.1724 150.924C56.8696 151.723 60.9943 153.835 60.9943 153.835C64.3289 155.947 70.3982 157.922 77.4092 157.922C84.4067 157.922 90.7635 158.34 95.4609 154.209H95.7122L95.8367 153.896C100.616 149.518 103.588 143.453 103.588 136.746C103.588 135.086 103.588 128.97 103.588 127.82V104.686C103.588 103.115 103.242 97.8467 98.8136 96.2643C96.6856 95.5081 94.6731 95.7934 92.9571 96.5337L92.5564 96.5518L92.4319 96.5608C91.6622 93.6677 89.738 91.8 86.8923 91.3042C83.4559 90.7089 81.0743 91.7366 79.6413 92.7757C78.2174 89.715 75.3627 88.119 71.4984 88.445C68.6007 88.6895 66.4456 90.1135 64.8813 91.9879V72.2542V58.0579Z" fill="#FFBE9D"/>
          <path d="M96.9819 85.9321L100.538 89.8892C101.222 90.6589 102.463 90.6114 103.065 89.7783C109.922 80.2635 113.7 68.8108 113.7 56.7651C113.7 25.495 88.2617 0.0610352 56.9961 0.0610352C54.85 0.0610352 52.6745 0.181017 50.5239 0.420981C24.4312 3.32772 3.44786 24.388 0.63167 50.5011C-0.903196 64.7111 2.857 78.5724 10.9252 89.776C11.5229 90.6136 12.7635 90.6567 13.4562 89.8915L17.0081 85.9366C17.5129 85.3774 17.5514 84.5466 17.1236 83.9263C11.4301 75.6091 8.44183 65.6732 8.74065 55.3434C9.46281 30.2694 29.6493 9.71168 54.6938 8.54355C82.3848 7.18073 105.272 29.3774 105.272 56.7606C105.272 66.5583 102.284 75.9803 96.8597 83.9173C96.4363 84.5466 96.4725 85.3729 96.9819 85.9321Z" fill="#082655" fill-opacity="0.1"/>
          <path d="M81.044 68.1926L84.8088 72.3806C85.5785 73.2431 86.9775 73.0462 87.4936 72.0184C89.8707 67.321 91.1225 62.1527 91.1225 56.7671C91.1225 40.703 79.7174 26.6606 63.9953 23.369C59.4088 22.3978 54.5914 22.3978 49.9913 23.369C34.2781 26.6606 22.873 40.703 22.873 56.7671C22.873 62.1572 24.1249 67.321 26.4974 72.0184C27.0226 73.0484 28.4126 73.2454 29.1868 72.3806L32.9493 68.1926C33.3726 67.7217 33.47 67.0561 33.2323 66.4743C31.9668 63.4001 31.3012 60.1039 31.3012 56.7648C31.3012 46.023 38.0927 36.3203 48.1961 32.6212C53.8398 30.5611 60.1581 30.5611 65.7972 32.6212C75.9029 36.318 82.6898 46.023 82.6898 56.7648C82.6898 60.1039 82.0243 63.4001 80.7656 66.4743C80.5279 67.0606 80.623 67.7262 81.044 68.1926Z" fill="#5692B7"/>
        </svg>
        <span class="permissions-empty-title">Choose User firstly to set permissions</span>
      </div>
    </div>
    <CommonDialog
        ref="commonDialogFormSuccess"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Permissions were changed</p>
    </CommonDialog>
    <ConfirmPopup
      ref="changeRolePopupConfirm"
      title="Change role"
      :description="'Are you sure you want to change role to ' + changeToRole + ' for:'"
      @confirm="changeUserRole()"
      button-confirm-text="Change"
    >
      <div v-if="tabIndive" class="holder-contacts">
        <div class="contacts-imgs">
          <div class="img-item">
            <img
              v-if="tabIndive.avatar"
              :src="tabIndive.avatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
              alt=""
            >
            <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt=""
            >
          </div>
        </div>
        <div class="contacts-count">
          <span v-if="tabIndive.firstName">
            {{
              (tabIndive.firstName.toLowerCase()[0].toUpperCase() + tabIndive.firstName.toLowerCase().slice(1))
              + ' ' + (tabIndive.lastName.toLowerCase()[0].toUpperCase() + tabIndive.lastName.toLowerCase().slice(1))
            }}
          </span>
          <span v-else-if="tabIndive.email">{{tabIndive.email}}</span>
        </div>
      </div>
      <span class="footer-text">After that, all additional permissions given earlier will be lost.</span>
    </ConfirmPopup>
    <CommonDialog
      ref="roleChangeSuccessDialog"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The role was changed</p>
    </CommonDialog>
  </div>
</template>

<script>
import hasPermission from "../../utils/permission";
import CommonDialog from "../popups/CommonDialog";
import AlertErrorMsg from "./AlertErrorMsg";
import SetPermissions from "./SetPermissions";
import CustomSearch from "../inputs/CustomSearch";
import PermissionsList from "./PermissionsList";
import {mapActions, mapMutations} from "vuex";
import CustomMultiSelect from "../inputs/CustomMultiSelect";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import settings from "../../mixins/scrollSettings";
import ArrowDown from "../icons/ArrowDown";
import ConfirmPopup from "@/components/popups/ConfirmPopup.vue";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "PermissionsIndividual",
  components: {
    ConfirmPopup,
    ArrowDown,
    CustomMultiSelect,
    CommonDialog,
    AlertErrorMsg,
    PermissionsList,
    CustomSearch,
    SetPermissions,
    vueCustomScrollbar,
  },
  props: {
    flagResetInfo: {}
  },
  mixins: [settings, getRoleName],
  data() {
    return {
      isSending: false,
      tabRole: "",
      changeToRole: null,
      isOpen: false,
      isOpenSort: false,
      customClass: "role-multi",
      multipleRole: true,
      multipleSort: false,
      mockRole: [{caseName: "Reviewer"}, {caseName: "Review admin"}, {caseName: "Case administrator"}, {caseName: "Billing contact"}, {caseName: "System administrator"}],
      mockSort: [{caseName: "User name", paramsCaseName: "userName"}, {caseName: "Role", paramsCaseName: "role"}],
      maxHeightSelect: 400,
      valueRoles: "",
      valueSort: "",
      tabIndive: "",
      disableButtonSubmit: true,
      userInfo: [],
      permissionUserChangedArray: [],
      permissionUserFilterArray: {},
      keySearch: "",
      sortBy: "",
      order: "asc",
      roles: "",
      errorMessage: "",
      loadFlagPermission: false,
    }
  },
  computed: {
    fullNameUser() {
      return (this.tabIndive.firstName.toLowerCase()[0].toUpperCase() + this.tabIndive.firstName.toLowerCase().slice(1)) + ' ' + (this.tabIndive.lastName.toLowerCase()[0].toUpperCase() + this.tabIndive.lastName.toLowerCase().slice(1))
    },
    permissions() {
      return this.$store.state.profile.userInfo
    }
  },
  watch: {
    valueRoles(val) {
      this.roles = val.map(item => this.getRoleNameBE(item.caseName))
      this.handlerPermissionsUser()
    },
    valueSort(val) {
      if (val === null) {
        this.sortBy = ''
      } else {
        this.sortBy = val.paramsCaseName
      }
      this.handlerPermissionsUser()
    },
    flagResetInfo() {
      this.handlerPermissionsUser()
    }
  },
  methods: {
    hasPermission,
    ...mapActions({
      getPermissionUser: 'profile/getPermissionUser',
    }),
    ...mapMutations({
      setUserData: 'profile/SET_USER_DATA',
    }),
    openDialogFormSuccess() {
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    fullName(firstName, lastName) {
      return (firstName.toLowerCase()[0].toUpperCase() + firstName.toLowerCase().slice(1)) + ' ' + (lastName.toLowerCase()[0].toUpperCase() + lastName.toLowerCase().slice(1))
    },
    getTabIndive(data) {
      this.tabIndive = data;
      this.$emit('handlerResetSettingData', data)
      this.getRole(this.tabIndive)
    },
    unDisableButtonSubmit() {
      this.disableButtonSubmit = false
    },
    compare(a1, a2) {
      return a1.length == a2.length && a1.every((v, i) => v === a2[i])
    },
    filter() {
      this.handlerPermissionsUser()
    },
    clearAll() {
      this.roles = ""
      this.handlerPermissionsUser()
    },
    clearAllSort() {
      this.sortBy = ""
      this.handlerPermissionsUser()
    },
    getRole(item) {
      if (item) {
        this.tabIndive = item
        this.disableButtonSubmit = true
      }
      this.loadFlagPermission = true
      this.getPermissionUser(item.publicId).then(res => {
        this.permissionRole = res.data.body
        let result = this.permissionRole.reduce(function (h, obj) {
          h[obj.group] = (h[obj.group] || []).concat(obj);
          return h;
        }, {})
        this.permissionUserFilterArray = JSON.parse(JSON.stringify(result))
        this.permissionUserChangedArray = Object.values(result).flat()
        this.setUserData(result)
      })
      setTimeout(() => {
        this.loadFlagPermission = false
      }, 1000)
    },
    changeCheckbox(item) {
      this.permissionUserChangedArray.forEach((el, index) => {
        if (item.id === el.id) {
          this.permissionUserChangedArray[index] = item
        }
      })
      let oldArray = Object.values(this.permissionUserFilterArray).flat().map(a => a.isActive);
      let newArray = this.permissionUserChangedArray.map(a => a.isActive);
      if (this.compare(oldArray, newArray)) {
        this.disableButtonSubmit = true
      } else {
        this.unDisableButtonSubmit();
      }
    },
    submit() {
      this.isSending = true
      this.$load(async () => {
        this.loadFlagPermission = true
        await this.$api.permissions.updatePermissionsUser(this.tabIndive.publicId, this.permissionUserChangedArray).then(() => {
          this.openDialogFormSuccess()
          this.getRole(this.tabIndive)
          this.disableButtonSubmit = true
          this.isSending = false
        }, (error) => {
          this.isSending = false
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          // this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlagPermission = false
        }, 1000)
      })
    },
    toggleSelect() {
      this.isOpen = !this.isOpen
    },
    closeMultiSelect() {
      this.isOpen = false
    },
    closeMultiSelectSort() {
      this.isOpen = false
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    confirmChangeUserRole(role) {
      if (role) {
        this.changeToRole = this.getRoleNameFE(role);
        this.$refs.changeRolePopupConfirm.openPopups();
      }
    },
    changeUserRole() {
      this.$load(async () => {
        await this.$api.permissions.changeUserRole({
          role: this.getRoleNameBE(this.changeToRole),
          userId: this.tabIndive.publicId,
        }).then(() => {
          this.changeToRole = null;
          this.handlerPermissionsUser();
          this.$refs.changeRolePopupConfirm.closePopup();
          this.$refs.roleChangeSuccessDialog.openPopups();
        })
      })
    },
    handlerPermissionsUser() {
      this.$load(async () => {
        this.loadFlagPermission = true
        await this.$api.permissions.getPermissionsUserInfo({
          keySearch: this.fixedEncodeURIComponent(this.keySearch),
          sortBy: this.sortBy,
          order: this.order,
          roles: this.roles,
        }).then((res) => {
          this.userInfo = res.data.body.users
          this.userInfo.map((item) => {
            if(item.publicId === this.$route.params.user_id) {
              this.getRole(item)
            }
          })
          this.$emit('handlerResetSettingData', this.tabIndive)
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlagPermission = false
        }, 1000)
      })
    }
  },
  mounted() {
    this.handlerPermissionsUser()
  },
}
</script>

<style scoped>

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.holder-search {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 40px;
  width: 100%;
}

.profile-content {
  display: flex;
}

.holder-permissions-indiv {
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  width: 430px;
}

.holder-sort {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-bottom: 20px;
}

.select-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.select-box:first-child {
  width: 60%;
  position: relative;
}

.select-box:last-child {
  width: 40%;
  justify-content: flex-end;
  margin-right: 20px;
}

.select-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.select-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.select-box svg {
  margin-right: 10px;
}

.select-box span {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
}

.sort {
  display: flex;
  margin-left: 10px;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.holder-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.contacts-imgs {
  display: flex;
}

.img-item {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  margin-left: -13px;
}

.img-item img {
  width: 100%;
  height: 100%;
}

.contacts-count {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contacts-count span {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-text-color);
  padding: 0;
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.contacts-count span:last-child {
  font-weight: 400;
  font-size: 16px;
  color: var(--light-gray);
}

.footer-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  margin-top: 10px;
}

</style>
