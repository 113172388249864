<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <CaseDetailsHeader
        custom-class="case-actions"
        :route-path="{name: 'case-details'}"
        :prefix-text="caseName"
        :withoutCaseTitle="true"
      />
      <main class="main">
        <section class="case-actions-section">
          <CaseActionsTabs/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import CaseDetailsHeader from "../../layout/CaseDetailsHeader";
import CaseActionsTabs from "./CaseActionsTabs";

export default {
  name: "CaseActions",
  components: {
    CaseActionsTabs,
    CaseDetailsHeader,
  },
  data() {
    return {
      caseName: this.$route.name[0].toUpperCase() + this.$route.name.slice(1)
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.caseName = this.$route.name[0].toUpperCase() + this.$route.name.slice(1)
      }
    },
  },
}
</script>

<style scoped>
.case-actions-section {
  padding: 27px 55px 27px 35px;
  min-height: 100vh;
}
</style>