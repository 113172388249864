<template>
  <tr>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="email">{{ item.userEmail }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="case">{{ item.caseName.replaceAll('\"', '') }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="device">{{ item.device }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="deviceId">{{ item.deviceId || '-' }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="date">{{ parseDate(item.dateOfCollection) }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="time">{{ item.timeOfCollection }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span
            ref="sources"
            class="sources"
            @mouseenter="handleMouseEnter"
            @mouseleave="showTooltip = false"
          >
            {{ displayedSources }}
          </span>
          <transition name="fade">
            <span v-if="showTooltip" class="tooltip">
              {{ displayedSources }}
            </span>
          </transition>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import capitalizeEveryWord from "@/mixins/capitalizeEveryWord";
import parseDate from "@/mixins/parseDate";

export default {
  name: "NotificationsSourcesItem",
  props: {
    item: {},
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  mixins: [capitalizeEveryWord, parseDate],
  computed: {
    displayedSources() {
      return this.capitalizeEveryWord(
        this.item.dataSources.replaceAll(",", " ")
      ).replaceAll(" ", ", ");
    },
  },
  methods: {
    handleMouseEnter() {
      const element = this.$refs.sources;
      if (element && element.offsetWidth < element.scrollWidth) {
        this.showTooltip = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  max-width: 150px;

  .email {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #8692A6;
  }

  .case,
  .device,
  .deviceId,
  .date,
  .time,
  .sources {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #696F79;
  }

  .sources {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip {
    position: absolute;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    z-index: 100;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    transform: translate(-20%, -110%);
    transition: all .5s ease;
    white-space: nowrap;
    max-width: none;

    &:before {
      left: 70px;
    }
  }
}
</style>