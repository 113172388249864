<template>
  <div v-if="editingMessage" class="message-details-wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="message-details-header">
      <h2 class="message-details-title">Details</h2>
      <svg @click="closeDetails" width="28" height="28" viewBox="0 0 28 28" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.9987 2.3335C7.54703 2.3335 2.33203 7.5485 2.33203 14.0002C2.33203 20.4518 7.54703 25.6668 13.9987 25.6668C20.4504 25.6668 25.6654 20.4518 25.6654 14.0002C25.6654 7.5485 20.4504 2.3335 13.9987 2.3335ZM19.832 18.1885L18.187 19.8335L13.9987 15.6452L9.81036 19.8335L8.16537 18.1885L12.3537 14.0002L8.16537 9.81183L9.81036 8.16683L13.9987 12.3552L18.187 8.16683L19.832 9.81183L15.6437 14.0002L19.832 18.1885Z"
          fill="#D7DCE6"/>
      </svg>
    </div>
    <div class="menu-item-wrapper">
      <div class="menu-item-header">
        <h2 class="menu-item-title">Message</h2>
        <svg
          @click="isViewMessage = !isViewMessage"
          :style="{ transform: isViewMessage ? '' : 'rotate(180deg)' }"
          width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.8402 14.6143L12.3859 9.16001C12.3352 9.1093 12.2751 9.06907 12.2089 9.04162C12.1427 9.01417 12.0717 9.00004 12 9.00004C11.9283 9.00004 11.8573 9.01417 11.7911 9.04162C11.7249 9.06907 11.6648 9.1093 11.6141 9.16001L6.15984 14.6143C6.0575 14.7166 6 14.8554 6 15.0002C6 15.1449 6.0575 15.2837 6.15984 15.3861C6.26218 15.4884 6.40099 15.5459 6.54573 15.5459C6.69047 15.5459 6.82928 15.4884 6.93162 15.3861L12 10.317L17.0684 15.3861C17.1191 15.4367 17.1792 15.4769 17.2454 15.5044C17.3116 15.5318 17.3826 15.5459 17.4543 15.5459C17.5259 15.5459 17.5969 15.5318 17.6631 15.5044C17.7293 15.4769 17.7895 15.4367 17.8402 15.3861C17.8908 15.3354 17.931 15.2752 17.9585 15.209C17.9859 15.1428 18 15.0718 18 15.0002C18 14.9285 17.9859 14.8575 17.9585 14.7913C17.931 14.7251 17.8908 14.665 17.8402 14.6143Z"
            fill="#8692A6"/>
        </svg>
      </div>
      <transition v-if="isViewMessage" name="slide">
        <div class="message-text">
          <div class="message-wrapper">
            <div class="relative">
              <span v-if="editingMessage.isBlured && !unBlurredMessage" class="blurred"></span>
              <span :style="{background: highlightBackgroundColor}" class="background"></span>
              <span
                ref="menuIcon"
                :class="{ underline: highlightBackgroundColor, 'nocopy' : editingMessage.isBlured && !unBlurredMessage }"
                :data-color="highlightBackgroundColor"
                :style="{textDecorationColor: highlightBackgroundColor}"
              >{{ unBlurredMessage || editingMessage.message }}</span>
            </div>
            <ContextMenu
              :item="editingMessage"
              :options="messagesMenu"
              @onSelected="onMessageMenuSelection"
              v-click-outside="closeHighlight"
            />
            <div
              v-show="highlightColorsEnabled"
              class="tools colors"
              ref="contextMenu"
              :style="positionStyles"
            >
              <button
                v-for="(color, index) in colors"
                :key="index"
                :class="{white: color.preview === '#fff'}"
                :style="{backgroundColor: color.preview}"
                class="color"
                @click="setHighlightBackground(color.origin, editingMessage.id)"
              />
              <button class="close" @click="deleteHighlightBackground(editingMessage.id)">
                <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="#8692A6"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div v-if="editingMessage.isBlured && hasPermission(['view_origin_content'])" class="view-message"
               @click="showMessage()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.1853 11.6962C23.1525 11.6222 22.3584 9.86062 20.5931 8.09531C18.2409 5.74312 15.27 4.5 12 4.5C8.72999 4.5 5.75905 5.74312 3.40687 8.09531C1.64155 9.86062 0.843741 11.625 0.814679 11.6962C0.772035 11.7922 0.75 11.896 0.75 12.0009C0.75 12.1059 0.772035 12.2097 0.814679 12.3056C0.847491 12.3797 1.64155 14.1403 3.40687 15.9056C5.75905 18.2569 8.72999 19.5 12 19.5C15.27 19.5 18.2409 18.2569 20.5931 15.9056C22.3584 14.1403 23.1525 12.3797 23.1853 12.3056C23.2279 12.2097 23.25 12.1059 23.25 12.0009C23.25 11.896 23.2279 11.7922 23.1853 11.6962ZM12 18C9.11437 18 6.59343 16.9509 4.50655 14.8828C3.65028 14.0313 2.92179 13.0603 2.34374 12C2.92164 10.9396 3.65014 9.9686 4.50655 9.11719C6.59343 7.04906 9.11437 6 12 6C14.8856 6 17.4066 7.04906 19.4934 9.11719C20.3514 9.9684 21.0815 10.9394 21.6609 12C20.985 13.2619 18.0403 18 12 18ZM12 7.5C11.11 7.5 10.2399 7.76392 9.49993 8.25839C8.7599 8.75285 8.18313 9.45566 7.84253 10.2779C7.50194 11.1002 7.41282 12.005 7.58646 12.8779C7.76009 13.7508 8.18867 14.5526 8.81801 15.182C9.44735 15.8113 10.2492 16.2399 11.1221 16.4135C11.995 16.5872 12.8998 16.4981 13.7221 16.1575C14.5443 15.8169 15.2471 15.2401 15.7416 14.5001C16.2361 13.76 16.5 12.89 16.5 12C16.4988 10.8069 16.0242 9.66303 15.1806 8.81939C14.337 7.97575 13.1931 7.50124 12 7.5ZM12 15C11.4066 15 10.8266 14.8241 10.3333 14.4944C9.83993 14.1648 9.45542 13.6962 9.22835 13.1481C9.00129 12.5999 8.94188 11.9967 9.05764 11.4147C9.17339 10.8328 9.45911 10.2982 9.87867 9.87868C10.2982 9.45912 10.8328 9.1734 11.4147 9.05764C11.9967 8.94189 12.5999 9.0013 13.148 9.22836C13.6962 9.45542 14.1648 9.83994 14.4944 10.3333C14.824 10.8266 15 11.4067 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15Z"
                fill="#5692B7"/>
            </svg>
            <span>{{ unBlurredMessage ? 'Hide message' : 'View message' }}</span>
          </div>
          <div v-if="editingMessage.isPinned" class="pinned">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.7068 6.49922L11.365 9.85235C11.65 10.6442 11.7681 11.9705 10.5406 13.6024C10.4547 13.7171 10.3451 13.812 10.2192 13.8805C10.0934 13.9489 9.95418 13.9895 9.8112 13.9992C9.78745 13.9992 9.76433 13.9992 9.74058 13.9992C9.60917 13.9992 9.47905 13.9734 9.35766 13.9231C9.23626 13.8728 9.12596 13.799 9.03308 13.7061L6.01683 10.6867L3.35245 13.353C3.25863 13.4468 3.13138 13.4995 2.9987 13.4995C2.86602 13.4995 2.73877 13.4468 2.64495 13.353C2.55113 13.2592 2.49842 13.1319 2.49842 12.9992C2.49842 12.8665 2.55113 12.7393 2.64495 12.6455L5.3112 9.9811L2.29245 6.96235C2.19323 6.863 2.11604 6.74387 2.06591 6.61271C2.01577 6.48155 1.99383 6.3413 2.00149 6.2011C2.00916 6.06089 2.04626 5.92387 2.11039 5.79896C2.17452 5.67405 2.26424 5.56404 2.3737 5.4761C3.96245 4.19422 5.48308 4.4461 6.1487 4.65485L9.4987 1.29297C9.59156 1.20009 9.70181 1.12641 9.82315 1.07614C9.94449 1.02587 10.0745 1 10.2059 1C10.3372 1 10.4673 1.02587 10.5886 1.07614C10.71 1.12641 10.8202 1.20009 10.9131 1.29297L14.7062 5.08547C14.8937 5.27291 14.9991 5.52712 14.9992 5.79222C14.9993 6.05732 14.8941 6.31162 14.7068 6.49922Z"
                fill="#5692B7"/>
            </svg>
            <span>Prioritized</span>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="hasPermission(['add_tag'])" class="menu-item-wrapper">
      <div class="menu-item-header">
        <div class="header-text">
          <h2 class="menu-item-title">Tags</h2>
          <span @click="$refs.editTagsPopup.openPopups()">Edit</span>
        </div>
        <svg
          @click="isViewTags = !isViewTags"
          :style="{ transform: isViewTags ? '' : 'rotate(180deg)' }"
          width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.8402 14.6143L12.3859 9.16001C12.3352 9.1093 12.2751 9.06907 12.2089 9.04162C12.1427 9.01417 12.0717 9.00004 12 9.00004C11.9283 9.00004 11.8573 9.01417 11.7911 9.04162C11.7249 9.06907 11.6648 9.1093 11.6141 9.16001L6.15984 14.6143C6.0575 14.7166 6 14.8554 6 15.0002C6 15.1449 6.0575 15.2837 6.15984 15.3861C6.26218 15.4884 6.40099 15.5459 6.54573 15.5459C6.69047 15.5459 6.82928 15.4884 6.93162 15.3861L12 10.317L17.0684 15.3861C17.1191 15.4367 17.1792 15.4769 17.2454 15.5044C17.3116 15.5318 17.3826 15.5459 17.4543 15.5459C17.5259 15.5459 17.5969 15.5318 17.6631 15.5044C17.7293 15.4769 17.7895 15.4367 17.8402 15.3861C17.8908 15.3354 17.931 15.2752 17.9585 15.209C17.9859 15.1428 18 15.0718 18 15.0002C18 14.9285 17.9859 14.8575 17.9585 14.7913C17.931 14.7251 17.8908 14.665 17.8402 14.6143Z"
            fill="#8692A6"/>
        </svg>
      </div>
      <transition v-if="isViewTags" name="slide">
        <div class="tags-wrapper" :class="{ 'tags-disabled': isLoadingState }">
          <div v-for="(category, index) in messageTags" class="tag-section" :key="index">
            <h2 class="section-header">{{ category.category.name }}</h2>
            <div class="section-tags">
              <div v-for="(tag, index) in category.tags" class="tag" :key="index">
                <div class="check">
                  <CustomCheckbox
                    :id="'tag_' + tag.id"
                    :input-element="tag"
                    @checked="changeTagState(tag)"
                    customClass="no-pos"
                  />
                </div>
                <span>{{ tag.tag }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="menu-item-wrapper">
      <div class="menu-item-header">
        <h2 class="menu-item-title">Comments</h2>
        <svg
          @click="isViewComments = !isViewComments"
          :style="{ transform: isViewComments ? '' : 'rotate(180deg)' }"
          width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.8402 14.6143L12.3859 9.16001C12.3352 9.1093 12.2751 9.06907 12.2089 9.04162C12.1427 9.01417 12.0717 9.00004 12 9.00004C11.9283 9.00004 11.8573 9.01417 11.7911 9.04162C11.7249 9.06907 11.6648 9.1093 11.6141 9.16001L6.15984 14.6143C6.0575 14.7166 6 14.8554 6 15.0002C6 15.1449 6.0575 15.2837 6.15984 15.3861C6.26218 15.4884 6.40099 15.5459 6.54573 15.5459C6.69047 15.5459 6.82928 15.4884 6.93162 15.3861L12 10.317L17.0684 15.3861C17.1191 15.4367 17.1792 15.4769 17.2454 15.5044C17.3116 15.5318 17.3826 15.5459 17.4543 15.5459C17.5259 15.5459 17.5969 15.5318 17.6631 15.5044C17.7293 15.4769 17.7895 15.4367 17.8402 15.3861C17.8908 15.3354 17.931 15.2752 17.9585 15.209C17.9859 15.1428 18 15.0718 18 15.0002C18 14.9285 17.9859 14.8575 17.9585 14.7913C17.931 14.7251 17.8908 14.665 17.8402 14.6143Z"
            fill="#8692A6"/>
        </svg>
      </div>
      <transition v-if="isViewComments" name="slide">
        <div class="comments-wrapper">
          <div class="comments-item-wrapper">
            <div v-for="(comment, index) in comments" class="comment-item" :key="index">
              <CommentMessage :comment="comment" @deleted="openDeleteCommentSuccessPopup"/>
            </div>
          </div>
          <textarea
            v-if="hasPermission(['add_comments_highlights'])"
            name="invite"
            class="comment-textarea"
            placeholder="Add comment"
            v-model="newCommentMessage"
            @keydown.enter.exact.prevent="addComment"
          ></textarea>
          <button
            v-if="hasPermission(['add_comments_highlights'])"
            id="button_create_case"
            class="btn btn-revert"
            @click="addComment"
          >Send
          </button>
        </div>
      </transition>
      <EditTagsPopup ref="editTagsPopup"/>
    </div>
    <ReminderPopup ref="reminderPopup"/>
    <CommonDialog ref="commentDeleteDialogMD">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The comment was deleted</p>
    </CommonDialog>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import parseDate from "@/mixins/parseDate";
import parseDateTime from "@/mixins/parseTime";
import {mapActions, mapGetters} from "vuex";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import ContextMenu from "@/components/popups/ContextMenu.vue";
import highlightImg from "@/assets/img/case-messages/highlight.png"
import redactIcon from "@/assets/img/case-messages/redacted.png"
import priorityIcon from "@/assets/img/case-messages/priority.png"
import reminderImg from "@/assets/img/case-messages/reminder.png"
import deleteIcon from "@/assets/img/case-messages/delete.svg"
import unredactIcon from "@/assets/img/case-messages/unredact.svg"
import editIcon from "@/assets/img/case-messages/edit.svg"
import EditTagsPopup from "@/components/popups/EditTagsPopup.vue";
import ReminderPopup from "@/components/popups/ReminderPopup.vue";
import dayjs from "dayjs";
import CommentMessage from "@/components/сases/case-messages/tags/CommentMessage.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import hasPermission from "@/utils/permission";

const timeOptions = [
  {id: 1, name: 'In 20 minutes'},
  {id: 2, name: 'In 1 hour'},
  {id: 3, name: 'In 3 hours'},
  {id: 4, name: 'Tomorrow'},
  {id: 5, name: 'Next week'},
  {id: 6, name: 'Custom...'}
];

const messageOptions = [
  {id: 1, name: 'Highlight', img: highlightImg},
  {id: 2, name: 'Redact text', img: redactIcon},
  {id: 3, name: 'Mark priority', img: priorityIcon},
  {id: 4, name: 'Remind me about this', img: reminderImg, children: timeOptions},
];

const commentOptions = [
  {name: 'Edit', img: editIcon},
  {name: 'Delete', img: deleteIcon},
];

export default {
  name: "MessageDetails",
  components: {
    CommonDialog,
    CommentMessage,
    ReminderPopup,
    EditTagsPopup,
    ContextMenu,
    AlertErrorMsg,
    CustomCheckbox,
  },
  emits: ["closeDetails", "changeCheckbox"],
  data() {
    return {
      unredactIcon,
      highlightImg,
      redactedImg: redactIcon,
      priorityImg: priorityIcon,
      reminderImg,
      deleteIcon,
      timeOptions: timeOptions,
      commentOptions: commentOptions,
      messageOptions: messageOptions,
      newComment: '',
      errorMessage: '',
      newCommentMessage: '',
      infoDialogData: {},
      commentEdit: false,
      isViewMessage: true,
      isViewTags: true,
      isViewComments: true,
      highlightBackgroundColor: null,
      highlightColorsEnabled: false,
      colors: [
        {origin: 'rgba(255,236,67,0.4)', preview: 'rgba(255,236,67)'},
        {origin: 'rgba(155,126,222,0.4)', preview: 'rgba(155,126,222)'},
        {origin: 'rgba(4,83,202,0.4)', preview: 'rgba(4,83,202)'},
        {origin: 'rgba(255,149,55,0.4)', preview: 'rgba(255,149,55)'},
        {origin: 'rgba(241,16,95,0.4)', preview: 'rgba(241,16,95)'},
        {origin: 'rgba(60,193,59,0.4)', preview: 'rgba(60,193,59)'}
      ],
      positionStyles: {
        position: 'absolute',
        top: '0px',
        left: '0px',
      },
    }
  },
  watch: {
    editingMessage: {
      handler() {
        this.highlightBackgroundColor = this.editingMessage.highlight ? this.editingMessage.highlight : null;
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('caseMessages', {
      editingMessage: "getEditingMessage",
      comments: "getComments",
      messageTags: "getMessageTags",
      isLoadingState: 'getLoadingState',
      unBlurredMessage: 'getUnBlurredMessage',
    }),
    messagesMenu() {
      let options = this.messageOptions;

      if (this.editingMessage.isPinned) {
        options
          .filter(option => option.name === 'Mark priority')
          .map(option => {
            option.name = 'Remove from Priorities';
            option.img = deleteIcon
          });
      } else {
        options
          .filter(option => option.name === 'Remove from Priorities')
          .map(option => {
            option.name = 'Mark priority';
            option.img = priorityIcon
          });
      }


      if (this.editingMessage.isBlured) {
        options
          .filter(option => option.name === 'Redact text')
          .map(option => {
            option.name = 'Unredact text';
            option.img = unredactIcon
          });
      } else if (this.editingMessage.message.length > 0) {
        options
          .filter(option => option.name === 'Unredact text')
          .map(option => {
            option.name = 'Redact text';
            option.img = redactIcon
          });
      } else {
        options = options.filter(option => option.name !== 'Redact text' && option.name !== 'Unredact text');
      }
      return options;
    }
  },
  mixins: [parseDate, parseDateTime],
  methods: {
    hasPermission,
    ...mapActions('caseMessages', {
      addTagToMessage: "addTagToMessage",
      removeTagToMessage: "removeTagToMessage",
      setLoadingState: "setLoadingState",
      pinCurrentMessage: "pinMessage",
      unpinCurrentMessage: "unpinMessage",
      blurMessage: "blurMessage",
      unblurMessage: "unblurMessage",
      showBlurred: "showBlurred",
      clearUnBlurredMessage: "clearUnBlurredMessage",
      getMessageById: "getMessageById",
      setReminder: 'setReminder',
      addReminder: 'addReminder',
      createCommentForMessage: 'createCommentForMessage',
      createHighlightForMessage: 'createHighlightForMessage',
      deleteHighlightForMessage: 'deleteHighlightForMessage',
    }),
    positionMenu(triggerElement) {
      const contextMenu = this.$refs.contextMenu;
      let iconRect;

      if (triggerElement) {
        iconRect = triggerElement.getBoundingClientRect();
      } else {
        const menuIcon = this.$refs.menuIcon;
        iconRect = menuIcon.getBoundingClientRect();
      }

      const menuRect = contextMenu.getBoundingClientRect();
      let top, left;

      top = iconRect.bottom;
      left = iconRect.left;

      // If menu goes off bottom, position above
      if (top + menuRect.height > window.innerHeight) {
        top = iconRect.top - menuRect.height;
      }

      // If menu goes off right edge, adjust left
      if (left + menuRect.width > window.innerWidth) {
        left = window.innerWidth - menuRect.width;
      }

      top = Math.max(top, 0);
      left = Math.max(left, 0);

      this.positionStyles.top = `${top}px`;
      this.positionStyles.left = `${left}px`;
      this.positionStyles.position = 'fixed';
    },

    openHighlight(event) {
      this.highlightColorsEnabled = !this.highlightColorsEnabled;
      this.positionMenu(event.currentTarget);
    },
    closeHighlight() {
      this.highlightColorsEnabled = false;
    },
    setHighlightBackground(color) {
      this.$load(() => {
        this.createHighlightForMessage(color);
        this.highlightBackgroundColor = color;
        this.closeHighlight();
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      })
    },
    deleteHighlightBackground() {
      this.deleteHighlightForMessage()
      this.highlightBackgroundColor = null;
      this.closeHighlight();
    },

    addComment() {
      this.$load(() => {
        if (this.newCommentMessage) {
          this.createCommentForMessage(this.newCommentMessage);
          this.newCommentMessage = '';
        }
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert();
      })
    },
    openDeleteCommentSuccessPopup() {
      this.$refs.commentDeleteDialogMD.openPopups();
    },

    onMessageMenuSelection(optionName, event) {
      if (messageOptions.some(option => option.name === optionName)) {
        this.handleMessageMenuSelection(optionName, event);
      } else if (timeOptions.some(option => option.name === optionName)) {
        this.handleTimeOptionSelection(optionName)
      } else {
        this.errorMessage = 'No such option';
      }
    },
    handleMessageMenuSelection(optionName, event) {
      switch (optionName) {
        case 'Highlight':
          this.openHighlight(event);
          break;
        case 'Mark priority':
        case 'Remove from Priorities':
          this.pinMessage();
          break;
        case 'Redact text':
        case 'Unredact text':
          this.redactMessage();
          break;
        default:
          this.errorMessage = 'No such option';
      }
    },
    handleTimeOptionSelection(optionName) {
      // Compute the date and time based on the selected time option
      const now = dayjs();
      let targetDateTime;

      switch (optionName) {
        case 'In 20 minutes':
          targetDateTime = now.add(20, 'minute');
          break;
        case 'In 1 hour':
          targetDateTime = now.add(1, 'hour');
          break;
        case 'In 3 hours':
          targetDateTime = now.add(3, 'hour');
          break;
        case 'Tomorrow':
          targetDateTime = now.add(1, 'day').startOf('day').add(9, 'hour');
          break;
        case 'Next week':
          targetDateTime = now.add(1, 'week').startOf('day').add(9, 'hour');
          break;
        case 'Custom...':
          this.$refs.reminderPopup.openPopups();
          break;
        default:
          this.errorMessage = 'No such option';
      }

      if (targetDateTime) {
        const utcDateTime = targetDateTime.utc();
        this.setReminder(utcDateTime.format('YYYY-MM-DDTHH:mm:ss'));
        this.addReminder();
      }
    },

    pinMessage() {
      if (this.editingMessage.isPinned) {
        this.unpinCurrentMessage();
      } else {
        this.pinCurrentMessage();
      }
    },

    redactMessage() {
      this.$load(() => {
        if (this.editingMessage.isBlured) {
          this.unblurMessage()
        } else {
          this.blurMessage();
        }
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
    showMessage() {
      if (this.editingMessage.isBlured && this.unBlurredMessage) {
        this.clearUnBlurredMessage();
      } else {
        this.showBlurred();
      }
    },

    changeTagState(tag) {
      this.setLoadingState(true);
      if (tag.isActive) {
        this.addTagToMessage(tag.id)
      } else {
        this.removeTagToMessage(tag.id)
      }
    },

    closeDetails() {
      this.$router.push({name: 'Case messages mobile list'})
    },
  },
  mounted() {
    setTimeout(() => {
      this.highlightBackgroundColor = this.editingMessage.highlight ? this.editingMessage.highlight : null;
    }, 1000);
  }
}
</script>
<style scoped lang="scss">
.message-details-wrapper {
  max-width: 435px;
  max-height: 1031px;
  width: 100%;
  height: 85vh;
  border-left: 1px solid var(--border-color);
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;

  .message-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;

    .message-details-title {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      color: var(--secondary-black-color);
    }
  }

  .menu-item-wrapper {

    .menu-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 25px;
      background-color: var(--hover-accent-color);

      .header-text {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: var(--light-gray);
          cursor: pointer;
        }
      }

      .menu-item-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        text-transform: uppercase;
        color: var(--secondary-black-color);
      }

      svg {
        transition: transform 0.3s;
      }
    }
  }
}

.message-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 25px 25px;
  transition: all .5s;

  .message-wrapper {
    display: flex;
    justify-content: space-between;
    max-height: 200px;
    height: fit-content;
    overflow-y: scroll;

    .relative {
      position: relative;
      width: 100%;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: var(--black-color);
        margin: 0;
      }
    }
  }

  .pinned,
  .view-message {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: var(--bright-accent-color)
    }
  }

  .view-message {
    cursor: pointer;
  }
}

.tags-wrapper {
  padding: 0 25px 15px;
  transition: all .5s;
  height: 190px;
  overflow-y: scroll;

  .tag-section {
    padding-top: 15px;

    .section-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
      color: var(--accent-color);
      padding-bottom: 15px;
    }

    .section-tags {

      .tag {
        display: flex;
        gap: 10px;
      }
    }

    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.tags-disabled {
  opacity: 0.1;
  pointer-events: none;
}

.comments-wrapper {
  padding: 0 0 25px 25px;

  .comments-item-wrapper {
    height: fit-content;
    max-height: 190px;
    overflow-y: scroll;
  }

  .comment-textarea {
    width: 100%;
    resize: none;
    min-height: 104px;
    outline: none;
    border: 1px solid var(--input-placeholder-value-color);
    border-radius: 16px !important;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    z-index: 2;
    color: var(--secondary-text-color);
    margin: 15px 0;
  }

  .btn {
    max-width: 120px;
    height: 35px;
  }
}

h2 {
  margin: 0;
}

svg {
  cursor: pointer;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.slide-enter-to, .slide-leave {
  max-height: 1000px;
  opacity: 1;
}

.tools {
  position: absolute;
  z-index: 10;
  transition: 0.2s all;
  border: 1px solid var(--case-btn-border-color);
  border-radius: 8px;
  padding: 8px;
  background-color: var(--white-color);
  font-size: 12px;

  max-width: fit-content;
  min-width: 250px;
  width: 100%;
  height: fit-content;
  box-shadow: 0 4px 8px 0 #00000029;
}

.colors {
  display: flex;
  align-items: center;
  gap: 16px;

  .close {
    border: none;
    width: fit-content;
    padding: 0;
    background-color: transparent;
  }

  .color {
    border: 1px solid transparent;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    &.white {
      border-color: var(--case-btn-border-color);
    }

    &:hover,
    &.active {
      border-color: #373c44;
    }
  }
}

.underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.background {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blurred {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black-color);
  pointer-events: none;
  width: 100%;
}

.edit-message-textarea {
  display: block;
  border: .5px solid var(--light-gray);
  border-radius: 0 !important;
  height: auto;
  padding: 0 !important;
  width: 100%;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  width: 100%;
  margin-top: 20px;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  width: 100%;
  margin-top: 20px;
}

.no-pos {
  position: unset;
}

</style>