<template>
  <div
    class="custom-select"
    ref="caseChooseDropdown"
  >
    <div
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
    >
      <transition name="fade" mode="in-out">
        <span v-show="!isSidebarCollapsed" v-if="!selected" class="placeholder">No case chosen</span>
        <span v-show="!isSidebarCollapsed" v-if="selected">{{ selected }}</span>
      </transition>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.10656 10.5693L7.74274 13.9022C7.77651 13.9332 7.81661 13.9578 7.86076 13.9746C7.9049 13.9914 7.95222 14 8 14C8.04779 14 8.0951 13.9914 8.13924 13.9746C8.18339 13.9578 8.22349 13.9332 8.25726 13.9022L11.8934 10.5693C11.9617 10.5067 12 10.4219 12 10.3335C12 10.245 11.9617 10.1602 11.8934 10.0977C11.8252 10.0351 11.7327 10 11.6362 10C11.5397 10 11.4471 10.0351 11.3789 10.0977L8 13.1952L4.62108 10.0977C4.5873 10.0667 4.54719 10.0421 4.50305 10.0254C4.45891 10.0086 4.4116 10 4.36382 10C4.31604 10 4.26873 10.0086 4.22459 10.0254C4.18045 10.0421 4.14034 10.0667 4.10656 10.0977C4.07278 10.1286 4.04598 10.1654 4.02769 10.2059C4.00941 10.2463 4 10.2897 4 10.3335C4 10.3773 4.00941 10.4206 4.02769 10.4611C4.04598 10.5016 4.07278 10.5383 4.10656 10.5693Z"
          fill="#D7DCE6"/>
        <path
          d="M11.8934 5.43071L8.25726 2.09775C8.22349 2.06676 8.18339 2.04218 8.13924 2.02541C8.0951 2.00863 8.04778 2 8 2C7.95221 2 7.9049 2.00863 7.86076 2.02541C7.81661 2.04218 7.77651 2.06676 7.74274 2.09775L4.10656 5.43071C4.03833 5.49325 4 5.57807 4 5.66652C4 5.75496 4.03833 5.83979 4.10656 5.90233C4.17479 5.96487 4.26733 6 4.36382 6C4.46031 6 4.55285 5.96487 4.62108 5.90233L8 2.80476L11.3789 5.90233C11.4127 5.93329 11.4528 5.95786 11.497 5.97462C11.5411 5.99137 11.5884 6 11.6362 6C11.684 6 11.7313 5.99137 11.7754 5.97462C11.8195 5.95786 11.8597 5.93329 11.8934 5.90233C11.9272 5.87136 11.954 5.8346 11.9723 5.79414C11.9906 5.75368 12 5.71031 12 5.66652C12 5.62273 11.9906 5.57936 11.9723 5.5389C11.954 5.49844 11.9272 5.46168 11.8934 5.43071Z"
          fill="#D7DCE6"/>
      </svg>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <h2 class="dropdown-title">CASES</h2>
      <div class="search-wrapper">
        <CustomSearch
          @update:modelValue="searchCase"
          placeholder="Search by name"
        />
      </div>
      <div
        v-for="(option, i) of casesFromApi"
        :key="i"
        class="item"
        :class="{active: selected === option.caseName}"
        @click="onSelect(option)"
      >
        {{ option.caseName }}
        <svg
          v-if="selected === option.caseName"
          width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.8313 5.95628L7.83128 15.9563C7.74339 16.0441 7.62425 16.0934 7.50003 16.0934C7.37581 16.0934 7.25667 16.0441 7.16878 15.9563L2.79378 11.5813C2.71098 11.4924 2.6659 11.3749 2.66804 11.2535C2.67018 11.132 2.71938 11.0161 2.80526 10.9303C2.89115 10.8444 3.00701 10.7952 3.12845 10.793C3.24989 10.7909 3.36742 10.836 3.45628 10.9188L7.50003 14.9617L17.1688 5.29378C17.2576 5.21098 17.3752 5.1659 17.4966 5.16804C17.618 5.17018 17.7339 5.21938 17.8198 5.30526C17.9057 5.39115 17.9549 5.50701 17.957 5.62845C17.9592 5.74989 17.9141 5.86742 17.8313 5.95628Z"
            fill="#373C44"/>
        </svg>
      </div>
      <div
        v-if="hasPermission(['case_management'])"
        @click="$router.push({ path: '/create-case' })"
        class="item"
        style="justify-content: normal; gap: 5px;"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H10.625V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375H9.375V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10Z"
            fill="#373C44"/>
        </svg>
        <span>Create new case</span>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSearch from "@/components/inputs/CustomSearch.vue";
import debounce from "@/mixins/debounce";
import {mapActions, mapGetters} from "vuex";
import hasPermission from "@/utils/permission";

export default {
  name: "CustomCaseSelect",
  components: {CustomSearch},
  data() {
    return {
      selected: null,
      open: false,
      casesFromApi: [],
    };
  },
  watch: {
    '$route.params.slug': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          const caseFromPath = this.casesFromApi.find(aCase => aCase.id.toString() === newVal);
          this.onSelect(caseFromPath);
        }
      }
    },
    updateCaseList() {
      this.getCases();
    },
  },
  computed: {
    ...mapGetters('appState', {
      isSidebarCollapsed: 'getSidebarState',
      currentCase: 'getCurrentCase',
      updateCaseList: 'getUpdateCasesList',
    }),
  },
  mixins: [debounce],
  methods: {
    hasPermission,
    ...mapActions('appState', ['setCurrentCase']),
    searchCase(keySearch) {
      this.debounce(() => this.getCases(keySearch), 500);
    },
    handleClickOutside(event) {
      // If the dropdown is open and the clicked element is not inside the dropdown, then close it.
      if (this.open && this.$refs.caseChooseDropdown && !this.$refs.caseChooseDropdown.contains(event.target)) {
        this.open = false;
      }
    },
    onSelect(option) {
      this.selected = option.caseName;
      this.setCurrentCase(option);
      localStorage.setItem('title', option.caseName);
      this.$store.dispatch('profile/getPermissionPerCase', {caseId: this.currentCase.id});
      if (this.$route.path.includes('/cases/')) {
        this.$router.push({name: this.$route.name, params: {slug: option.id.toString(), forceReload: true}});
      }
      this.open = false;
    },
    getCases(keySearch) {
      this.$load(async () => {
          const response = await this.$api.cases.getCases({
            caseName: keySearch || '',
            page: 1,
            size: 100,
          });
          this.casesFromApi = response.data.body.content;
          if (this.casesFromApi.length > 0) {
            if (!this.currentCase) {
              await this.setCurrentCase(response.data.body.content[0]);
              this.selected = response.data.body.content[0].caseName;
              localStorage.setItem('title', response.data.body.content[0].caseName);
            } else {
              this.selected = this.currentCase.caseName;
              localStorage.setItem('title', this.currentCase.caseName);
            }
          }
        }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        })
      );
    },
  },
  created() {
    this.getCases();
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>

.custom-select {
  max-width: 210px;
  width: 100%;
  outline: none;
}

.custom-select .selected {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--case-btn-border-color) !important;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
}

.custom-select .selected:hover {
  filter: drop-shadow(0px 0px 5px rgba(200, 205, 212, 0.5));
}

.selected span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-select .items {
  max-width: 200px;
  width: 100%;
  height: fit-content;
  max-height: 272px;
  padding: 8px 0px;
  border-radius: 4px;

  margin-top: 5px;
  color: var(--secondary-black-color);
  overflow-x: hidden;
  position: absolute;
  background-color: var(--white-color);
  left: 15px;
  right: 0;
  z-index: 10;
  overflow-y: scroll;
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
  box-shadow: 0px 4px 20px 0px #4D6A9326;
}

.custom-select .items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-black-color);
}

.custom-select .items div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select .items .item:hover {
  width: 100%;
  background: var(--case-btn-border-color);
  border-left: 3px solid var(--secondary-black-color);
}

.selectHide {
  display: none;
}

.support-form .selected {
  padding: 15px 25px;
}

.support-form .selected svg path {
  fill: var(--light-gray);
}

.support-form .selected:hover {
  filter: none;
}

.selectMessages .selected {
  padding: 9px 15px;
}

.selectMessages .items div {
  font-size: 12px;
}

.placeholder {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray);
}

.dropdown-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-black-color);
  padding: 8px 16px 3px;
  margin: 0;
}

.search-wrapper {
  padding: 5px 15px;
}

.active {
  background: var(--case-btn-border-color);
  border-left: 3px solid var(--secondary-black-color);
}

</style>
