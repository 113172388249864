<template>
  <div class="wrapper">
    <div class="posts-wrapper">
      <div v-for="(post, index) in posts" class="post" :key="index">
        <div class="post-title" @click="onPostClick(post)">
          <div class="title">{{post.postTitle}}</div>
          <svg :class="{ 'active-arrow': isCurrentPost(post) }" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.023 7.14797L9.39799 12.773C9.34574 12.8253 9.28371 12.8668 9.21542 12.8951C9.14713 12.9234 9.07394 12.9379 9.00002 12.9379C8.9261 12.9379 8.8529 12.9234 8.78461 12.8951C8.71633 12.8668 8.65429 12.8253 8.60205 12.773L2.97705 7.14797C2.89829 7.0693 2.84465 6.96903 2.82291 6.85986C2.80116 6.75069 2.8123 6.63752 2.85492 6.53468C2.89753 6.43184 2.96969 6.34396 3.06228 6.28216C3.15486 6.22036 3.2637 6.18741 3.37502 6.1875H14.625C14.7363 6.18741 14.8452 6.22036 14.9378 6.28216C15.0303 6.34396 15.1025 6.43184 15.1451 6.53468C15.1877 6.63752 15.1989 6.75069 15.1771 6.85986C15.1554 6.96903 15.1017 7.0693 15.023 7.14797Z" fill="black"/>
          </svg>
        </div>
        <transition name="fade">
          <div
            v-if="$route.path.includes(`/topic/${post.postTopic}/${post.postTitle}`)"
            class="post-content"
          >
            <div v-if="post.imageUrl">
              <img :src="post.imageUrl" alt="post-image">
            </div>
            <div class="post-text" v-html="post.postText"/>
            <div class="help" v-show="false">
              <div class="help-info">
                <span>Was this information helpful?</span>
                <div class="help-buttons">
                  <button id="yes_help_button" class="btn btn-revert help-button">Yes</button>
                  <button id="no_help_button" class="btn btn-revert help-button">No</button>
                </div>
              </div>
              <div class="help-statistics">
                <span>This article was helpful to 83% of users</span>
              </div>
            </div>
            <div v-if="post.relatedPosts && post.relatedPosts.length" class="related-articles-wrapper">
              <span class="related-articles-title">Related articles:</span>
              <div
                v-for="(article, index) in post.relatedPosts"
                :key="index"
                @click="pushToArticle(article)"
              >
                <span class="related-articles-item">{{ article.title }}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SupportTopic',
  emits: ["pushTo"],
  props: {
    posts: {
      required: true
    },
    chosenArticle: {
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {
    onPostClick(post) {
      if (post && post.postTopic && post.postTitle
        && this.$route.path.includes(`/topic/${post.postTopic}/${post.postTitle}`)) {
        // this.$router.push({path: `/pme-support/topic/${post.postTopic}/=`});
      } else {
        this.$router.push({path: `/pme-support/topic/${post.postTopic}/${post.postTitle}`});
      }
    },
    // getRelatedPosts(relatedPosts) {
    //   if (typeof relatedPosts !== 'string') {
    //     return [];
    //   }
    //   return relatedPosts.split("|");
    // },
    pushToArticle(article) {
      this.$emit('pushTo', article);
    },
    isCurrentPost(post) {
      if (post && post.postTopic && post.postTitle) {
        return this.$route.path.includes(`/topic/${post.postTopic}/${post.postTitle}`);
      }
      return false;
    }
  },
}
</script>
<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding: 24px;

  .posts-wrapper {
    max-width: 870px;
    width: 100%;

    :last-child {
      border-bottom: none;
    }

    .post {
      width: inherit;
      border-bottom: 1px solid var(--case-btn-border-color);

      .post-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 85px;
        cursor: pointer;

        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
        }

        svg {
          transition: transform 0.3s;
        }

        .active-arrow {
          transform: rotate(-180deg);
        }
      }

      .post-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding-bottom: 40px;

        .help {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &-info {
            display: flex;
            align-items: center;
            gap: 27px;

            span {
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              color: var(--secondary-text-color);
            }
          }

          &-buttons {
            display: flex;
            align-items: center;
            gap: 15px;
          }

          &-button {
            min-width: 90px;
            height: 45px;
            padding: 15px 35px;
            border-radius: 30px;
            border: 1px solid var(--accent-color);
          }

          &-statistics {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: var(--light-gray);
          }

        }

        .related-articles {

          &-wrapper {
            width: 868px;
            height: fit-content;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            border-radius: 10px;
            border: 1px solid var(--case-btn-border-color);
          }

          &-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            color: var(--secondary-black-color);
          }

          &-item {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: var(--accent-color);
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>