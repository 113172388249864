import { render, staticRenderFns } from "./CaseEmployeesItem.vue?vue&type=template&id=4d6b5475&scoped=true"
import script from "./CaseEmployeesItem.vue?vue&type=script&lang=js"
export * from "./CaseEmployeesItem.vue?vue&type=script&lang=js"
import style0 from "./CaseEmployeesItem.vue?vue&type=style&index=0&id=4d6b5475&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6b5475",
  null
  
)

export default component.exports