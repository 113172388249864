<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <CustodianSupport/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CustodianSupport from "@/components/support/CustodianSupport.vue";

export default {
  name: "ClientSupport",
  components: {
    CustodianSupport,
    HeaderRegistered,
  },
  mounted() {
    document.title = 'Custodian Support'
  }
}
</script>

<style scoped>

</style>