<template>
  <div class="dashboard-search" :class="classComponent">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5" clip-path="url(#clip0_9084_575)">
        <path d="M23.0362 21.4823C23.6053 22.0461 23.6053 22.9642 23.0362 23.5334C22.7516 23.8179 22.3811 23.9575 22.0107 23.9575C21.6402 23.9575 21.2697 23.8179 20.9852 23.5334L16.0993 18.6474C16.4859 18.3629 16.851 18.0461 17.1999 17.6971C17.5436 17.3535 17.855 16.9884 18.1395 16.6072L18.2469 16.6877L23.0362 21.4823Z" fill="#8692A6"/>
        <path d="M17.2056 3.89861C20.674 7.36707 20.9854 12.7953 18.1452 16.6127C17.866 16.9939 17.5546 17.359 17.2056 17.7026C16.8566 18.0516 16.4915 18.3684 16.1049 18.653C12.2874 21.4825 6.86463 21.1657 3.40691 17.7026C-0.405169 13.8906 -0.405169 7.71069 3.40691 3.89861C7.21362 0.0865306 13.3935 0.0865306 17.2056 3.89861ZM15.6861 16.1832C18.6552 13.2087 18.6552 8.39257 15.6861 5.41807C12.7116 2.44895 7.8955 2.44895 4.92101 5.41807C1.94651 8.3872 1.95188 13.2087 4.92101 16.1832C7.89013 19.1523 12.7116 19.1523 15.6861 16.1832Z" fill="#8692A6"/>
        <path d="M15.686 5.41831C18.6551 8.38744 18.6551 13.2089 15.686 16.1834C12.7115 19.1525 7.89537 19.1525 4.92088 16.1834C1.95175 13.2089 1.95175 8.39281 4.92088 5.41831C7.89 2.44382 12.7115 2.44919 15.686 5.41831Z" fill="white"/>
        <path d="M18.8544 16.5376C20.1269 14.6477 20.7336 12.3765 20.5779 10.0839C20.4061 7.60873 19.343 5.27315 17.5873 3.51745C15.6437 1.57383 13.0558 0.5 10.3068 0.5C7.55778 0.5 4.96986 1.57383 3.02087 3.51745C1.07724 5.46644 0.00341797 8.04899 0.00341797 10.798C0.00341797 13.547 1.07724 16.1349 3.02087 18.0785C4.77657 19.8342 7.10677 20.8973 9.57657 21.0691C9.81818 21.0852 10.0598 21.096 10.296 21.096C12.3417 21.096 14.3444 20.4893 16.0356 19.351L20.5994 23.9148C20.9752 24.2906 21.4692 24.5 22.0061 24.5C22.5376 24.5 23.037 24.2906 23.4128 23.9201C23.7887 23.5443 23.9981 23.045 23.9981 22.5134C23.9981 21.9819 23.7887 21.4825 23.4128 21.1121L18.8544 16.5376ZM15.7833 18.2181C14.0222 19.5228 11.8477 20.1564 9.65174 20.0007C7.43429 19.845 5.35107 18.8946 3.77791 17.3215C2.03295 15.5819 1.07724 13.2624 1.07724 10.798C1.07724 8.33356 2.03832 6.01946 3.77791 4.2745C5.51751 2.52953 7.83698 1.57383 10.3014 1.57383C12.7658 1.57383 15.0799 2.5349 16.8249 4.2745C18.3981 5.84765 19.3484 7.93624 19.5041 10.1591C19.6544 12.355 19.0209 14.5349 17.7108 16.2906C17.4477 16.645 17.1524 16.994 16.8249 17.3161C16.492 17.6544 16.143 17.955 15.7833 18.2181ZM22.6611 23.1577C22.4893 23.3295 22.2585 23.4262 22.0115 23.4262C21.7645 23.4262 21.539 23.3295 21.3672 23.1577L16.9108 18.7013C17.1417 18.5081 17.3672 18.304 17.5873 18.0839C17.8021 17.8691 18.0115 17.6383 18.2048 17.4074L22.6611 21.8638C22.8329 22.0356 22.9296 22.2611 22.9296 22.5081C22.9296 22.7497 22.8329 22.9805 22.6611 23.1577Z" fill="#8692A6"/>
      </g>
      <defs>
        <clipPath id="clip0_9084_575">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
    <input
        :id="id"
        class="search"
        type="text"
        :placeholder="placeholder"
        :value="modelValue"
        @input="change"
    >
  </div>
</template>

<script>
import debounce from "@/mixins/debounce";

export default {
  name: "CustomSearch",
  props: {
    classComponent: {
      type: String,
      default: '',
    },
    id: {
      type: String
    },
    modelValue: String,
    placeholder: {
      type: String,
      default: 'Search by name'
    }
  },
  mixins: [debounce],
  methods: {
    change(event) {
      this.debounce(() => {
        let inputValue = event.target.value
        this.$emit('update:modelValue', inputValue)
        this.$emit('filter')
      }, 1000)
    }
  }
}
</script>

<style scoped>

/* search */

.dashboard-search input {
  width: 100%;
  height: auto;
  max-width: 80%;
}

.search {
  border: none;
  border-radius: 8px;
}

.dashboard-search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 98%;
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  height: 35px;
  gap: 5px;
}

.search::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-gray);
}

.dashboard-search svg {
  margin: 0 0 0 10px;
}

input {
  outline: none;
}

.faq-search {
  width: 100%;
}

.faq-search .search {
  width: 100%;
  padding: 8px 15px 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

/* end search */

.dashboard-search.case {
  max-width: 310px;
}

.dashboard-search.case .search {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray);
  max-width: 265px;
}

.case-members-form {
  max-width: 310px;
}

.case-members-form input {
  width: 100%;
}

.dashboard-search.assign-case-manager {
  width: 100%;
  max-width: 300px;
}

.dashboard-search.assign-case-manager input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
}

.dashboard-search.case-actions input {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray);
}
</style>
