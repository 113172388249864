<template>
  <div class="wrapper user-profile-public-page__wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath"/>
      <main class="main">
        <section class="profile-section">
          <div class="top-info">
            <div class="top-info-content">
              <div class="top-info-name">
                <h2>{{ capitalizeFirstLetter(user.firstName) }} {{ capitalizeFirstLetter(user.lastName) }}</h2>
                <span
                  v-for="role in user.roles"
                  :key="role"
                >
                  {{ getRoleNameFE(role) }}
                </span>
              </div>
              <div class="top-info-btns">
                <button
                  :disabled="currentUserInfo.publicId === userId || !hasPermission(['manage_individual_rights']) || (user.roles && user.roles.includes('SYSTEM_ADMINISTRATOR'))"
                  :id="`edit_permission_user_${userId}`"
                  @click="$router.push({path: `/permissions/individual/${userId}`})"
                  class="btn gray-btn"
                  title="Edit permissions">
                  <span class="btn-span">Edit permissions</span>
                  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.6125 11.8647C16.3649 11.8647 16.1643 12.0664 16.1643 12.3153V16.316C16.1634 17.0623 15.5619 17.6669 14.8194 17.6678H2.24136C1.49891 17.6669 0.897421 17.0623 0.896546 16.316V4.57412C0.897421 3.82801 1.49891 3.22324 2.24136 3.22236H6.22154C6.46914 3.22236 6.66981 3.02065 6.66981 2.77177C6.66981 2.52289 6.46914 2.32118 6.22154 2.32118H2.24136C1.00406 2.32259 0.00140085 3.33043 0 4.57412V16.316C0.00140085 17.5597 1.00406 18.5675 2.24136 18.5689H14.8194C16.0568 18.5675 17.0594 17.5597 17.0608 16.316V12.3153C17.0608 12.0664 16.8601 11.8647 16.6125 11.8647Z"
                      fill="#082655"/>
                    <path
                      d="M16.8837 1.162C16.0952 0.371355 14.819 0.371355 14.0307 1.162L6.03302 9.20094C5.97838 9.25604 5.93863 9.32433 5.91797 9.39913L4.86646 13.2152C4.8232 13.3717 4.86716 13.5393 4.98133 13.6542C5.09567 13.769 5.26237 13.8131 5.41804 13.7698L9.21453 12.7127C9.28912 12.6919 9.35724 12.652 9.41205 12.5969L9.43744 12.5712L17.4093 4.55813C18.1971 3.76643 18.1971 2.48278 17.4093 1.69109L16.8837 1.162ZM6.58793 10.3445L8.27403 12.0395L5.94161 12.689L6.58793 10.3445ZM9.12067 12.253V12.2526L9.41292 12.5464L9.12067 12.253ZM9.12067 11.6158L7.00959 9.49383L13.5551 2.91454L15.666 5.03617L9.12067 11.6158ZM16.7755 3.92097L16.2999 4.39901L14.1891 2.2772L14.6647 1.79916C15.1025 1.36001 15.8115 1.36001 16.2494 1.79916L16.7755 2.32842C17.2132 2.7681 17.2132 3.48129 16.7755 3.92097Z"
                      fill="#082655"/>
                  </svg>
                </button>
                <button
                  :disabled="currentUserInfo.publicId === userId || !hasPermission(['manage_contacts'])  || (user.roles && user.roles.includes('SYSTEM_ADMINISTRATOR'))"
                  :id="`deactivate_user_${userId}`"
                  class="btn btn-deactivate"
                  @click.stop="confirmDeactivateUser(userId)"
                  title="Deactivate"
                >
                  <span class="btn-span">Deactivate</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                      fill="#D7DCE6"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="top-info-img">
              <img
                :src="user.photoProfile"
                v-if="user.photoProfile"
                class="uploading-image"
                onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
              />
              <img
                v-else
                src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'
                alt="">
            </div>
          </div>
          <div class="bottom-info">
            <div class="bottom-info-content">
              <div class="info-content-item">
                <span>phone number</span>
                <p>{{ user.phone }}</p>
              </div>
              <div class="info-content-item">
                <span>Email</span>
                <p>{{ user.email }}</p>
              </div>
              <div class="info-content-item">
                <span>Creation Date</span>
                <p>{{ user.activityDate }}</p>
              </div>
            </div>
          </div>
          <h6 v-if="userManagesCases.length">User manages cases</h6>
          <div v-if="userManagesCases.length" class="holder-profile-cases">
            <ClientCaseItem
              v-for="(caseItem, index) in userManagesCases"
              :key="index"
              :caseItem="caseItem"
            />
          </div>
          <h6 v-if="userCasesMember.length">Cases member</h6>
          <div v-if="userCasesMember.length" class="holder-profile-cases">
            <ClientCaseItem
              v-for="(caseItem, index) in userCasesMember"
              :key="index"
              :caseItem="caseItem"
            />
          </div>
        </section>
      </main>
    </div>
    <CommonDialog
      ref="commonDialogDeleteUser"
      @closed="$router.push({path: '/contacts/team'})"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">User was deactivated</p>
    </CommonDialog>
    <InfoDialog
      ref="infoDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="deactivateUser"/>
  </div>
</template>

<script>
import InfoDialog from "../components/popups/InfoDialog";
import hasPermission from "../utils/permission";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import CommonDialog from "../components/popups/CommonDialog";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import ClientCaseItem from "../components/client-profile/ClientCaseItem";
import createDeactivateDialog from "../constants/infoDialogData";
import capitalizeFirstLetter from "../mixins/capitalizeFirstLetter";
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "UserProfile",
  components: {
    InfoDialog,
    ClientCaseItem,
    HeaderRegistered,
    AlertErrorMsg,
    CommonDialog
  },
  data() {
    return {
      infoDialogData: {},
      userId: this.$route.params.slug,
      user: {},
      currentUserInfo: '',
      returnPath: true,
      errorMessage: '',
    }
  },
  computed: {
    userManagesCases() {
      if (this.user && this.user.cases) {
        return this.user.cases.filter(aCase => aCase.caseManagerName === this.user.firstName + " " + this.user.lastName)
      }
      return [];
    },
    userCasesMember() {
      if (this.user && this.user.cases) {
        return this.user.cases.filter(aCase => aCase.caseManagerName !== this.user.firstName + " " + this.user.lastName)
      }
      return [];
    },
  },
  mixins: [capitalizeFirstLetter, getRoleName],
  methods: {
    hasPermission,
    getUser() {
      this.$load(async () => {
        await this.$api.profile.getProfileUser({userId: this.userId}).then((res) => {
            this.user = res.data.body
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
          })
        )
      })
    },
    getCurrentUser() {
      this.$load(async () => {
        await this.$api.profile.getProfile().then((res) => {
          this.currentUserInfo = res.data.body
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    confirmDeactivateUser(id) {
      this.infoDialogData = createDeactivateDialog('account');
      this.$refs.infoDialog.openPopup(id);
    },
    deactivateUser(id) {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(id).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    }
  },
  mounted() {
    document.title = 'User profile'
    this.getUser()
    this.getCurrentUser()
  }
}
</script>

<style scoped>
.profile-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 27px 55px 27px 35px;
}

.top-info {
  position: relative;
  background-color: var(--accent-color);
  border-radius: 40px 40px 0 0;
  min-height: 90px;
}

.top-info-img {
  position: absolute;
  top: 100%;
  left: 30px;
  transform: translateY(-70%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.bottom-info {
  background-color: var(--blue-bg-color);
  border-radius: 0 0 40px 40px;
  margin-bottom: 50px;
}

.top-info-content h2 {
  font-size: 26px;
  font-weight: 700;
  color: var(--white-color);
}

.top-info-name {
  margin-bottom: 12px;
}

.top-info-name span {
  text-transform: uppercase;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.top-info, .bottom-info {
  display: flex;
  justify-content: flex-end;
}

.top-info-content, .bottom-info-content {
  width: 85%;
}

.top-info-content {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.bottom-info-content {
  width: 85%;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  align-items: flex-start;
  justify-content: flex-start;
}

.info-content-item {
  width: calc(33.33% - 100px);
  display: flex;
  flex-direction: column;
}

.info-content-item span {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
}

.info-content-item p {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
  line-height: 17px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.profile-image-info {
  text-align: center;
}

.input-image {
  display: none;
}

.form-profile-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-profile-image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  border-radius: 50%;
}

.input__file-button {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 2;
}

.uploading-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid transparent;
  transition: all .5s;
}

.form-profile-image svg {
  position: absolute;
  z-index: 2;
  transition: all .5s;
}

.form-profile-image.active svg {
  z-index: 4;
}

.form-profile-image.active .input__file-button {
  z-index: 5;
}

.form-profile-image.active:after {
  z-index: 3;
  opacity: 0;
  transition: all .5s;
}

.form-profile-image.active:hover:after {
  opacity: 0.5;
}

.form-profile-image.active svg {
  opacity: 0;
}

.form-profile-image.active:hover svg {
  opacity: 1;
}

.user-photo-error {
  text-align: center;
}

.top-info-btns {
  margin: 20px 34px 0 0;
  display: flex;
  align-items: flex-start;
}

.top-info-btns .btn:hover, .group .btn:hover {
  background: #fff;
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.top-info-btns .btn svg {
  margin-left: 10px;
}

.top-info-btns .btn:first-child {
  margin-right: 13px;
}

.holder-profile-cases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}
</style>