<template>
  <div class="table-row">
    <div class="card">

      <div class="case" @click="goToCase">
        <div class="holder-info">
          <span>{{ session.caseName }}</span>
        </div>
      </div>

      <div class="device">
        <div class="holder-info">
          <div class="devices-wrapper">
            <div v-for="(device, index) in (session.device.split(' '))" :key="index" class="device-name">
              <svg v-if="device.toLowerCase().includes('ios') || device.toLowerCase().includes('android')"
                   width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#F8FAFC"/>
                <path
                  d="M19.3655 8H12.6332C11.9894 8 11.4692 8.52214 11.4692 9.16598V22.8355C11.4692 23.4774 11.9894 24 12.6332 24H19.3655C20.0083 24 20.5304 23.4779 20.5304 22.8355V9.16598C20.5299 8.52214 20.0083 8 19.3655 8ZM19.6573 20.9636H12.3423V9.96686H19.6573V20.9636ZM17.5602 9.18315H14.439V8.8428H17.5607L17.5602 9.18315ZM18.8958 9.02762C18.8958 9.19224 18.7615 9.32555 18.5974 9.32555C18.4328 9.32555 18.299 9.19173 18.299 9.02762C18.299 8.8635 18.4328 8.72968 18.5974 8.72968C18.762 8.72918 18.8958 8.863 18.8958 9.02762ZM17.4203 22.7578H14.5778V21.8832H17.4203V22.7578Z"
                  fill="#082655"/>
              </svg>
              <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#F8F5FF"/>
                <g clip-path="url(#clip0_25341_1064)">
                  <path
                    d="M24.4023 19.7443V18.9878V14.4468V9.90569C24.4023 9.49008 24.0527 9.14917 23.6249 9.14917H8.06996C7.64212 9.14917 7.29153 9.48841 7.29153 9.90569V14.4468V18.9878V19.7443L5.34741 22.0149C5.34741 22.6404 5.86948 23.1492 6.51408 23.1492H25.1807C25.8244 23.1492 26.3474 22.6402 26.3474 22.0149L24.4023 19.7443ZM17.404 22.7721H14.2925C14.185 22.7721 14.0979 22.6881 14.0979 22.5827C14.0979 22.4774 14.185 22.3934 14.2925 22.3934H17.4043C17.5108 22.3934 17.5989 22.4774 17.5989 22.5827C17.5959 22.6883 17.5106 22.7721 17.404 22.7721ZM12.6414 22.0156L13.2348 21.3225H18.4588L19.0532 22.0156H12.6414ZM23.3077 18.4058C23.3077 18.7709 23.0022 19.0663 22.6297 19.0663H9.0649C8.69241 19.0663 8.38685 18.7683 8.38685 18.4058V10.4891C8.38685 10.124 8.69241 9.8286 9.0649 9.8286H22.6299C23.0024 9.8286 23.308 10.1259 23.308 10.4891C23.3077 10.4891 23.3077 18.4058 23.3077 18.4058Z"
                    fill="#9B7EDE"/>
                </g>
                <defs>
                  <clipPath id="clip0_25341_1064">
                    <rect width="21" height="20" fill="white" transform="translate(5.34741 6)"/>
                  </clipPath>
                </defs>
              </svg>
              <span>{{ device }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="date">
        <div class="holder-info">
          <span>{{ parseDate(session.dataSession) }}</span>
        </div>
      </div>

      <div class="view">
        <div @click="pushToSessionsPage" class="holder-info">
          <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M24 8.21053C24 8.21053 19.5 0 12 0C4.5 0 0 8.21053 0 8.21053C0 8.21053 4.5 16.4211 12 16.4211C19.5 16.4211 24 8.21053 24 8.21053ZM1.7595 8.21053C2.48478 9.30756 3.31897 10.3293 4.2495 11.2604C6.18 13.1846 8.82 14.9282 12 14.9282C15.18 14.9282 17.8185 13.1846 19.752 11.2604C20.6825 10.3293 21.5167 9.30756 22.242 8.21053C21.5167 7.11349 20.6825 6.09174 19.752 5.16069C17.8185 3.23644 15.1785 1.49282 12 1.49282C8.82 1.49282 6.1815 3.23644 4.248 5.16069C3.31746 6.09174 2.48327 7.11349 1.758 8.21053H1.7595Z"
                  fill="#696F79"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.9999 4.47866C11.0053 4.47866 10.0515 4.87186 9.34823 5.57176C8.64497 6.27165 8.24988 7.22092 8.24988 8.21072C8.24988 9.20052 8.64497 10.1498 9.34823 10.8497C10.0515 11.5496 11.0053 11.9428 11.9999 11.9428C12.9944 11.9428 13.9483 11.5496 14.6515 10.8497C15.3548 10.1498 15.7499 9.20052 15.7499 8.21072C15.7499 7.22092 15.3548 6.27165 14.6515 5.57176C13.9483 4.87186 12.9944 4.47866 11.9999 4.47866ZM6.74988 8.21072C6.74988 6.825 7.303 5.49603 8.28757 4.51617C9.27213 3.53632 10.6075 2.98584 11.9999 2.98584C13.3923 2.98584 14.7276 3.53632 15.7122 4.51617C16.6968 5.49603 17.2499 6.825 17.2499 8.21072C17.2499 9.59644 16.6968 10.9254 15.7122 11.9053C14.7276 12.8851 13.3923 13.4356 11.9999 13.4356C10.6075 13.4356 9.27213 12.8851 8.28757 11.9053C7.303 10.9254 6.74988 9.59644 6.74988 8.21072Z"
                  fill="#696F79"/>
          </svg>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import parseDate from "@/mixins/parseDate";
import gotoProfile from "@/mixins/gotoProfile";
import {mapActions} from "vuex";

export default {
  name: "ClientSessionItem",
  props: {
    session: {
      required: true,
    }
  },
  data() {
    return {
      popupAction: '',
      showTooltip: false,
    }
  },
  mixins: [parseDate, gotoProfile],
  methods: {
    ...mapActions('sessions', ['setSessionData']),
    goToCase() {
      localStorage.setItem('title', this.keyItem.caseName)
      this.$router.push({path: `/cases/${this.keyItem.caseId}`}).catch(() => {
      })
    },
    pushToSessionsPage() {
      this.setSessionData({
        caseName: this.session.caseName,
        sessionId: this.session.session
      });
      this.$router.push({path: `cases/${this.session.caseId}/custodian-support`})
    }
  },
}
</script>
<style scoped lang="scss">
.case {
  width: 32%;
}

.device {
  width: 32%;
  //padding: 0 30% 0 0;
}

.date {
  width: 32%;
}

.view {
  width: 4%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0 15px 15px;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;

  &:hover {
    background-color: var(--hover-accent-color);
  }
}

.holder-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.holder-info svg {
  cursor: pointer;
}

.holder-info span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.case .holder-info span {
  cursor: pointer;
  text-decoration: underline;
}

.devices-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  .device-name {
    display: flex;
    align-items: center;
    gap: 5px;

    svg, span {
      display: inline-block;
    }
  }
}
</style>