<template>
  <svg width="77" height="36" viewBox="0 0 77 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M74.7135 20.4437H63.3748V16.8369H74.7135V20.4437ZM76.3294 28.5106H63.3748C62.8776 28.5106 62.3344 28.4234 61.7451 28.249C61.1559 28.0747 60.608 27.7902 60.1016 27.3955C59.6044 26.9917 59.1855 26.4732 58.8448 25.84C58.5134 25.1975 58.3477 24.4129 58.3477 23.486V10.5734C58.3477 10.3256 58.3937 10.0916 58.4858 9.87131C58.5778 9.65105 58.7021 9.45832 58.8587 9.29313C59.0244 9.12794 59.2177 8.99945 59.4387 8.90768C59.6597 8.81591 59.8991 8.77002 60.1569 8.77002H76.3294V12.3492H61.9385V23.486C61.9385 23.954 62.0628 24.3119 62.3114 24.5597C62.56 24.8075 62.9236 24.9314 63.4024 24.9314H76.3294V28.5106Z" fill="white"/>
    <path d="M54.812 28.5118H51.1936V16.7555L44.8268 27.9198C44.6702 28.2043 44.4493 28.42 44.1638 28.5668C43.8876 28.7137 43.5884 28.7871 43.2661 28.7871C42.9531 28.7871 42.6585 28.7137 42.3822 28.5668C42.1152 28.42 41.9035 28.2043 41.747 27.9198L35.3525 16.7555V28.5118H31.7617V10.2992C31.7617 9.88626 31.8814 9.51916 32.1208 9.19796C32.3694 8.87675 32.6916 8.66108 33.0876 8.55095C33.2809 8.50506 33.4743 8.4913 33.6676 8.50965C33.861 8.51883 34.0451 8.56013 34.22 8.63355C34.4042 8.69779 34.5699 8.79415 34.7172 8.92263C34.8646 9.04194 34.9888 9.18878 35.0901 9.36315L43.2661 23.5284L51.4422 9.36315C51.6539 9.01441 51.9439 8.76662 52.3122 8.61978C52.6897 8.47294 53.081 8.45 53.4862 8.55095C53.8729 8.66108 54.1905 8.87675 54.4391 9.19796C54.6877 9.51916 54.812 9.88626 54.812 10.2992V28.5118Z" fill="white"/>
    <path d="M29.248 17.0297C29.248 17.8465 29.1467 18.5761 28.9441 19.2185C28.7416 19.8609 28.4653 20.4299 28.1155 20.9255C27.7748 21.4119 27.3789 21.8294 26.9277 22.1782C26.4766 22.5269 26.0024 22.8114 25.5052 23.0317C25.0172 23.2519 24.52 23.4125 24.0136 23.5135C23.5165 23.6144 23.0469 23.6649 22.6049 23.6649H14.4842V20.0857H22.6049C23.0653 20.049 23.4796 19.9573 23.8479 19.8104C24.2254 19.6544 24.5477 19.4479 24.8147 19.1909C25.0817 18.934 25.2888 18.6265 25.4362 18.2686C25.5835 17.9015 25.6571 17.4885 25.6571 17.0297V15.4053C25.6111 14.9556 25.5144 14.5426 25.3671 14.1663C25.2198 13.79 25.0172 13.4688 24.7594 13.2027C24.5108 12.9366 24.207 12.7301 23.8479 12.5832C23.4888 12.4272 23.0745 12.3492 22.6049 12.3492H14.5118C14.033 12.3492 13.6693 12.4731 13.4207 12.7209C13.1721 12.9687 13.0478 13.3266 13.0478 13.7946V28.5106H9.45703V13.7946C9.45703 12.8769 9.62276 12.0968 9.95422 11.4544C10.2949 10.812 10.7138 10.2935 11.211 9.89884C11.7174 9.50421 12.2652 9.21971 12.8545 9.04534C13.4438 8.86179 13.987 8.77002 14.4842 8.77002H22.6049C23.4152 8.77002 24.1425 8.87556 24.7871 9.08664C25.4316 9.28854 25.9978 9.56386 26.4858 9.9126C26.983 10.2522 27.4019 10.6468 27.7426 11.0965C28.0924 11.5462 28.3779 12.0188 28.5988 12.5144C28.829 13.0008 28.9948 13.4964 29.096 14.0011C29.1973 14.4967 29.248 14.9648 29.248 15.4053V17.0297Z" fill="white"/>
    <path d="M73.9805 31.7485H74.6606L75.4739 33.2145H75.5064L76.3197 31.7485H76.9998L75.7905 33.8261V35.0623H75.1898V33.8261L73.9805 31.7485Z" fill="#5692B7"/>
    <path d="M71.3633 35.0589V31.7451H72.61C72.8654 31.7451 73.0797 31.7893 73.2528 31.8778C73.4271 31.9663 73.5585 32.0903 73.6473 32.2499C73.7371 32.4085 73.782 32.5935 73.782 32.8049C73.782 33.0174 73.7366 33.2019 73.6457 33.3583C73.5558 33.5136 73.4233 33.6339 73.2479 33.7191C73.0726 33.8033 72.8573 33.8453 72.6019 33.8453H71.7139V33.347H72.5207C72.6701 33.347 72.7923 33.3265 72.8876 33.2855C72.9828 33.2434 73.0531 33.1825 73.0986 33.1027C73.1451 33.0218 73.1684 32.9225 73.1684 32.8049C73.1684 32.6874 73.1451 32.587 73.0986 32.504C73.0521 32.4198 72.9812 32.3562 72.886 32.3131C72.7907 32.2688 72.6679 32.2467 72.5175 32.2467H71.9655V35.0589H71.3633ZM73.0807 33.5573L73.9038 35.0589H73.2317L72.4233 33.5573H73.0807Z" fill="#5692B7"/>
    <path d="M68.5859 35.0623V31.7485H70.7482V32.2517H69.1882V33.1514H70.6362V33.6546H69.1882V34.5591H70.7612V35.0623H68.5859Z" fill="#5692B7"/>
    <path d="M65.7232 31.7485L66.5884 34.3568H66.6225L67.4861 31.7485H68.1484L66.9764 35.0623H66.2329L65.0625 31.7485H65.7232Z" fill="#5692B7"/>
    <path d="M64.8871 33.4039C64.8871 33.7609 64.82 34.0667 64.6858 34.3213C64.5527 34.5748 64.3709 34.7689 64.1404 34.9038C63.911 35.0386 63.6507 35.106 63.3596 35.106C63.0685 35.106 62.8076 35.0386 62.5771 34.9038C62.3477 34.7679 62.1659 34.5732 62.0317 34.3197C61.8986 34.0651 61.832 33.7598 61.832 33.4039C61.832 33.0468 61.8986 32.7415 62.0317 32.488C62.1659 32.2335 62.3477 32.0388 62.5771 31.9039C62.8076 31.7691 63.0685 31.7017 63.3596 31.7017C63.6507 31.7017 63.911 31.7691 64.1404 31.9039C64.3709 32.0388 64.5527 32.2335 64.6858 32.488C64.82 32.7415 64.8871 33.0468 64.8871 33.4039ZM64.2816 33.4039C64.2816 33.1525 64.2421 32.9405 64.1631 32.768C64.0852 32.5943 63.977 32.4632 63.8384 32.3748C63.6999 32.2852 63.5403 32.2405 63.3596 32.2405C63.1788 32.2405 63.0192 32.2852 62.8807 32.3748C62.7422 32.4632 62.6334 32.5943 62.5544 32.768C62.4765 32.9405 62.4375 33.1525 62.4375 33.4039C62.4375 33.6552 62.4765 33.8677 62.5544 34.0414C62.6334 34.214 62.7422 34.345 62.8807 34.4345C63.0192 34.523 63.1788 34.5672 63.3596 34.5672C63.5403 34.5672 63.6999 34.523 63.8384 34.4345C63.977 34.345 64.0852 34.214 64.1631 34.0414C64.2421 33.8677 64.2816 33.6552 64.2816 33.4039Z" fill="#5692B7"/>
    <path d="M61.3637 32.865H60.7566C60.7392 32.7658 60.7073 32.6779 60.6608 32.6013C60.6142 32.5236 60.5563 32.4578 60.4871 32.4039C60.4178 32.35 60.3388 32.3095 60.2501 32.2825C60.1624 32.2545 60.0677 32.2405 59.966 32.2405C59.7853 32.2405 59.6251 32.2858 59.4855 32.3764C59.3459 32.4659 59.2366 32.5975 59.1576 32.7712C59.0786 32.9438 59.0391 33.1547 59.0391 33.4039C59.0391 33.6573 59.0786 33.8709 59.1576 34.0446C59.2377 34.2172 59.347 34.3477 59.4855 34.4362C59.6251 34.5235 59.7847 34.5672 59.9644 34.5672C60.0639 34.5672 60.157 34.5543 60.2436 34.5284C60.3312 34.5014 60.4097 34.4621 60.479 34.4103C60.5493 34.3585 60.6083 34.2949 60.6559 34.2193C60.7046 34.1438 60.7382 34.0575 60.7566 33.9605L61.3637 33.9637C61.3409 34.1212 61.2917 34.269 61.2159 34.407C61.1413 34.5451 61.0433 34.667 60.9221 34.7727C60.8009 34.8774 60.6592 34.9593 60.4968 35.0187C60.3345 35.0769 60.1543 35.106 59.9563 35.106C59.6641 35.106 59.4032 35.0386 59.1738 34.9038C58.9444 34.7689 58.7637 34.5742 58.6316 34.3197C58.4996 34.0651 58.4336 33.7598 58.4336 33.4039C58.4336 33.0468 58.5001 32.7415 58.6333 32.488C58.7664 32.2335 58.9476 32.0388 59.1771 31.9039C59.4065 31.7691 59.6662 31.7017 59.9563 31.7017C60.1413 31.7017 60.3134 31.7275 60.4725 31.7793C60.6316 31.8311 60.7733 31.9072 60.8978 32.0075C61.0222 32.1067 61.1245 32.2286 61.2046 32.3732C61.2857 32.5166 61.3388 32.6806 61.3637 32.865Z" fill="#5692B7"/>
    <path d="M57.3605 32.6579C57.3453 32.5166 57.2815 32.4066 57.1689 32.3278C57.0574 32.2491 56.9124 32.2097 56.7339 32.2097C56.6083 32.2097 56.5006 32.2286 56.4108 32.2664C56.321 32.3041 56.2523 32.3554 56.2047 32.4201C56.157 32.4848 56.1327 32.5587 56.1316 32.6417C56.1316 32.7108 56.1473 32.7707 56.1787 32.8214C56.2112 32.8721 56.255 32.9152 56.3102 32.9508C56.3654 32.9853 56.4265 33.0144 56.4936 33.0382C56.5607 33.0619 56.6283 33.0819 56.6965 33.098L57.0082 33.1757C57.1337 33.2048 57.2544 33.2442 57.3702 33.2938C57.4871 33.3434 57.5915 33.406 57.6835 33.4815C57.7766 33.557 57.8502 33.6482 57.9043 33.755C57.9584 33.8618 57.9854 33.9869 57.9854 34.1304C57.9854 34.3245 57.9357 34.4955 57.8361 34.6433C57.7365 34.79 57.5926 34.9049 57.4043 34.9879C57.2171 35.0699 56.9903 35.1109 56.7241 35.1109C56.4655 35.1109 56.2409 35.071 56.0505 34.9912C55.8611 34.9113 55.7128 34.7948 55.6057 34.6417C55.4996 34.4885 55.4423 34.3019 55.4336 34.0818H56.0261C56.0348 34.1972 56.0705 34.2932 56.1332 34.3698C56.196 34.4464 56.2777 34.5036 56.3784 34.5413C56.4801 34.5791 56.5937 34.598 56.7193 34.598C56.8502 34.598 56.9649 34.5786 57.0634 34.5397C57.163 34.4998 57.2409 34.4448 57.2972 34.3747C57.3534 34.3035 57.3821 34.2204 57.3832 34.1255C57.3821 34.0392 57.3567 33.968 57.3069 33.9119C57.2571 33.8547 57.1873 33.8073 57.0975 33.7695C57.0087 33.7307 56.9049 33.6962 56.7858 33.666L56.4076 33.5689C56.1338 33.4988 55.9173 33.3925 55.7583 33.2501C55.6003 33.1067 55.5212 32.9163 55.5212 32.679C55.5212 32.4837 55.5743 32.3127 55.6803 32.166C55.7875 32.0193 55.933 31.9055 56.117 31.8246C56.301 31.7427 56.5093 31.7017 56.742 31.7017C56.9779 31.7017 57.1846 31.7427 57.3621 31.8246C57.5407 31.9055 57.6808 32.0183 57.7825 32.1628C57.8843 32.3063 57.9367 32.4713 57.94 32.6579H57.3605Z" fill="#5692B7"/>
    <path d="M54.8991 31.7485V35.0623H54.2969V31.7485H54.8991Z" fill="#5692B7"/>
    <path d="M52.111 35.0623H50.9844V31.7485H52.1337C52.4638 31.7485 52.7473 31.8149 52.9843 31.9476C53.2224 32.0792 53.4053 32.2685 53.533 32.5155C53.6607 32.7625 53.7245 33.0581 53.7245 33.4022C53.7245 33.7474 53.6601 34.044 53.5314 34.2921C53.4037 34.5402 53.2191 34.7306 52.9778 34.8633C52.7376 34.996 52.4486 35.0623 52.111 35.0623ZM51.5866 34.5429H52.0817C52.3133 34.5429 52.5065 34.5008 52.6613 34.4167C52.816 34.3315 52.9323 34.2047 53.0103 34.0365C53.0882 33.8671 53.1272 33.6557 53.1272 33.4022C53.1272 33.1487 53.0882 32.9383 53.0103 32.7711C52.9323 32.6029 52.8171 32.4772 52.6645 32.3941C52.513 32.31 52.3247 32.2679 52.0996 32.2679H51.5866V34.5429Z" fill="#5692B7"/>
    <path d="M49.3555 35.1102C49.1055 35.1102 48.8896 35.0584 48.7078 34.9548C48.5271 34.8502 48.388 34.7024 48.2906 34.5115C48.1932 34.3195 48.1445 34.0935 48.1445 33.8335C48.1445 33.5779 48.1932 33.3535 48.2906 33.1604C48.3891 32.9663 48.5266 32.8152 48.703 32.7074C48.8794 32.5984 49.0866 32.5439 49.3247 32.5439C49.4784 32.5439 49.6234 32.5688 49.7597 32.6184C49.8972 32.6669 50.0184 32.7424 50.1233 32.8449C50.2294 32.9474 50.3127 33.0779 50.3733 33.2365C50.4339 33.394 50.4642 33.5817 50.4642 33.7996V33.9792H48.4205V33.5844H49.901C49.8999 33.4722 49.8755 33.3724 49.8279 33.285C49.7803 33.1966 49.7137 33.127 49.6282 33.0763C49.5438 33.0256 49.4453 33.0002 49.3328 33.0002C49.2127 33.0002 49.1072 33.0294 49.0162 33.0876C48.9253 33.1448 48.8545 33.2203 48.8036 33.3141C48.7538 33.4069 48.7284 33.5088 48.7273 33.6199V33.9646C48.7273 34.1091 48.7538 34.2332 48.8068 34.3367C48.8599 34.4392 48.934 34.518 49.0292 34.573C49.1245 34.6269 49.2359 34.6539 49.3636 34.6539C49.4491 34.6539 49.5265 34.642 49.5958 34.6183C49.665 34.5935 49.7251 34.5573 49.776 34.5099C49.8268 34.4624 49.8652 34.4036 49.8912 34.3335L50.4399 34.395C50.4053 34.5395 50.3392 34.6658 50.2419 34.7736C50.1455 34.8804 50.0222 34.9635 49.8717 35.0228C49.7213 35.0811 49.5492 35.1102 49.3555 35.1102Z" fill="#5692B7"/>
    <path d="M44.3633 35.0589V31.7451H46.5255V32.2483H44.9655V33.148H46.4135V33.6512H44.9655V34.5557H46.5385V35.0589H44.3633Z" fill="#5692B7"/>
    <path d="M41.7734 35.0623V31.7485H42.3757V34.5591H43.8399V35.0623H41.7734Z" fill="#5692B7"/>
    <path d="M41.1218 31.7451V35.0589H40.5195V31.7451H41.1218Z" fill="#5692B7"/>
    <path d="M37.5156 35.0589V31.7451H38.7883C39.0286 31.7451 39.2282 31.7829 39.3873 31.8584C39.5475 31.9328 39.6671 32.0347 39.7461 32.1642C39.8261 32.2936 39.8662 32.4403 39.8662 32.6043C39.8662 32.7391 39.8402 32.8546 39.7883 32.9506C39.7363 33.0455 39.6665 33.1226 39.5789 33.1819C39.4912 33.2413 39.3933 33.2839 39.285 33.3098V33.3421C39.403 33.3486 39.5161 33.3847 39.6243 33.4505C39.7336 33.5153 39.8229 33.607 39.8922 33.7256C39.9614 33.8443 39.996 33.9877 39.996 34.156C39.996 34.3275 39.9544 34.4818 39.871 34.6188C39.7877 34.7547 39.6622 34.862 39.4944 34.9408C39.3267 35.0195 39.1157 35.0589 38.8614 35.0589H37.5156ZM38.1179 34.5573H38.7656C38.9842 34.5573 39.1416 34.5158 39.238 34.4327C39.3354 34.3486 39.3841 34.2407 39.3841 34.1091C39.3841 34.0109 39.3597 33.9225 39.311 33.8437C39.2623 33.7639 39.193 33.7013 39.1032 33.656C39.0134 33.6097 38.9063 33.5865 38.7818 33.5865H38.1179V34.5573ZM38.1179 33.1544H38.7136C38.8175 33.1544 38.9111 33.1356 38.9945 33.0978C39.0778 33.059 39.1433 33.0045 39.1909 32.9344C39.2396 32.8632 39.2639 32.7791 39.2639 32.682C39.2639 32.5536 39.2185 32.4479 39.1276 32.3648C39.0377 32.2818 38.9041 32.2402 38.7266 32.2402H38.1179V33.1544Z" fill="#5692B7"/>
    <path d="M36.9457 33.4039C36.9457 33.7609 36.8786 34.0667 36.7444 34.3213C36.6113 34.5748 36.4295 34.7689 36.199 34.9038C35.9695 35.0386 35.7093 35.106 35.4182 35.106C35.127 35.106 34.8662 35.0386 34.6357 34.9038C34.4063 34.7679 34.2245 34.5732 34.0903 34.3197C33.9572 34.0651 33.8906 33.7598 33.8906 33.4039C33.8906 33.0468 33.9572 32.7415 34.0903 32.488C34.2245 32.2335 34.4063 32.0388 34.6357 31.9039C34.8662 31.7691 35.127 31.7017 35.4182 31.7017C35.7093 31.7017 35.9695 31.7691 36.199 31.9039C36.4295 32.0388 36.6113 32.2335 36.7444 32.488C36.8786 32.7415 36.9457 33.0468 36.9457 33.4039ZM36.3402 33.4039C36.3402 33.1525 36.3007 32.9405 36.2217 32.768C36.1438 32.5943 36.0356 32.4632 35.897 32.3748C35.7585 32.2852 35.5989 32.2405 35.4182 32.2405C35.2374 32.2405 35.0778 32.2852 34.9393 32.3748C34.8008 32.4632 34.692 32.5943 34.613 32.768C34.5351 32.9405 34.4961 33.1525 34.4961 33.4039C34.4961 33.6552 34.5351 33.8677 34.613 34.0414C34.692 34.214 34.8008 34.345 34.9393 34.4345C35.0778 34.523 35.2374 34.5672 35.4182 34.5672C35.5989 34.5672 35.7585 34.523 35.897 34.4345C36.0356 34.345 36.1438 34.214 36.2217 34.0414C36.3007 33.8677 36.3402 33.6552 36.3402 33.4039Z" fill="#5692B7"/>
    <path d="M29.8359 31.7451H30.5729L31.5599 34.1463H31.5989L32.5858 31.7451H33.3228V35.0589H32.7449V32.7823H32.7141L31.7953 35.0492H31.3635L30.4447 32.7774H30.4138V35.0589H29.8359V31.7451Z" fill="#5692B7"/>
    <path d="M26.1094 35.0623V31.7485H26.7116V34.5591H28.1759V35.0623H26.1094Z" fill="#5692B7"/>
    <path d="M23.2288 35.0623H22.5859L23.7563 31.7485H24.4998L25.6719 35.0623H25.029L24.1411 32.4281H24.1151L23.2288 35.0623ZM23.2499 33.763H25.003V34.2452H23.2499V33.763Z" fill="#5692B7"/>
    <path d="M20.043 32.2483V31.7451H22.6955V32.2483H21.6679V35.0589H21.0705V32.2483H20.043Z" fill="#5692B7"/>
    <path d="M19.766 33.4039C19.766 33.7609 19.6989 34.0667 19.5647 34.3213C19.4316 34.5748 19.2498 34.7689 19.0193 34.9038C18.7899 35.0386 18.5296 35.106 18.2385 35.106C17.9474 35.106 17.6865 35.0386 17.456 34.9038C17.2266 34.7679 17.0448 34.5732 16.9106 34.3197C16.7775 34.0651 16.7109 33.7598 16.7109 33.4039C16.7109 33.0468 16.7775 32.7415 16.9106 32.488C17.0448 32.2335 17.2266 32.0388 17.456 31.9039C17.6865 31.7691 17.9474 31.7017 18.2385 31.7017C18.5296 31.7017 18.7899 31.7691 19.0193 31.9039C19.2498 32.0388 19.4316 32.2335 19.5647 32.488C19.6989 32.7415 19.766 33.0468 19.766 33.4039ZM19.1605 33.4039C19.1605 33.1525 19.121 32.9405 19.042 32.768C18.9641 32.5943 18.8559 32.4632 18.7174 32.3748C18.5788 32.2852 18.4192 32.2405 18.2385 32.2405C18.0577 32.2405 17.8981 32.2852 17.7596 32.3748C17.6211 32.4632 17.5123 32.5943 17.4333 32.768C17.3554 32.9405 17.3164 33.1525 17.3164 33.4039C17.3164 33.6552 17.3554 33.8677 17.4333 34.0414C17.5123 34.214 17.6211 34.345 17.7596 34.4345C17.8981 34.523 18.0577 34.5672 18.2385 34.5672C18.4192 34.5672 18.5788 34.523 18.7174 34.4345C18.8559 34.345 18.9641 34.214 19.042 34.0414C19.121 33.8677 19.1605 33.6552 19.1605 33.4039Z" fill="#5692B7"/>
    <path d="M14.1099 31.7451L14.9751 34.3534H15.0092L15.8728 31.7451H16.5351L15.3631 35.0589H14.6196L13.4492 31.7451H14.1099Z" fill="#5692B7"/>
    <path d="M13.0124 31.7451V35.0589H12.4102V31.7451H13.0124Z" fill="#5692B7"/>
    <path d="M9.46875 35.0623V31.7485H10.7155C10.9709 31.7485 11.1851 31.796 11.3583 31.8909C11.5325 31.9858 11.664 32.1164 11.7527 32.2825C11.8426 32.4475 11.8875 32.6352 11.8875 32.8456C11.8875 33.0581 11.8426 33.2469 11.7527 33.4119C11.6629 33.5769 11.5304 33.7069 11.355 33.8018C11.1797 33.8957 10.9638 33.9426 10.7073 33.9426H9.88107V33.4491H10.6262C10.7755 33.4491 10.8978 33.4232 10.993 33.3714C11.0883 33.3197 11.1586 33.2485 11.2041 33.1579C11.2506 33.0672 11.2739 32.9632 11.2739 32.8456C11.2739 32.728 11.2506 32.6244 11.2041 32.5349C11.1586 32.4454 11.0877 32.3758 10.9914 32.3262C10.8962 32.2755 10.7734 32.2501 10.6229 32.2501H10.071V35.0623H9.46875Z" fill="#5692B7"/>
    <path d="M3.84774 18.6106L3.85945 18.6866C5.91645 10.7128 12.4306 5.11218 15.4305 3.30859C13.6686 3.30859 8.85913 3.59337 5.71642 7.29547C2.65736 10.899 3.27943 14.929 3.84774 18.6106Z" fill="url(#paint0_linear_25133_93840)"/>
    <path d="M0.333432 11.6573L0.341765 11.7107C1.8047 6.09953 6.43754 2.15835 8.57109 0.88916C7.31805 0.88916 3.89753 1.08956 1.66244 3.69474C-0.513166 6.23059 -0.0707479 9.06649 0.333432 11.6573Z" fill="url(#paint1_linear_25133_93840)"/>
    <defs>
      <linearGradient id="paint0_linear_25133_93840" x1="9.45175" y1="3.30859" x2="9.45175" y2="18.6866" gradientUnits="userSpaceOnUse">
        <stop stop-color="#62A2C0"/>
        <stop offset="1" stop-color="#32375C"/>
      </linearGradient>
      <linearGradient id="paint1_linear_25133_93840" x1="4.319" y1="0.88916" x2="4.319" y2="11.7107" gradientUnits="userSpaceOnUse">
        <stop stop-color="#62A2C0"/>
        <stop offset="1" stop-color="#32375C"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LogoIcon",
}
</script>

<style scoped>

</style>