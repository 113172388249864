import store from '../store'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function hasRole(value) {
    if (value && value instanceof Array && value.length > 0) {
        const roles = store.state.profile.user.roles || []
        const permissionRoles = value

        return roles.some(role => {
            return permissionRoles.includes(role.toLowerCase())
        })
    } else {
        return false
    }
}