<template>
  <div class="wrapper user-profile-public-page__wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="profile-section">
          <div v-if="client" class="data-bar">
            <div class="data-bar-name">
              <div class="data-bar-name-img">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.5313 14.8125C22.5313 16.1043 22.1482 17.367 21.4305 18.4411C20.7129 19.5151 19.6928 20.3523 18.4994 20.8466C17.306 21.3409 15.9928 21.4703 14.7258 21.2183C13.4589 20.9662 12.2951 20.3442 11.3817 19.4308C10.4683 18.5174 9.84626 17.3536 9.59425 16.0867C9.34224 14.8197 9.47158 13.5065 9.96592 12.3131C10.4603 11.1197 11.2974 10.0996 12.3714 9.38196C13.4455 8.6643 14.7082 8.28125 16 8.28125C17.7316 8.28321 19.3917 8.97196 20.6161 10.1964C21.8405 11.4208 22.5293 13.0809 22.5313 14.8125ZM31.4375 16C31.4375 19.0532 30.5321 22.0379 28.8358 24.5766C27.1395 27.1153 24.7285 29.094 21.9077 30.2624C19.0868 31.4308 15.9829 31.7365 12.9883 31.1409C9.99372 30.5452 7.24302 29.0749 5.08405 26.916C2.92507 24.757 1.45479 22.0063 0.859134 19.0117C0.263475 16.0171 0.569189 12.9132 1.73762 10.0923C2.90604 7.27149 4.88471 4.86048 7.42339 3.16419C9.96208 1.46789 12.9468 0.5625 16 0.5625C20.093 0.566822 24.017 2.19466 26.9112 5.08881C29.8053 7.98297 31.4332 11.907 31.4375 16ZM29.0625 16C29.0606 14.2418 28.7042 12.5021 28.0145 10.8848C27.3248 9.26751 26.316 7.80594 25.0485 6.58748C23.781 5.36902 22.2807 4.41869 20.6375 3.79333C18.9943 3.16797 17.2418 2.88043 15.4849 2.94789C8.49352 3.21805 2.91821 9.04125 2.93751 16.0371C2.94421 19.2219 4.11881 22.2936 6.23876 24.6702C7.1021 23.418 8.19878 22.344 9.46876 21.507C9.57703 21.4355 9.70576 21.4014 9.83523 21.41C9.96471 21.4186 10.0878 21.4694 10.1857 21.5545C11.7995 22.9504 13.8619 23.7185 15.9956 23.7185C18.1292 23.7185 20.1916 22.9504 21.8054 21.5545C21.9033 21.4694 22.0264 21.4186 22.1559 21.41C22.2854 21.4014 22.4141 21.4355 22.5224 21.507C23.7939 22.3436 24.8921 23.4176 25.7568 24.6702C27.8872 22.2849 29.0641 19.1982 29.0625 16Z"
                    fill="black"/>
                </svg>
              </div>
              <div class="data-bar-name-text">
                <div v-if="!isEditingName" class="name-wrapper" @click="activateNameEdit">
                  <span v-if="client.fullName.trim()" id="client_name">{{ client.fullName.trim() }}</span>
                  <span v-else id="no_name">No user name</span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_26337_105591)">
                      <path
                        d="M17.092 1.50249C17.7049 2.11502 17.7049 3.10839 17.092 3.72092L16.2995 4.51345L13.5547 1.76866L14.3472 0.976124C14.9601 0.363427 15.9535 0.363427 16.5662 0.976124L17.092 1.50249Z"
                        fill="#696F79"/>
                      <path
                        d="M6.37542 8.948L9.12022 11.6928L9.09465 11.7184L5.29834 12.7701L6.34986 8.97356L6.37542 8.948Z"
                        fill="#696F79"/>
                      <path
                        d="M16.6125 11.3648C16.3649 11.3648 16.1643 11.5665 16.1643 11.8154V15.8161C16.1634 16.5624 15.5619 17.167 14.8194 17.1679H2.24136C1.49891 17.167 0.897421 16.5624 0.896546 15.8161V4.07423C0.897421 3.32812 1.49891 2.72335 2.24136 2.72247H6.22154C6.46914 2.72247 6.66981 2.52076 6.66981 2.27188C6.66981 2.023 6.46914 1.82129 6.22154 1.82129H2.24136C1.00406 1.8227 0.00140085 2.83054 0 4.07423V15.8161C0.00140085 17.0598 1.00406 18.0676 2.24136 18.069H14.8194C16.0568 18.0676 17.0594 17.0598 17.0608 15.8161V11.8154C17.0608 11.5665 16.8601 11.3648 16.6125 11.3648Z"
                        fill="#696F79"/>
                      <path
                        d="M16.8837 0.662073C16.0952 -0.128569 14.819 -0.128569 14.0307 0.662073L6.03302 8.70102C5.97838 8.75611 5.93863 8.8244 5.91797 8.89921L4.86646 12.7153C4.8232 12.8718 4.86716 13.0393 4.98133 13.1543C5.09567 13.269 5.26237 13.3132 5.41804 13.2699L9.21453 12.2128C9.28912 12.192 9.35724 12.1521 9.41205 12.097L9.43744 12.0713L17.4093 4.0582C18.1971 3.26651 18.1971 1.98286 17.4093 1.19116L16.8837 0.662073ZM6.58793 9.84456L8.27403 11.5395L5.94161 12.189L6.58793 9.84456ZM9.12067 11.7531V11.7527L9.41292 12.0465L9.12067 11.7531ZM9.12067 11.1159L7.00959 8.9939L13.5551 2.41461L15.666 4.53625L9.12067 11.1159ZM16.7755 3.42104L16.2999 3.89909L14.1891 1.77728L14.6647 1.29923C15.1025 0.860085 15.8115 0.860085 16.2494 1.29923L16.7755 1.8285C17.2132 2.26817 17.2132 2.98137 16.7755 3.42104Z"
                        fill="#696F79"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_26337_105591">
                        <rect width="18" height="18" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div v-else class="name-input" @keydown.esc="isEditingName = false">
                  <form @submit.prevent="submitNameEdit">
                    <input v-model="newClientName" type="text" placeholder="Enter user name" autofocus ref="newNameInput"/>
                  </form>
                  <svg @click="submitNameEdit" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.5306 7.28055L9.53055 19.2806C9.4609 19.3503 9.37818 19.4056 9.28713 19.4433C9.19609 19.4811 9.09849 19.5005 8.99993 19.5005C8.90137 19.5005 8.80377 19.4811 8.71272 19.4433C8.62168 19.4056 8.53896 19.3503 8.4693 19.2806L3.2193 14.0306C3.07857 13.8898 2.99951 13.699 2.99951 13.4999C2.99951 13.3009 3.07857 13.11 3.2193 12.9693C3.36003 12.8286 3.55091 12.7495 3.74993 12.7495C3.94895 12.7495 4.13982 12.8286 4.28055 12.9693L8.99993 17.6896L20.4693 6.2193C20.61 6.07857 20.8009 5.99951 20.9999 5.99951C21.199 5.99951 21.3898 6.07857 21.5306 6.2193C21.6713 6.36003 21.7503 6.55091 21.7503 6.74993C21.7503 6.94895 21.6713 7.13982 21.5306 7.28055Z"
                      fill="#5692B7"/>
                  </svg>
                </div>
                <span id="client_email">{{ client.email }}</span>
              </div>
            </div>
            <div class="data-bar-info">
              <button
                :disabled="!hasPermission(['manage_contacts'])"
                :id="`deactivate_client_${client.publicId.substring(0, 20)}`"
                class="btn btn-deactivate"
                data-title="Deactivate"
                @click.stop="confirmDeactivateUser"
              >
                <span class="btn-span">Deactivate</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                    fill="#D7DCE6"/>
                </svg>
              </button>
              <tooltip v-if="!hasPermission(['manage_contacts'])"
                       :target-ref="$refs[`disabledDeactivateClient_${client.publicId.substring(0, 20)}`]">
                <span>To be able to deactivate users from a system - you need to have the 'Manage contact's' permission in system</span>
              </tooltip>
            </div>
          </div>
          <div class="info-table">
            <div class="profile-cases">
              <h6>Member of case</h6>
              <div class="keys-search-panel">
                <CustomSelect
                  v-model="selectedCaseId"
                  placeholder="Case name"
                  :options="casesList"
                  @input="getKeysByFilter"
                  :type-select="'filterUser'"
                  :customClass="'custom-select-sessions'"
                  ref="caseSelect"
                />
                <date-picker
                  v-model="regDataRange"
                  :id="`sort_keys_by_reg_date`"
                  range
                  :format="'DD/MM/YYYY'"
                  :range-separator="' - '"
                  :editable="false"
                  class="datepicker-input"
                  :input-class="'datepicker-input'"
                  placeholder="Registration date"
                >
                  <template #icon-calendar>
                    <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                        fill="#082655"/>
                      <path
                        d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                        fill="white"/>
                      <path
                        d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                        fill="#082655"/>
                      <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                      <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                      <path
                        d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                        fill="#082655"/>
                      <path
                        d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                        fill="#082655"/>
                      <path
                        d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                        fill="white"/>
                    </svg>
                  </template>
                  <template #icon-clear>
                    <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                        fill="#082655"/>
                    </svg>
                  </template>
                </date-picker>
                <date-picker
                  v-model="dataRange"
                  :id="`sort_by_date_keys`"
                  range
                  :format="'DD/MM/YYYY'"
                  :range-separator="' - '"
                  :editable="false"
                  class="datepicker-input"
                  :input-class="'datepicker-input'"
                  placeholder="Usage period"
                >
                  <template #icon-calendar>
                    <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                        fill="#082655"/>
                      <path
                        d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                        fill="white"/>
                      <path
                        d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                        fill="#082655"/>
                      <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                      <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                      <path
                        d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                        fill="#082655"/>
                      <path
                        d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                        fill="#082655"/>
                      <path
                        d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                        fill="white"/>
                    </svg>
                  </template>
                  <template #icon-clear>
                    <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                        fill="#082655"/>
                    </svg>
                  </template>
                </date-picker>
                <CustomSelect
                  v-model="selectedStatus"
                  placeholder="Key Status"
                  :options="statusList"
                  @input="getKeysByFilter"
                  :customClass="'custom-select-sessions'"
                  ref="statusSelect"
                />
                <button
                  v-if="isAnyFilterApplied"
                  class="reset-filters"
                  @click="resetFilters"
                >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
                      fill="#FF9537"/>
                  </svg>
                  Reset Filters
                </button>
              </div>
              <ClientKeysList :keys-data="clientKeys" @sortBy="getKeysWithSortBy"/>
            </div>
            <div v-if="client" class="profile-no-data">
              <h6>No Matching Data</h6>
              <ClientNoMatching :client-email="client.email" :cases-list="casesList"/>
            </div>
            <div class="profile-sessions">
              <h6>Sessions</h6>
              <ClientSessionsList :client-id="clientId" :cases-list="casesList"/>
            </div>
            <div class="profile-sessions">
              <h6>Collections</h6>
              <ClientCollectionsList :cases-list="casesList"/>
            </div>
          </div>
        </section>
      </main>
    </div>
    <InfoDialog
      ref="infoDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="deactivateUser"/>
    <CommonDialog
      ref="commonDialogDeleteUser"
      @closed="$router.push({ path: '/management' })"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Contact was deactivated</p>
    </CommonDialog>
  </div>
</template>

<script>
import HeaderRegistered from "@/components/layout/HeaderRegistered";
import Tooltip from "@/components/common/Tooltip.vue";
import hasPermission from "@/utils/permission";
import createDeactivateDialog from "@/constants/infoDialogData";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import ClientKeysList from "@/pages/client-profile/ClientKeysList.vue";
import ClientCollectionsList from "@/pages/client-profile/ClientCollectionsList.vue";
import {mapActions} from "vuex";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {KEY_STATUS_SELECT} from "@/constants/const";
import DatePicker from "vue2-datepicker";
import ClientNoMatching from "@/pages/client-profile/ClientNoMatchTable.vue";
import ClientSessionsList from "@/pages/client-profile/ClientSessionsList.vue";

export default {
  name: "ClientProfile",
  components: {
    ClientSessionsList,
    ClientNoMatching,
    CustomSelect,
    ClientCollectionsList,
    ClientKeysList,
    CommonDialog,
    AlertErrorMsg,
    InfoDialog,
    Tooltip,
    HeaderRegistered,
    DatePicker,
  },
  data() {
    return {
      clientId: this.$route.params.slug,
      client: null,
      returnPath: true,
      isEditingName: false,
      infoDialogData: {},
      errorMessage: '',
      newClientName: '',

      page: 1,
      size: 10,

      clientKeys: [],
      casesFromApi: [],
      clientSessions: [],
      selectedCaseId: null,
      selectedStatus: null,
      statusList: KEY_STATUS_SELECT,

      dataRange: '',
      dateFrom: '',
      dateTo: '',

      regDataRange: '',
      regDateFrom: '',
      regDateTo: '',

      sortBy: '',
      orderBy: '',
    }
  },
  watch: {
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getKeysByFilter();
    },
    regDataRange(value) {
      this.regDateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.regDateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getKeysByFilter();
    },
  },
  computed: {
    casesList() {
      return this.casesFromApi.map((aCase) => {
        return {name: aCase.caseName, id: aCase.id};
      });
    },
    isAnyFilterApplied() {
      return this.dataRange
        || this.dateFrom
        || this.dateTo
        || this.selectedStatus
        || this.selectedCaseId;
    },
  },
  methods: {
    ...mapActions('sessions', ['setEmail']),
    hasPermission,
    submitNameEdit() {
      if (this.newClientName !== this.client.fullName.trim()) {
        this.$load(async () => {
          this.client.fullName = this.newClientName;
          await this.$api.profile.updateClientProfileInfo(this.client.publicId, {fullName: this.newClientName});
          await this.getClient();
        }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        }));
      }
      this.isEditingName = false;
    },
    activateNameEdit() {
      this.isEditingName = true;
      this.$nextTick(() => {
        this.$refs.newNameInput.focus();
      });
    },
    getKeysByFilter() {
      this.page = 1;
      this.clientKeys = [];
      this.getKeys();
    },
    getClient() {
      this.$load(async () => {
          const res = await this.$api.profile.getProfileClient({clientId: this.clientId});
          this.client = res.data.body;
          await this.setEmail(this.client.email)
          this.newClientName = this.client.fullName.trim();
          this.getKeys();
        }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        })
      )
    },
    getKeysWithSortBy(sortByObj) {
      this.sortBy = sortByObj.sortBy;
      this.orderBy = sortByObj.order;
      this.getKeys();
    },
    getKeys() {
      this.$load(async () => {
          const res = await this.$api.management.getKeys(
            {
              page: this.page,
              size: this.size,
              keySearch: this.client.email,
              caseId: this.selectedCaseId ? this.selectedCaseId : '',
              status: this.selectedStatus,
              dateFrom: this.dateFrom,
              dateTo: this.dateTo,
              order: this.orderBy,
              sortBy: this.sortBy,
              registerDateRange: this.regDateFrom && this.regDateTo ? (this.regDateFrom + " " + this.regDateTo) : '',
            });
          this.clientKeys = res.data.body.content;
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        }
      );
    },
    getCases() {
      this.loadFlag = true;
      this.$load(async () => {
        await this.$api.cases.getCases({
          caseName: '',
          page: 1,
          size: 100,
        }).then((res) => {
            this.casesFromApi = res.data.body.content
            this.loadFlag = false;
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert();
          })
        )
      })
    },
    confirmDeactivateUser() {
      this.infoDialogData = createDeactivateDialog('custodian');
      this.$refs.infoDialog.openPopup();
    },
    deactivateUser() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.contacts.deactivateUser(this.client.publicId).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    resetFilters() {
      this.page = 1;
      this.dataRange = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.sortBy = '';
      this.orderBy = '';
      this.selectedStatus = '';
      this.selectedCaseId = null;
      this.$refs.statusSelect.selected = null;
      this.$refs.caseSelect.value = null;
      this.getKeys();
    },
  },
  mounted() {
    document.title = 'Custodian Profile'
    this.getClient();
    this.getCases();
  }
}
</script>

<style scoped lang="scss">
.profile-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 32px 55px 27px 30px;

  .data-bar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--case-btn-border-color);
    padding: 0 0 16px;

    .data-bar-name {
      max-width: 350px;
      width: 100%;
    }

    .data-bar-name,
    .data-bar-info {
      display: flex;
      align-items: flex-start;
      gap: 15px;

      .data-bar-name-img {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: initial;
        border-radius: 50%;
        overflow: hidden;

        img {
          object-fit: cover;
        }
      }

      .data-bar-name-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 5px;
        max-width: 300px;
        width: 100%;

        .name-wrapper {
          display: flex;
          align-items: center;
          gap: 15px;
          cursor: pointer;

          #client_name {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: var(--accent-color);
          }

          #no_name {
            font-weight: 700;
            font-size: 18px;
            line-height: 21.78px;
            color: var(--secondary-text-color);
          }
        }

        #client_email {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: var(--secondary-text-color);
        }

        .name-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--case-btn-border-color);

          svg {
            cursor: pointer;
          }

          input {
            max-width: 300px;
            width: 100%;
            border: none;
          }
        }
      }
    }
  }
}

h6 {
  margin-bottom: 23px;
}

.info-table {
  display: flex;
  flex-direction: column;

  .profile-cases {
    .keys-search-panel {
      display: flex;
      gap: 15px;
      height: fit-content;
      align-items: center;
      margin: 0 0 15px;

      .reset-filters {
        background-color: transparent;
        display: flex;
        align-items: center;
        border: none;
        margin-right: 23px;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        color: var(--secondary-black-color);

        &:disabled {
          cursor: not-allowed;

          path {
            fill: var(--case-btn-border-color);
          }
        }

        svg {
          margin-right: 10px;
        }

        &:not(:disabled):hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.profile-cases,
.profile-sessions {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.holder-profile-cases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.content-wrapper {
  margin: 0 0 100px 0;
}
</style>