import instance from "@/api/instance";
import Vue from "vue";

function filterNewMessages(newMessages, state) {

    const existingMessagesMap = new Map(
        (state.messages || []).map((msg) => [msg.id, msg])
    );

    const mergedMessages = newMessages.map((newMsg) => {
        const existingMsg = existingMessagesMap.get(newMsg.id);
        if (existingMsg) {
            return {...existingMsg, ...newMsg};
        } else {
            return newMsg;
        }
    });

    // Optionally, include messages that are in the existing state but not in the new data
    const newMessageIds = new Set(newMessages.map((msg) => msg.id));
    const remainingOldMessages = (state.messages || []).filter(
        (msg) => !newMessageIds.has(msg.id)
    );
    return [...remainingOldMessages, ...mergedMessages];
}

function updateExistingMessages(updatedMessage, state) {
    return state.messages.map(message => {
        if (message.id === updatedMessage.id) {
            return updatedMessage;
        }
        return message;
    });
}

function updateNewMessages(state, res, commit) {
    let finalMessages;
    if (state.chatPage === 1) {
        finalMessages = res.data.body.content;
    } else {
        finalMessages = filterNewMessages(res.data.body.content, state);
    }
    commit('setMessages', finalMessages)
    if (res.data.body.last) {
        commit('setHasMoreMessages', false)
    } else {
        commit('setHasMoreMessages', true)
        state.chatPage += 1;
    }
}

export default {
    namespaced: true,
    state: {
        // CHAT DATA
        chatData: {
            caseId: null,
            chatId: null,
            clientId: null,
            deviceId: null,
            lastEditedMessageId: null,
        },

        // MESSAGES
        messages: [],
        unBlurredMessage: null,
        messageTags: [],
        editingMessage: null,

        // COMMENTS
        comments: [],
        editingComment: null,

        // TAGS
        tagsTabMessages: [],
        chatTags: [],
        tagsList: [],

        // COMMON
        isEditMode: false,
        isLoading: false,
        currentRoute: '',
        chatPage: 1,
        chatSize: 10,
        keySearch: '',
        hasMoreMessages: false,

        // REMINDER
        reminderMessages: [],
        remindAt: new Date(),
        countReminders: null,
    },
    getters: {
        getChatData: ({chatData}) => chatData,

        getMessages: ({messages}) => messages,
        getEditingMessage: ({editingMessage}) => editingMessage,
        getUnBlurredMessage: ({unBlurredMessage}) => unBlurredMessage,
        getComments: ({comments}) => comments,

        getEditModeState: ({isEditMode}) => isEditMode,
        getLoadingState: ({isLoading}) => isLoading,
        getHasMoreMessages: ({hasMoreMessages}) => hasMoreMessages,

        getChatTags: ({chatTags}) => chatTags,
        getMessageTags: ({messageTags}) => messageTags,
        getTagsList: ({tagsList}) => tagsList,

        getCountReminders: ({countReminders}) => countReminders,
        getReminderMessages: ({reminderMessages}) => reminderMessages,
    },
    actions: {

        // TAGS POPUP ENDPOINTS

        getListTags({state, commit}) {
            instance.get(`/tag/case/${state.chatData.caseId}`)
                .then(res => {
                    commit('setTagsList', res.data.body);
                });
        },

        addNewCategory({state, commit, dispatch}, {categoryName}) {
            instance.post(`/tag/case/${state.chatData.caseId}/category`, {category: categoryName})
                .then(() => {
                    try {
                        dispatch('getListTags');
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        editCategory({state, commit, dispatch}, {categoryId, categoryName}) {
            instance.put(`/tag/case/${state.chatData.caseId}/category/${categoryId}`, {category: categoryName})
                .then(() => {
                    try {
                        dispatch('getListTags');
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        removeCategory({state, commit, dispatch}, categoryId) {
            instance.delete(`/tag/case/${state.chatData.caseId}/category/${categoryId}`)
                .then(() => {
                    try {
                        dispatch('getListTags');
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        changeCategoryOrder({state, commit, dispatch}, {categoryId, orderIndex}) {
            instance.post(`/tag/case/${state.chatData.caseId}/category/order`, {categoryId, orderIndex})
                .then(() => {
                    try {
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        addNewTag({state, commit, dispatch}, {categoryId, newTagName}) {
            instance.post(`/tag/case/${state.chatData.caseId}?categoryId=${categoryId}`, {tag: newTagName})
                .then(() => {
                    try {
                        dispatch('getListTags');
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        editTag({state, commit, dispatch}, {tagId, tagName}) {
            instance.put(`/tag/case/${state.chatData.caseId}/tag/${tagId}`, {tag: tagName})
                .then(() => {
                    try {
                        dispatch('getListTags');
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        async removeTag({state, commit, dispatch}, {tagId}) {
            try {
                await instance.delete(`/tag/${tagId}/case/${state.chatData.caseId}`)
                dispatch('getListTags');
                dispatch('getMessageListTags');
            } catch (error) {
                return Promise.reject(error);
            } finally {
                setTimeout(() => commit('setLoadingState', false), 1000)
            }
        },

        changeTagOrder({state, commit, dispatch}, {tagId, categoryId, orderIndex}) {
            instance.post(`/tag/case/${state.chatData.caseId}/order`, {
                id: tagId,
                categoryId: categoryId,
                orderIndex: orderIndex,
            })
                .then(() => {
                    try {
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        // TAGS TAB ENDPOINTS

        getListTagsByChatId({state, commit}) {
            instance.get(`/tag/case/${state.chatData.caseId}/chat/${state.chatData.chatId}`)
                .then(res => {
                    const chatTagsActive = (res.data.body || []).map(category => ({
                        ...category,
                        tags: (category.tags || []).map(tag => ({
                            ...tag,
                            isActive: true,
                        })),
                    }));
                    commit('setChatTags', chatTagsActive)
                });
        },

        getListMessagesByChatIdAndTags({state, commit}) {
            try {
                const queryParams = new URLSearchParams();
                const tagNames = state.chatTags.length > 0
                    ? state.chatTags
                        .flatMap(category => category.tags
                            .filter(tag => tag.isActive)
                            .map(tag => tag.tag))
                        .join(",")
                    : '';

                queryParams.append('keySearch', state.keySearch);
                queryParams.append('tags', tagNames);
                queryParams.append('page', state.chatPage.toString());
                queryParams.append('size', state.chatSize.toString());
                instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/messages/tags?${queryParams.toString()}`)
                    .then(res => {
                        updateNewMessages(state, res, commit);
                    });
            } finally {
                setTimeout(() => commit('setLoadingState', false), 1000)
            }
        },

        // MESSAGES TAB ENDPOINTS

        async getChatMessages({state, commit}) {
            const res = await instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/messages`
                + `?page=${state.chatPage}&size=${state.chatSize}&keySearch=${state.keySearch}`)
            updateNewMessages(state, res, commit);
            return res;
        },

        // MESSAGE DETAILS

        addTagToMessage({state, dispatch, commit}, tagId) {
            instance.post(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/tag/${tagId}`, {})
                .then(() => {
                    try {
                        if (state.currentRoute.includes('reminder')) {
                            dispatch('getReminderMessages');
                        } else {
                            dispatch('getMessageById');
                        }
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        removeTagToMessage({state, dispatch, commit}, tagId) {
            instance.delete(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/tag/${tagId}`)
                .then(() => {
                    try {
                        if (state.currentRoute.includes('reminder')) {
                            dispatch('getReminderMessages');
                        } else {
                            dispatch('getMessageById');
                        }
                        dispatch('getMessageListTags');
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        blurMessage({state, dispatch}) {
            instance.put(`/messages/${state.editingMessage.id}/case/${state.chatData.caseId}/blur`, {})
                .then(() => {
                    dispatch('getMessageById');
                })
        },

        unblurMessage({state, dispatch}) {
            instance.put(`/messages/${state.editingMessage.id}/case/${state.chatData.caseId}/unblur`, {})
                .then(() => {
                    dispatch('getMessageById');
                })
        },

        showBlurred({state, commit}) {
            instance.get(`/messages/${state.editingMessage.id}/case/${state.chatData.caseId}`, {})
                .then((res) => {
                    commit('setUnBlurredMessage', res.data.body.message)
                })
        },

        getMessageById({state, commit, dispatch}, messageId) {
            let msgId = messageId
            if (!msgId) {
                msgId = state.editingMessage.id;
            }
            instance.get(`/messages/${state.chatData.caseId}/message/${msgId}/chat/${state.chatData.chatId}`)
                .then(res => {
                    try {
                        dispatch('setLoadingState', true)
                        commit('setEditingMessage', res.data.body);
                        setTimeout(() => {
                            dispatch('getMessageListTags');
                        }, 500);
                        setTimeout(() => {
                            dispatch('getCommentsByMessageId');
                        }, 500);
                        if (state.currentRoute !== 'comments') {
                            const updatedMessages = updateExistingMessages(res.data.body, state);
                            commit('setMessages', updatedMessages);
                        }
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                });
        },

        getMessageListTags({state, commit}) {
            instance.get(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/tags`)
                .then(res => {
                    const messageTags = (res.data.body.categories || []).map(category => ({
                        ...category,
                        tags: (category.tags || []).map(tag => ({
                            ...tag,
                            isActive: tag.isSelected,
                        })),
                    }));
                    commit('setMessageTags', messageTags)
                });
        },

        // COMMENTS

        getListCommentsByChatId({state, commit}) {
            try {
                instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/comments?`
                    + `searchKey=${state.keySearch}&page=${state.chatPage}&size=${state.chatSize}`)
                    .then(res => {
                        updateNewMessages(state, res, commit);
                    });
            } finally {
                setTimeout(() => commit('setLoadingState', false), 1000)
            }
        },

        getCommentsByMessageId({state, commit}) {
            instance.get(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/comments`)
                .then(res => {
                    commit('setComments', res.data.body)
                });
        },

        createCommentForMessage({state, dispatch}, text) {
            instance.post(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/comment`, {text}, {
                headers: {'Content-Type': 'application/json'}
            }).then(() => {
                dispatch('getCommentsByMessageId');
                setTimeout(() => {
                    if (state.currentRoute === 'comments') {
                        state.comments.forEach((comment) => {
                            if (!state.messages.some(message => (message.id === comment.id))) {
                                state.messages.unshift(comment)
                            }
                        })
                    }
                }, 500);
            })
        },

        changeComment({state, dispatch, commit}, newComment) {
            instance.put(`/messages/${state.chatData.caseId}/comment/${state.editingComment.id}`, {text: newComment})
                .then(() => {
                    const index = state.messages.findIndex(comment => comment.id === state.editingComment.id);
                    if (index !== -1) {
                        const updatedComment = {
                            ...state.messages[index],
                            text: newComment,
                        };
                        commit('UPDATE_COMMENT', {index, updatedComment});
                        setTimeout(() => dispatch('getCommentsByMessageId'), 500);
                    }
                });
        },

        deleteComment({state, dispatch}) {
            instance.delete(`/messages/${state.chatData.caseId}/comment/${state.editingComment.id}`)
                .then(() => {
                    if (state.currentRoute === 'comments') {
                        const deletingCommentIndex = state.messages.indexOf(state.editingComment);
                        state.messages.splice(deletingCommentIndex, 1);
                    }
                    dispatch('getCommentsByMessageId');
                });
        },

        // PINNED

        getListPinnedMessages({state, commit}) {
            instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/messages/pin?`
                + `keySearch=${state.keySearch}&page=${state.chatPage}&size=${state.chatSize}`)
                .then(res => {
                    updateNewMessages(state, res, commit);
                });
        },

        pinMessage({state, dispatch}) {
            instance.post(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/message/${state.editingMessage.id}/pin`, {})
                .then(() => {
                    dispatch('getMessageById');
                    setTimeout(() => {
                        if (state.currentRoute === 'pinned' || state.currentRoute === 'edit') {
                            if (!state.messages.includes(state.editingMessage)) {
                                state.messages.push(state.editingMessage)
                                state.messages.sort((a, b) => b.id - a.id);
                            }
                        }
                    }, 500);
                })
        },

        unpinMessage({state, dispatch}) {
            instance.delete(`/messages/${state.editingMessage.id}/case/${state.chatData.caseId}/chat/${state.chatData.chatId}/pin`, {})
                .then(() => {
                    if (state.currentRoute === 'pinned') {
                        state.messages = state.messages.filter((msg) => msg.id !== state.editingMessage.id);
                    }
                    dispatch('getMessageById');
                })
        },

        // HIGHLIGHT

        getListHighlightMessages({state, commit}) {
            instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/messages/highlight?`
                + `keySearch=${state.keySearch}&page=${state.chatPage}&size=${state.chatSize}`)
                .then((res) => {
                    updateNewMessages(state, res, commit);
                })
        },

        createHighlightForMessage({state, dispatch}, color) {
            color = color.replace('#', '')

            instance.post(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/highlight?color=${color}`, null, {
                headers: {'Content-Type': 'application/json'}
            }).then(() => {
                dispatch('getMessageById');
                setTimeout(() => {
                    if (state.currentRoute === 'highlight' || state.currentRoute === 'edit') {
                        if (!state.messages.includes(state.editingMessage)) {
                            state.messages.push(state.editingMessage)
                            state.messages.sort((a, b) => b.id - a.id);
                        }
                    }
                }, 500);
            })
        },

        deleteHighlightForMessage({state, dispatch}) {
            instance.delete(`/messages/${state.chatData.caseId}/message/${state.editingMessage.id}/highlight`)
                .then(() => {
                    dispatch('getMessageById');
                    setTimeout(() => {
                        if (state.currentRoute === 'highlight') {
                            state.messages = state.messages.filter((msg) => msg.id !== state.editingMessage.id);
                        }
                    }, 500)
                });
        },

        // REMINDERS

        getReminderMessages({state, commit}) {
            instance.get(`/messages/${state.chatData.caseId}/chat/${state.chatData.chatId}/messages/reminder?`
                + `keySearch=${state.keySearch}&page=${state.chatPage}&size=${state.chatSize}`)
                .then((res) => {
                    try {
                        updateNewMessages(state, res, commit);
                    } finally {
                        setTimeout(() => commit('setLoadingState', false), 1000)
                    }
                })
        },

        addReminder({state, dispatch}) {
            instance.post(`/reminder`,
                {
                    remindAt: state.remindAt,
                    caseId: state.chatData.caseId,
                    chatId: state.chatData.chatId,
                    messageId: state.editingMessage.id
                })
                .then(() => {
                    setTimeout(() => dispatch('countActiveRemindersByChatId'), 1000);
                })
        },

        editReminder({state}, reminderId) {
            instance.put(`/reminder/case/${state.chatData.caseId}/${reminderId}`, {remindAt: state.remindAt})
                .then(() => {
                    state.messages = state.messages.map((msg) => {
                        if (msg.reminder.id === reminderId) {
                            msg.reminder.remindIn = state.remindAt
                        }
                        return msg
                    });
                })
        },

        deleteReminder({state, dispatch}, reminderId) {
            instance.delete(`/reminder/case/${state.chatData.caseId}/${reminderId}`)
                .then(() => {
                    if (state.currentRoute === 'reminders') {
                        state.messages = state.messages.filter((msg) => msg.reminder.id !== reminderId);
                    }
                    setTimeout(() => dispatch('countActiveRemindersByChatId'), 1000);
                })
        },

        markAsCompleteReminder({state, dispatch}, reminderId) {
            instance.post(`/reminder/case/${state.chatData.caseId}/${reminderId}`, {})
                .then(() => {
                    if (state.currentRoute === 'reminders') {
                        state.messages = state.messages.filter((msg) => msg.reminder.id !== reminderId);
                    }
                    setTimeout(() => dispatch('countActiveRemindersByChatId'), 1000);
                })
        },

        countActiveRemindersByChatId({state, dispatch}) {
            instance.get(`/reminder/case/${state.chatData.caseId}/chat/${state.chatData.chatId}/count`)
                .then((res) => {
                    dispatch('setCountReminders', res.data.body.count);
                })
        },

        // UTILS

        clearUnBlurredMessage({commit}) {
            commit('setUnBlurredMessage', null);
        },

        resetTabState({commit}) {
            commit('resetTabState');
        },

        // SETTERS

        setCaseId({commit}, caseId) {
            commit('setCaseId', Number.parseInt(caseId));
        },

        setInitialState({commit, dispatch}, {caseId, messageId}) {
            commit('resetState');
            commit('setCaseId', Number.parseInt(caseId));
            commit('setEditMode', true);
            commit('setChatDataLastMessageId', messageId);
            dispatch('getMessageById', messageId);
        },

        setCountReminders({commit}, countReminders) {
            commit('setCountReminders', countReminders);
        },
        setReminder({commit}, remindAt) {
            commit('setReminder', remindAt);
        },
        setCurrentRoute({commit}, currentRoute) {
            commit('setCurrentRoute', currentRoute);
        },
        setKeySearch({commit}, keySearch) {
            commit('resetTabState');
            commit('setKeySearch', keySearch);
        },
        setLoadingState({commit}, isLoading) {
            commit('setLoadingState', isLoading);
        },
        setChatTags({commit}, chatTags) {
            commit('setChatTags', chatTags);
        },
        setEditMode({commit}, isEditMode) {
            commit('setEditMode', isEditMode);
        },
        setEditingComment({commit}, comment) {
            commit('setEditingComment', comment);
        },
        setChatData({commit}, chatData) {
            commit('setChatData', chatData);
        },
        setChatId({commit}, chatId) {
            commit('setChatId', chatId);
        },
        setMessages({commit}, messages) {
            commit('setMessages', messages);
        }
    },
    mutations: {

        // COMMON

        setEditMode(state, isEditMode) {
            state.isEditMode = isEditMode;
        },
        setLoadingState(state, isLoading) {
            state.isLoading = isLoading;
        },
        setCaseId(state, caseId) {
            state.chatData.caseId = caseId;
        },
        setChatDataLastMessageId(state, messageId) {
            state.chatData.lastEditedMessageId = messageId;
        },
        setChatData(state, chatData) {
            state.chatData = chatData;
        },
        setChatId(state, chatId) {
            state.chatData.chatId = chatId;
        },
        setCurrentRoute(state, currentRoute) {
            state.currentRoute = currentRoute;
        },
        setKeySearch(state, keySearch) {
            state.keySearch = keySearch;
        },
        setHasMoreMessages(state, hasMoreMessages) {
            state.hasMoreMessages = hasMoreMessages;
        },
        setMessages(state, messages) {
            state.messages = messages;
        },

        // REMINDERS

        setCountReminders(state, countReminders) {
            state.countReminders = countReminders;
        },
        setReminder(state, remindAt) {
            state.remindAt = remindAt;
        },


        // TAGS

        setMessageTags(state, messageTags) {
            state.messageTags = messageTags;
        },
        setTagsList(state, tagsList) {
            state.tagsList = tagsList;
        },
        setChatTags(state, chatTags) {
            state.chatTags = chatTags;
        },

        // MESSAGE

        setEditingMessage(state, editingMessage) {
            state.editingMessage = editingMessage;
        },
        setUnBlurredMessage(state, unBlurredMessage) {
            state.unBlurredMessage = unBlurredMessage;
        },
        setEditingComment(state, comment) {
            state.editingComment = comment;
        },
        setComments(state, comments) {
            state.comments = comments;
        },


        // UTILS

        UPDATE_COMMENT(state, {index, updatedComment}) {
            Vue.set(state.messages, index, updatedComment);
        },

        resetTabState(state) {
            state.chatPage = 1;
            state.messages = [];
            state.keySearch = '';
        },

        resetState(state) {
            // state.chatId = null;
            // state.caseId = null;
            state.isEditMode = false;
            state.isLoading = false;
            state.hasMoreMessages = false;
            state.chatPage = 1;
            state.keySearch = '';

            state.editingMessage = null;
            state.editingComment = null;
            state.unBlurredMessage = null;

            state.comments = [];
            state.chatTags = [];
            state.messageTags = [];
            state.tagsList = [];

            state.messages = [];
            state.tagsTabMessages = [];

            state.currentRoute = '';

            state.reminderMessages = [];
            state.countReminders = 0;
            state.remindAt = new Date();
        }
    }
}
