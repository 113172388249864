<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section
          class="dashboard-section"
          v-if="!isDownloads"
        >
          <div class="trash-section">
            <div class="holder-img">
              <img src="@/assets/img/trash.svg" alt="trash">
            </div>
            <h2>Now your Trash is empty</h2>
            <p>All the deleting data stored in the system for 30 days in the "Trash" folder</p>
          </div>
        </section>
        <section v-else class="dashboard-section">
          <DownloadsContent/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";
import DownloadsContent from "../components/shared-cases/DownloadsContent";

export default {
  name: "Download",
  components: {
    DownloadsContent,
    HeaderRegistered,
  },
  data() {
    return {
      isDownloads: true,
    }
  },
  mounted() {
    document.title = 'Download'
  }
}
</script>

<style scoped>
.trash-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
</style>