<template>
  <div class="overlay"
       v-if="activeDialog"
       :class="activeDialog ? 'active': ''"
  >
    <div class="export-dialog-wrapper" id="dialoge_close">
      <h1>Export details</h1>
      <div
        v-if="isMessagesExport"
        class="config-choose-wrapper"
        :class="{ disabled: !hasPermission(['export_responsive_data']) }"
      >
        <span class="choose-text">Choose configurations to show in exported file:</span>
        <CustomCheckbox
          id="all"
          :label="'All'"
          :input-element="configChosen.all"
          :check-permission="!hasPermission(['export_responsive_data'])"
        />
        <CustomCheckbox
          id="tags"
          :label="'Tags'"
          :input-element="configChosen.tags"
          :disable-checkbox="disableOptions"
          :check-permission="!hasPermission(['export_responsive_data'])"
        />
        <CustomCheckbox
          id="comments"
          :label="'Comments'"
          :input-element="configChosen.comments"
          :disable-checkbox="disableOptions"
          :check-permission="!hasPermission(['export_responsive_data'])"
        />
        <CustomCheckbox
          id="attachment"
          :label="'Attachment'"
          :input-element="configChosen.attachments"
          :disable-checkbox="disableOptions"
          :check-permission="!hasPermission(['export_responsive_data'])"
        />
        <CustomCheckbox
          id="transcription"
          :label="'Transcription'"
          :input-element="configChosen.transcriptions"
          :disable-checkbox="disableOptions || !configChosen.attachments.isActive"
          :check-permission="!hasPermission(['export_responsive_data'])"
        />
      </div>
      <div class="config-choose-wrapper">
        <span class="choose-text">Choose file format:</span>
        <div
          v-for="format in exportFormats"
          :key="format"
          class="toggleWrapper"
          @mouseenter="showTooltip(format)"
          @mouseleave="hideTooltip(format)"
        >
          <div
            class="btn-wrapper"
            :class="{ disabled: (format === 'rsmf' || format ==='csv') && (isAnyConfigChosen || isFiltersApplied) }"
          >
            <input
              type="radio"
              :id="`format-${format}`"
              :name="format"
              :value="format"
              v-model="formatChosen"
            />
            <label :for="`format-${format}`" class="toggle">
              <div class="radio-label-wrapper">
                <span class="toggle__handler">{{ format }}</span>
              </div>
            </label>
          </div>
          <div v-if="format === 'rsmf'" style="position: relative;">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM10 17.375C8.64026 17.375 7.31105 16.9718 6.18046 16.2164C5.04987 15.4609 4.16868 14.3872 3.64833 13.1309C3.12798 11.8747 2.99183 10.4924 3.2571 9.15875C3.52238 7.82513 4.17716 6.60013 5.13864 5.63864C6.10013 4.67716 7.32514 4.02237 8.65876 3.7571C9.99238 3.49183 11.3747 3.62798 12.631 4.14833C13.8872 4.66868 14.9609 5.54987 15.7164 6.68045C16.4718 7.81104 16.875 9.14025 16.875 10.5C16.8729 12.3227 16.1479 14.0702 14.8591 15.3591C13.5702 16.6479 11.8227 17.3729 10 17.375ZM11.25 14.25C11.25 14.4158 11.1842 14.5747 11.0669 14.6919C10.9497 14.8092 10.7908 14.875 10.625 14.875C10.2935 14.875 9.97554 14.7433 9.74112 14.5089C9.5067 14.2745 9.375 13.9565 9.375 13.625V10.5C9.20924 10.5 9.05027 10.4342 8.93306 10.3169C8.81585 10.1997 8.75 10.0408 8.75 9.875C8.75 9.70924 8.81585 9.55027 8.93306 9.43306C9.05027 9.31585 9.20924 9.25 9.375 9.25C9.70652 9.25 10.0245 9.3817 10.2589 9.61612C10.4933 9.85054 10.625 10.1685 10.625 10.5V13.625C10.7908 13.625 10.9497 13.6908 11.0669 13.8081C11.1842 13.9253 11.25 14.0842 11.25 14.25ZM8.75 7.0625C8.75 6.87708 8.80499 6.69582 8.908 6.54165C9.01101 6.38748 9.15743 6.26732 9.32874 6.19636C9.50004 6.12541 9.68854 6.10684 9.8704 6.14301C10.0523 6.17919 10.2193 6.26848 10.3504 6.39959C10.4815 6.5307 10.5708 6.69775 10.607 6.8796C10.6432 7.06146 10.6246 7.24996 10.5536 7.42127C10.4827 7.59257 10.3625 7.73899 10.2084 7.842C10.0542 7.94502 9.87292 8 9.6875 8C9.43886 8 9.20041 7.90123 9.02459 7.72541C8.84878 7.5496 8.75 7.31114 8.75 7.0625Z"
                fill="#8692A6"/>
            </svg>
            <transition name="fade">
              <span v-if="showRsmfTooltip && format === 'rsmf'" class="tooltip">Available only without Configurations or Filters</span>
            </transition>
          </div>
          <div v-if="format === 'csv'" style="position: relative;">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM10 17.375C8.64026 17.375 7.31105 16.9718 6.18046 16.2164C5.04987 15.4609 4.16868 14.3872 3.64833 13.1309C3.12798 11.8747 2.99183 10.4924 3.2571 9.15875C3.52238 7.82513 4.17716 6.60013 5.13864 5.63864C6.10013 4.67716 7.32514 4.02237 8.65876 3.7571C9.99238 3.49183 11.3747 3.62798 12.631 4.14833C13.8872 4.66868 14.9609 5.54987 15.7164 6.68045C16.4718 7.81104 16.875 9.14025 16.875 10.5C16.8729 12.3227 16.1479 14.0702 14.8591 15.3591C13.5702 16.6479 11.8227 17.3729 10 17.375ZM11.25 14.25C11.25 14.4158 11.1842 14.5747 11.0669 14.6919C10.9497 14.8092 10.7908 14.875 10.625 14.875C10.2935 14.875 9.97554 14.7433 9.74112 14.5089C9.5067 14.2745 9.375 13.9565 9.375 13.625V10.5C9.20924 10.5 9.05027 10.4342 8.93306 10.3169C8.81585 10.1997 8.75 10.0408 8.75 9.875C8.75 9.70924 8.81585 9.55027 8.93306 9.43306C9.05027 9.31585 9.20924 9.25 9.375 9.25C9.70652 9.25 10.0245 9.3817 10.2589 9.61612C10.4933 9.85054 10.625 10.1685 10.625 10.5V13.625C10.7908 13.625 10.9497 13.6908 11.0669 13.8081C11.1842 13.9253 11.25 14.0842 11.25 14.25ZM8.75 7.0625C8.75 6.87708 8.80499 6.69582 8.908 6.54165C9.01101 6.38748 9.15743 6.26732 9.32874 6.19636C9.50004 6.12541 9.68854 6.10684 9.8704 6.14301C10.0523 6.17919 10.2193 6.26848 10.3504 6.39959C10.4815 6.5307 10.5708 6.69775 10.607 6.8796C10.6432 7.06146 10.6246 7.24996 10.5536 7.42127C10.4827 7.59257 10.3625 7.73899 10.2084 7.842C10.0542 7.94502 9.87292 8 9.6875 8C9.43886 8 9.20041 7.90123 9.02459 7.72541C8.84878 7.5496 8.75 7.31114 8.75 7.0625Z"
                fill="#8692A6"/>
            </svg>
            <transition name="fade">
              <span v-if="showCsvTooltip && format === 'csv'" class="tooltip">Available only without Configurations or Filters</span>
            </transition>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button
          id="dialoge_continue"
          class="btn dialog-btn"
          @click="closePopups">
          <span class="dialog-btn-text">Close</span>
        </button>
        <button
          :disabled="!formatChosen"
          id="dialoge_continue"
          class="btn"
          @click="exportAction">
          <span class="dialog-btn-text">Export</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import {mapActions} from "vuex";
import {CASE_EXPORT_FORMATS} from "@/constants/const";
import hasPermission from "@/utils/permission";

export default {
  name: "ExportPopup",
  components: {
    CustomCheckbox,
  },
  props: {
    isFiltersApplied: {
      type: Boolean,
      required: true,
    },
    isMessagesExport: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  emits: ["closed", "export"],
  data() {
    const configInitialState = {
      all: {isActive: false},
      tags: {isActive: false},
      comments: {isActive: false},
      attachments: {isActive: false},
      transcriptions: {isActive: false},
    }
    return {
      CASE_EXPORT_FORMATS,
      configInitialState,
      activeDialog: false,
      configChosen: configInitialState,
      formatChosen: null,
      showRsmfTooltip: false,
      showCsvTooltip: false,
      disableOptions: false,
    };
  },
  watch: {
    configChosen: {
      deep: true,
      handler() {
        if ((this.formatChosen === 'rsmf' || this.formatChosen === 'csv') && this.isAnyConfigChosen) {
          this.formatChosen = null;
        }
      },
    },
    'configChosen.attachments.isActive'(newVal) {
      this.configChosen.transcriptions.isActive = !newVal ? false : this.configChosen.transcriptions.isActive;
    },
    'configChosen.all.isActive'(newVal) {
      if (newVal) {
        this.configChosen.tags.isActive = true;
        this.configChosen.comments.isActive = true;
        this.configChosen.attachments.isActive = true;
        this.configChosen.transcriptions.isActive = true;
        this.disableOptions = true;
      } else {
        this.configChosen.all.isActive = false;
        this.configChosen.tags.isActive = false;
        this.configChosen.comments.isActive = false;
        this.configChosen.attachments.isActive = false;
        this.configChosen.transcriptions.isActive = false;
        this.disableOptions = false;
      }
    }
  },
  computed: {
    exportFormats() {
      if (!this.isMessagesExport) {
        return ['pdf', 'xml']
      } else {
        return Object.values(CASE_EXPORT_FORMATS);
      }
    },
    isAnyConfigChosen() {
      return Object.values(this.configChosen).filter(item => item.isActive).length > 0;
    },
  },
  methods: {
    hasPermission,
    ...mapActions('caseMessages', ['setLoadingState']),
    showTooltip(format) {
      if (format === 'rsmf') {
        this.showRsmfTooltip = true;
      } else if (format === 'csv') {
        this.showCsvTooltip = true;
      }
    },
    hideTooltip(format) {
      if (format === 'rsmf') {
        this.showRsmfTooltip = false;
      } else if (format === 'csv') {
        this.showCsvTooltip = false;
      }
    },
    openPopups() {
      this.activeDialog = true;
      this.setLoadingState(true);
    },
    closePopups() {
      this.formatChosen = null;
      this.configChosen = this.configInitialState;
      this.$emit("closed");
      this.setLoadingState(false);
      this.activeDialog = false;
    },
    exportAction() {
      this.$emit("export", this.formatChosen, this.getMappedConfig());
      this.formatChosen = null;
      this.configChosen = this.configInitialState;
      this.setLoadingState(false);
      this.activeDialog = false;
    },
    getMappedConfig() {
      if (!this.isAnyConfigChosen) {
        return {
          tags: false,
          comments: false,
          attachments: false,
          transcriptions: false,
        }
      }
      if (this.configChosen.all.isActive) {
        return {
          tags: true,
          comments: true,
          attachments: true,
          transcriptions: true,
        }
      }
      return {
        tags: this.configChosen.tags.isActive,
        comments: this.configChosen.comments.isActive,
        attachments: this.configChosen.attachments.isActive,
        transcriptions: this.configChosen.transcriptions.isActive,
      }
    }
  },
  beforeDestroy() {
    this.setLoadingState(false);
  }
};
</script>

<style scoped lang="scss">
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show .3s linear;
  pointer-events: auto;
}

.export-dialog-wrapper {
  width: 100%;
  max-width: 460px;
  padding: 32px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--black-color);
    margin: 0 0 24px;
  }

  .config-choose-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: 0 0 24px;

    .choose-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 25.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--secondary-text-color);
    }

    .toggleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        input {
          height: fit-content;
          margin: 2px 0 0;
        }

        .toggle {
          margin-bottom: 0;

          .radio-label-wrapper {
            display: flex;
            align-items: center;

            & > span:first-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: var(--secondary-black-color);
            }

            &:last-child {
              font-size: 12px;
              font-weight: 500;
              line-height: 19px;
              color: var(--secondary-text-color);
            }
          }
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .btn {
      width: 100%;
      max-width: 190px;
      height: 50px;
      padding: 13px 12px;
      border-radius: 25px;

      .dialog-btn-text {
        color: var(--white-color);
      }

      &:hover {
        .dialog-btn-text {
          color: var(--accent-color);
        }
      }
    }

    .dialog-btn {
      background-color: var(--white-color);
      border-color: var(--accent-color);

      .dialog-btn-text {
        color: var(--accent-color);
      }

      &:hover {
        background-color: var(--accent-color);
        border-color: var(--accent-color);

        .dialog-btn-text {
          color: var(--white-color);
        }
      }
    }
  }

}

.dialog-btn-text {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: var(--accent-color);
  transition: .5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

/*custom classes */
.export-dialog-wrapper.assign-success {
  width: 100%;
  max-width: 588px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  z-index: 100;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  transform: translate(-87%, -110%);
  transition: all .5s ease;
  white-space: nowrap;
  max-width: none;

  &:before {
    left: 240px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
