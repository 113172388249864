<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <EmptyNotification v-if="!isNotification"/>
          <NotificationsList v-else/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderRegistered from "../components/layout/HeaderRegistered";
import EmptyNotification from "../components/profile/EmptyNotification";
import NotificationsList from "../components/notifications/NotificationsList"

export default {
  name: "Notifications",
  components: {
    HeaderRegistered,
    EmptyNotification,
    NotificationsList
  },

  data() {
    return {
      isNotification: true,
    }
  },

  mounted() {
    document.title = this.$router.currentRoute.name;
  }
}
</script>

<style scoped>

</style>