<template>
  <div class="holder-profile-form">
    <div class="holder-pass">
      <div class="pass-text">
        <p>How would you like to receive your authentication code?</p>
      </div>
      <div class="toggleWrapper">
        <input
          type="radio"
          id="email-2fa"
          name="2fa"
          value="EMAIL_2FA"
          v-model="checked2fa"
          :disabled="default2fa === 'G2FA'"
        />
        <label for="email-2fa" class="toggle">
          <div class="radio-label-wrapper">
            <span class="toggle__handler">Via Email Secret Code</span>
            <span class="toggle__handler">We will send a secret code to your email</span>
          </div>
        </label>
      </div>
      <div class="toggleWrapper">
        <input
          type="radio"
          id="sms-2fa"
          name="2fa"
          value="SMS_2FA"
          v-model="checked2fa"
          :disabled="default2fa === 'G2FA'"
        />
        <label for="sms-2fa" class="toggle">
          <div class="radio-label-wrapper">
            <span class="toggle__handler">Via SMS Text Message</span>
            <span class="toggle__handler">We will send a SMS code to your phone number</span>
          </div>
        </label>
      </div>
      <div class="toggleWrapper">
        <input
          type="radio"
          id="g2fa"
          name="2fa"
          value="G2FA"
          v-model="checked2fa"
        />
        <label for="g2fa" class="toggle">
          <div class="radio-label-wrapper">
            <span class="toggle__handler">Via the Google Authenticator</span>
            <span class="toggle__handler">Use an app to generate an authentication code</span>
          </div>
        </label>
      </div>
      <div
        v-if="checked2fa !== 'G2FA'"
        id="save_2fa_state"
        ref="save2FaState"
        style="margin: 50px 0 0 0"
      >
        <button
          id="save_2fa_state_button"
          @click="check2fa"
          :disabled="checked2fa === default2fa"
          class="btn profile-btn save-btn"
        >
          Save
        </button>
        <tooltip v-if="default2fa === 'G2FA' && checked2fa !== 'G2FA'" :target-ref="$refs.save2FaState">
          <span>To change verification type you need to disable google authenticator</span>
        </tooltip>
      </div>
      <div v-if="checked2fa === 'G2FA' && default2fa !== 'G2FA'" class="verification-2fa-wrap">
        <div class="verification-2fa-step">
          <h2 class="verification-2fa-title">1. Configure the app</h2>
          <p class="verification-2fa-descr">Open Google Authenticator app and scan the QR-code:</p>
          <div class="verification-2fa-qr-wrap">
            <img v-if="qrCode" :src="`data:image/png;base64,${qrCode}`" class="verification-2fa-qr-image" alt="qr code">
          </div>
          <div class="verification-2fa-secret-wrap">
            <span class="verification-2fa-secret-title">Secret key</span>
            <span class="verification-2fa-secret-value">{{ securityCode }}</span>
          </div>
        </div>
        <div class="verification-2fa-step">
          <h2 class="verification-2fa-title">2. Enter the 6-digit code</h2>
          <div class="verification-2fa-otp-wrap">
            <otp-code ref="otpInput"
                      :otpVerify="otpVerify"
                      @update:otpValue="setGoogleTwoFa"
            />
          </div>
        </div>
      </div>
      <div v-if="checked2fa === 'G2FA' && default2fa === 'G2FA'" class="verification-2fa-wrap">
        <div class="verification-2fa-step">
          <h2 class="verification-2fa-title" style="margin-bottom: 10px;">Disable Google Authenticator</h2>
          <p class="verification-2fa-descr">In order to change the type of 2FA, you will first need to disable the
            Google Authenticator.</p>
        </div>
        <div class="verification-2fa-step">
          <h2 class="verification-2fa-title">Enter the 6-digit code</h2>
          <div class="verification-2fa-otp-wrap">
            <otp-code ref="otpInput"
                      :otpVerify="otpVerify"
                      @update:otpValue="disableGTwoFaOtp"
            />
          </div>
        </div>
      </div>
    </div>

    <CommonDialog
      ref="commonDialogTwoFaSuccess"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p v-if="checked2fa === 'G2FA' || isG2Disabled" class="dialog-message-description" style="display:block;">Google
        authenticator was successfully
        {{ isG2Disabled ? 'disabled and 2fa was switched to email verification' : 'enabled' }}</p>
      <p v-else class="dialog-message-description" style="display:block;">The 2FA was successfully changed</p>
    </CommonDialog>
  </div>
</template>

<script>
import CommonDialog from "../popups/CommonDialog";
import OtpCode from "./OtpCode";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  components: {
    Tooltip,
    CommonDialog,
    OtpCode,
  },
  data() {
    return {
      errorMessage: '',
      checked2fa: '',
      default2fa: null,
      isG2Disabled: false,
      qrCode: '',
      securityCode: '',
      otpVerify: null,
    };
  },
  methods: {
    openDialogTwoFaSuccess() {
      this.$refs.commonDialogTwoFaSuccess.openPopups();
    },
    checkProfileGoogleTwoFa() {
      this.$load(async () => {
        await this.$api.profile.checkProfileGoogleTwoFa().then((res) => {
            if (res.data.body.result === true) {
              this.checked2fa = "G2FA";
              this.default2fa = "G2FA";
            }
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
          })
        )
      })
    },
    setGoogleTwoFa(value) {
      this.$load(async () => {
        await this.$api.profile.confirmProfileGoogleTwoFa({code: value}).then(() => {
          this.$load(async () => {
            this.$api.profile.changeVerificationType({type: 'G2FA'}).then(() => {
              this.otpVerify = true;
              this.default2fa = "G2FA";
              this.isG2Disabled = false;
              this.$refs.otpInput.handleClearInput();
              this.openDialogTwoFaSuccess()
            })
          });
        })
      }, (error) => {
        this.otpVerify = false;
        this.errorMessage = error.data.errors.message[0]
      })
    },
    profileGoogleTwoFa() {
      this.$load(async () => {
        await this.$api.profile.profileGoogleTwoFa({}).then((res) => {
            let response = res.data.body
            this.getGoogleTwoFaQr()
            this.securityCode = response.securityCode
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
          })
        )
      })
    },
    getGoogleTwoFaQr() {
      this.$load(async () => {
        await this.$api.profile.getGoogleTwoFaQr({}).then((res) => {
            this.qrCode = res.data.body.encodedQrCode
          }, (error => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
          })
        )
      })
    },
    disableGTwoFaOtp(value) {
      this.$load(async () => {
        await this.$api.profile.disableProfileGoogleTwoFa({code: value}).then(() => {
          this.$api.profile.changeVerificationType({type: 'EMAIL_2FA'}).then(() => {
            this.otpVerify = true;
            this.isG2Disabled = true;
            this.default2fa = "EMAIL_2FA";
            this.checked2fa = "EMAIL_2FA";
            this.openDialogTwoFaSuccess();
          });
        });
      }, (error) => {
        this.otpVerify = false;
        this.errorMessage = error.data.errors.message[0]
      })
    },
    check2fa() {
      this.resetState()
      if (this.default2fa !== this.checked2fa) {
        if (this.checked2fa === 'G2FA') {
          this.profileGoogleTwoFa()
        } else if (this.checked2fa === 'EMAIL_2FA') {
          this.$load(async () => {
            this.$api.profile.changeVerificationType({type: this.checked2fa}).then(() => {
              this.default2fa = this.checked2fa;
              this.openDialogTwoFaSuccess()
            })
          });
        } else if (this.checked2fa === 'SMS_2FA') {
          this.$load(async () => {
            this.$api.profile.changeVerificationType({type: this.checked2fa}).then(() => {
              this.default2fa = this.checked2fa;
              this.openDialogTwoFaSuccess()
            })
          });
        }
      }
    },
    async checkDefaultTwoFaType() {
      await this.checkProfileGoogleTwoFa();
      if (this.checked2fa !== 'G2FA') {
        this.$load(async () => {
          this.$api.profile.getProfile().then(res => {
            this.default2fa = res.data.body.verificationType;
            this.checked2fa = res.data.body.verificationType;
          })
        });
      }
    },
    resetState() {
      this.isG2Disabled = null;
    }
  },
  watch: {
    checked2fa(newVal) {
      if (newVal === 'G2FA') {
        this.profileGoogleTwoFa();
      }
    }
  },
  mounted() {
    this.checkDefaultTwoFaType();
  }
}
</script>

<style lang="scss" scoped>

.holder-profile-form {
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-text-color);
}

.pass-text {
  width: 50%;
}

.holder-profile-form p {
  color: var(--secondary-black-color);
  margin: 0;
  width: 90%;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.pass-text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--secondary-black-color);
}

.form-group {
  position: relative;
  width: 50%;
}

.form-group input {
  padding: 15px 44px;
}

.form-group .input-password {
  position: absolute;
  top: 44px;
  right: 23px;
}

.form-group .input-text {
  position: absolute;
  top: 41.5px;
  right: 23px;
}

.holder-pass {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 76px;
}

.profile-btn {
  width: 100%;
  padding: 14px;
  margin-top: 30px;
}

.radio-label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-label-wrapper > span:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: var(--secondary-black-color);
}

.radio-label-wrapper:last-child {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--secondary-text-color);
}

.toggleWrapper {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin: 15px 0 0;

  .toggle {
    margin-bottom: 0;
  }

  input {
    height: fit-content;
    margin: 2px 0 0;
  }
}

/* end */


.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 16px 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 26px;
  text-align: center;
  color: #696F79 !important;
  display: block;
  width: 100%;
  margin-bottom: 32px !important;
}

.verification-2fa-wrap {
  max-width: 612px;
  max-height: 600px;
  width: 100%;
  background: #0826551A;
  padding: 25px;
  margin: 15px 0 0 0;
}

.verification-2fa-qr-wrap {
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
}

.verification-2fa-secret-title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.g2fa-button,
.save-btn {
  max-width: 400px;
  border-radius: 25px;
}

.save-btn {
  margin-top: 0;
}

</style>