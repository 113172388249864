<template>
  <div class="overlay"
       :class="dialog ? 'active': ''"
       v-if="dialog">
    <div class="popup" v-click-outside="closePopup">
      <div
          :id="`close_case_popup`"
          class="popup-close" @click="closePopup">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
              fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="popup-content">
        <h2 class="popup-title">Assign Case</h2>
        <div class="">
          <form @submit.prevent="submit">
            <div class="popup-form-item" :class="{ 'form-group--error': $v.formData.cases.$error }">
              <label style="margin-bottom: 10px">Choose cases</label>
              <CustomMultiSelect
                  :id="`assign_select_role_user`"
                  :options="caseList"
                  v-model="$v.formData.cases.$model"
                  :multiple="multipleCases"
                  :placeholder="'Choose case'"
              />
              <div class="error" :style="{ opacity: !$v.formData.cases.required ? '1' : '0' }">Field is required</div>
            </div>
          </form>
        </div>
        <div v-if="error" class="popup-error">
          {{error}}
        </div>
        <button
            :id="`assign_case_popup`"
            class="btn popup-action-btn"
            @click="submit"
        >
          <span class="action-btn-text">Assign</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import CustomMultiSelect from "../inputs/CustomMultiSelect";
import {required} from "vuelidate/lib/validators";

export default {
  name: "AssignCasePopup",
  components: {
    CustomMultiSelect,
  },
  props: {
    error: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      payload: null,
      multipleCases: true,
      casesFromApi: null,
      caseList: [],
      formData: {
        cases: "",
      },
    }
  },
  validations: {
    formData: {
      cases: {
        required,
      },
    },
  },
  mounted() {
    this.getCases();
  },
  methods: {
    openPopup(payload) {
      this.dialog = true
      this.payload = payload;
    },
    closePopup() {
      this.dialog = false
    },
    resetForm() {
      this.formData.cases = []
      this.$emit('clear', null);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    getCases() {
      this.$load(async () => {
        await this.$api.cases.getCasesInvite().then((res) => {
          this.casesFromApi = res.data.body;
          this.caseList = this.casesFromApi.map((aCase) => ({caseName: aCase.caseName, caseId: aCase.id}));
        });
      });
    },
    submit() {
      this.isSending = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.isSending = false
      } else {
        this.submitStatus = 'PENDING'
        this.formData.cases.length
          ? this.formData.cases = [...new Set(this.formData.cases.map(el => el.caseId))]
          : this.formData.cases = null
        let data = Object.assign(this.payload, this.formData)
        this.$emit('submit', data)
        this.resetForm()
      }
    },
  },
}
</script>

<style scoped>

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.overlay.active {
  animation: show .3s linear;
}

.popup {
  position: absolute;
  padding: 50px 25px 23px 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 446px;
  min-height: 285px;
  border-radius: 30px;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.popup-content {
  width: 369px;
}

.popup-title {
  font-size: 30px;
  line-height: 30px;
  color: var(--black-color);
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.popup-action-btn {
  padding: 15px 50px;
  width: 100%;
  max-width: 152px;
  margin: 18px auto 0;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  justify-content: center;
  border-radius: 25px;
}

.popup-action-btn:hover {
  background-color: var(--accent-color);
}

.popup-action-btn:hover .action-btn-text {
  color: var(--white-color);
}

.action-btn-text {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: var(--accent-color);
  transition: .5s all;
}

.popup-form-item span,
.popup-form-item label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #696F79;
}

.popup-form-item textarea {
  height: 130px;
  resize: none;
  border-radius: 20px !important;
}

.popup-title {
  margin-top: 0;
  margin-bottom: 35px;
}

.popup-error {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--alert-color);
  margin-top: 10px;
}

.error {
  display: block;
  opacity: 0;
}

</style>
