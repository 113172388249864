<template>
  <vue-editor
    v-model="content"
    :editorToolbar="customToolbar"
    class="text-editor"
  />
</template>
<script>
import {VueEditor} from "vue2-editor";

export default {
  name: "RichTextEditor",
  components: {
    VueEditor,
  },
  props: {
    defaultText: {
      type: String,
    }
  },
  data() {
    return {
      content: "",
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline", "strike"],
        ["code-block"],
        [{list: "ordered"}, {list: "bullet"}],
        [{indent: "-1"}, {indent: "+1"}],
        [{color: []}, {background: []}],
        ["link"],
        ["clean"]
      ],
    }
  },
  watch: {
    content(val) {
      this.$emit("input", val);
    },
    defaultText(val) {
      this.content = val;
      // this.$emit("input", val);
    }
  },
}
</script>
<style scoped>
</style>